import { useState } from 'react';
import { collection, query, where, getDocs, addDoc, doc, updateDoc, getDoc, runTransaction } from 'firebase/firestore';
import { db } from '../config/firebase';
import type { Review } from '../types';

export function useReviews(sitterId: string) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const loadReviews = async () => {
    try {
      setLoading(true);
      const q = query(
        collection(db, 'reviews'), 
        where('sitterId', '==', sitterId),
        where('status', '==', 'approved')
      );
      const querySnapshot = await getDocs(q);
      
      const reviews: Review[] = [];
      querySnapshot.forEach((doc) => {
        reviews.push({ id: doc.id, ...doc.data() } as Review);
      });

      return reviews.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
    } catch (err) {
      setError('Erreur lors du chargement des avis');
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const addReview = async (reviewData: Omit<Review, 'id' | 'date' | 'status'>) => {
    try {
      setLoading(true);
      
      // Validation des données
      if (!reviewData.sitterId || !reviewData.ownerId || !reviewData.comment || !reviewData.serviceType) {
        throw new Error('Tous les champs requis doivent être remplis');
      }

      if (reviewData.comment.length < 10) {
        throw new Error('L\'avis doit contenir au moins 10 caractères');
      }

      // Vérifier si l'utilisateur a déjà laissé un avis
      const q = query(
        collection(db, 'reviews'),
        where('sitterId', '==', sitterId),
        where('ownerId', '==', reviewData.ownerId)
      );
      const existingReviews = await getDocs(q);
      
      if (!existingReviews.empty) {
        throw new Error('Vous avez déjà laissé un avis pour ce pet sitter');
      }

      // Créer l'avis
      const reviewRef = await addDoc(collection(db, 'reviews'), {
        ...reviewData,
        rating: Math.min(Math.max(Number(reviewData.rating), 1), 5),
        comment: String(reviewData.comment).trim(),
        date: new Date().toISOString(),
        status: 'approved',
        ownerAvatar: reviewData.ownerAvatar || null,
        ownerName: reviewData.ownerName || 'Anonyme'
      });

      // Mettre à jour la note moyenne du pet sitter
      const sitterRef = doc(db, 'sitterProfiles', sitterId);
      const sitterDoc = await getDoc(sitterRef);
      
      if (sitterDoc.exists()) {
        const currentReviews = await loadReviews();
        const allReviews = [...currentReviews, { 
          id: reviewRef.id, 
          ...reviewData, 
          rating: Math.min(Math.max(Number(reviewData.rating), 1), 5)
        }];
        
        const totalRating = allReviews.reduce((sum, review) => sum + review.rating, 0);
        const averageRating = totalRating / allReviews.length;

        await updateDoc(sitterRef, {
          rating: Number(averageRating.toFixed(1)),
          reviewCount: allReviews.length
        });
      }

      return {
        success: true,
        message: 'Votre avis a été publié avec succès.'
      };
    } catch (err) {
      const message = err instanceof Error ? err.message : 'Erreur lors de l\'ajout de l\'avis';
      setError(message);
      throw new Error(message);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    error,
    loadReviews,
    addReview
  };
}