import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, Gift } from 'lucide-react';
import { Link } from 'react-router-dom';

export default function PromotionalBanner() {
  const [isVisible, setIsVisible] = useState(true);

  if (!isVisible) return null;

  return (
    <AnimatePresence>
      <motion.div
        initial={{ height: 0, opacity: 0 }}
        animate={{ height: 'auto', opacity: 1 }}
        exit={{ height: 0, opacity: 0 }}
        className="bg-primary-500 text-white relative z-50"
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <Gift className="w-5 h-5 flex-shrink-0" />
              <p className="text-sm sm:text-base">
                <span className="font-semibold">Offre spéciale :</span>{' '}
                Essai gratuit de 3 mois pour devenir PetSitter !{' '}
                <Link 
                  to="/auth" 
                  className="underline hover:text-white/90 whitespace-nowrap hidden sm:inline-block"
                >
                  S'inscrire maintenant →
                </Link>
              </p>
            </div>
            <div className="flex items-center space-x-4">
              <Link 
                to="/auth" 
                className="underline hover:text-white/90 whitespace-nowrap sm:hidden text-sm"
              >
                S'inscrire →
              </Link>
              <button
                onClick={() => setIsVisible(false)}
                className="flex-shrink-0 text-white/80 hover:text-white transition-colors"
                aria-label="Fermer"
              >
                <X className="w-5 h-5" />
              </button>
            </div>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
}