export const TERMS_CONTENT = {
  sections: [
    {
      id: 1,
      title: "Informations sur le site Internet",
      content: `Le site Internet est conçu et fourni par EuroPetSitter (BCE n° BE 0602.875.091), sis 100/9 avenue Edmond Leburton, 4300 Waremme, Belgique (EuroPetSitter@gmail.com).

Le site Internet est hébergé par NAMECHEAP, Inc. (Employer Identification Number 26-2368289) dont le siège social est établi East Washington Street 4600, Suite 305, Phoenix, AZ 85034, USA.

Le présent site Internet est accessible par le biais du nom de domaine www.EuroPetSitter.fr et est exploité dans le respect de la législation belge. L'utilisation de ce site Internet est régie par les présentes conditions générales. Nous ne pourrions être tenus responsables en aucune manière d'une mauvaise utilisation de notre site.`
    },
    {
      id: 2,
      title: "Application des Conditions générales",
      content: `Les présentes conditions générales définissent, sans préjudice de l'application de conditions particulières, les droits et obligations de parties lors de l'utilisation de notre site internet www.EuroPetSitter.fr et de tous les services offerts via celui-ci.

Pour l'application des présentes conditions générales :

- le détenteur d'animaux de compagnie est un utilisateur « consommateur » qui agit à des fins qui n'entrent pas dans le cadre de son activité commerciale, industrielle, artisanale ou libérale ;
- le prestataire de service animalier est un utilisateur « entreprise » qui poursuit de manière durable un but économique.

L'Utilisateur reconnaît expressément avoir pris connaissance des présentes conditions générales et les avoir acceptées. Seules les dérogations faisant l'objet d'un accord écrit de notre société peuvent modifier l'application des présentes conditions générales. En cas de contrariété entre les conditions générales de l'Utilisateur et nos conditions générales, il est convenu que les nôtres prévalent.`
    },
    {
      id: 3,
      title: "Rôle de la plateforme digitale EuroPetSitter",
      content: `EuroPetSitter a pour but de mettre en relation des détenteurs d'animaux de compagnie et des prestataires de services animaliers. Les services proposés par ces derniers sont essentiellement l'hébergement, la garde, la visite à domicile, la promenade d'animaux domestiques, et plus particulièrement à l'attention des chiens et chats. Pour rappel, l'arrêté du 11 août 2006 fixe la liste des espèces, races ou variétés d'animaux domestiques pouvant être légalement détenus en France (liste consultable en ligne sur Légifrance). Cette réglementation dépend du ministère de l'Agriculture et de l'Alimentation.

EuroPetSitter n'intervient pas dans les accords financiers entre les parties. Aucune transaction financière entre les parties n'est possible via la plateforme. EuroPetSitter ne prélève aucune commission.

Les obligations de EuroPetSitter se limitent à la mise à disposition en ligne d'une plateforme électronique. EuroPetSitter ne garantit pas la bonne exécution des obligations du Prestataire de services animaliers ni du détenteur d'animal. EuroPetSitter ne répond pas des fautes du Prestataire ni du détenteur d'animal. La responsabilité de EuroPetSitter ne saurait être engagée du fait d'un dommage quelconque subi par un animal de compagnie lors d'une Prestation.`
    },
    {
      id: 4,
      title: "Utilisation du site par le prestataire de service animalier",
      content: `Le prestataire de services animaliers peut placer sur le site EuroPetSitter une publicité détaillant ses prestations durant une période déterminée (pour un trimestre ou semestre ou à l'année).

La publicité doit être conforme aux dispositions légales en vigueur, à l'ordre public et aux bonnes mœurs et être dépourvue de tout critère discriminatoire. Dans le cas contraire, EuroPetSitter se réserve le droit de retirer immédiatement la publicité et de rompre l'abonnement sans préavis ni indemnité aux torts du Prestataire.

Le prestataire est seul responsable des autorisations et déclarations relatives à la réalisation des prestations. Il déclare qu'il dispose des droits et autorisations nécessaires à cette fin. Le cas échéant, le prestataire déclare avoir effectué au préalable toute démarche nécessaire, telle que demandes d'autorisations et déclarations administratives, notamment responsabilité civile. Il exerce ses prestations à titre d'indépendant et garantit EuroPetSitter contre tout recours qui serait entrepris à son encontre.`
    },
    {
      id: 5,
      title: "Utilisation du site par le détenteur d'animaux de compagnie",
      content: `Les détenteurs d'animaux de compagnie ont un accès à titre gratuit aux informations mentionnées sur la plateforme digital EuroPetSitter.fr. La plateforme digitale EuroPetSitter fonctionne comme un répertoire doté d'un moteur de recherche.

En cette qualité, EuroPetSitter décline toute responsabilité quant aux conséquences possibles dues à l'inexactitude des informations mentionnées par les prestataires de services animaliers (identité, coordonnées etc.)`
    },
    {
      id: 6,
      title: "Disponibilité du site Internet et force majeure",
      content: `EuroPetSitter se réserve le droit de modifier, suspendre ou interrompre, à tout moment, toute caractéristique du site Internet, y compris la disponibilité de toute fonctionnalité, base de données et/ou contenu.

EuroPetSitter ne sera pas responsable à votre égard ou à l'égard de tiers pour la suspension temporaire de tout ou une partie du site Internet si elle est inférieure à 7 jours (un laps de temps de 2% au total sur une base annuelle). En cas de suspension temporaire constatée par l'Utilisateur et validée par EuroPetSitter dépassant le seuil de 7 jours, l'Utilisateur pourra exiger un allongement au prorata et à titre gratuit de la durée de son abonnement.

La survenance de tout événement indépendant de notre volonté et tout cas de force majeure qui empêche l'utilisation du site dans les conditions initialement prévues, nous décharge de toute responsabilité et nous permet moyennant notification par lettre recommandée, de mettre fin à l'abonnement payant du Prestataire sans indemnité.`
    },
    {
      id: 7,
      title: "Limitation de responsabilité",
      content: `EuroPetSitter ne répond que d'une obligation de moyen concernant les services de mise en relation des Utilisateurs sur la plateforme.

Les informations contenues sur le site Internet EuroPetSitter.fr sont aussi précises que possibles et le site Internet est périodiquement mis à jour mais peut toutefois contenir des inexactitudes, des omissions ou des lacunes. En conséquence, EuroPetSitter ne pourra être tenu responsable d'un quelconque dommage subi par l'ordinateur de l'Utilisateur ou d'une quelconque perte de données consécutive à l'utilisation du site Internet EuroPetSitter.

A défaut de dispositions légales ou réglementaires contraires, la responsabilité de EuroPetSitter est limitée au préjudice direct, personnel et certain subi par les Prestataires de services animaliers et lié à la défaillance en cause.`
    },
    {
      id: 8,
      title: "Données personnelles",
      content: `Les utilisateurs du site Europetsitter disposent des droits suivants : droit à l'information, droit d'accès, droit de rectification, droit à l'effacement, droit à la limitation du traitement, droit d'opposition, droit à la portabilité et droit de ne pas faire l'objet d'une décision individuelle automatisée (articles 13 à 22 du RGPD).

Dans le cadre de son activité commerciale, EuroPetSitter réalise des activités de traitement des données à caractère personnel pour lesquelles elle est responsable des données qu'elle collecte. EuroPetSitter collecte des données relatives à ses Utilisateurs. Ces données sont collectées de manière licite, loyale et transparente.`
    },
    {
      id: 9,
      title: "Cookies",
      content: `EuroPetSitter entend informer les visiteurs du site Internet de l'utilisation de cookies. Les cookies sont de petits fichiers texte qui sont enregistrés sur le disque dur de l'ordinateur de l'Utilisateur du Site. Ils contiennent des informations comme par exemple la préférence linguistique de l'internaute, de manière à ce qu'il ne doive pas à nouveau introduire ces informations lors d'une prochaine visite sur ce même site Internet.

Certains cookies permettent un affichage graphique optimal, d'autres permettent qu'une application Internet fonctionne correctement. Si vous ne voulez pas qu'un site Internet installe des cookies sur votre ordinateur, vous pouvez paramétrer votre navigateur à cet effet.`
    },
    {
      id: 10,
      title: "Propriété intellectuelle",
      content: `Les noms, images, logos ou tout autre signe distinctif présentés sur le site Internet identifiant EuroPetSitter, ses partenaires, des tiers ou leurs produits et services sont des contenus protégés au titre du droit des marques et/ou du droit d'auteur.

La structure générale, les contenus éditoriaux, images, sons, vidéos ou multimédias, les logiciels et les bases de données utilisés et tous les autres contenus présentés sur le site Internet sont protégés au titre du droit d'auteur, des droits voisins et/ou du droit sui generis du producteur de base de données.`
    },
    {
      id: 11,
      title: "Litiges",
      content: `En cas de litige entre parties, sont seuls compétents les tribunaux de Liège (Belgique).
Le droit belge est applicable.
La non-validité ou l'illégalité d'une des clauses prévues dans les conditions générales, n'entraîne aucunement une invalidité ou une nullité des autres clauses qui restent intégralement valables.`
    }
  ]
};

export const COOKIES_CONTENT = {
  sections: [
    {
      id: 1,
      title: "Données personnelles",
      subsections: [
        {
          id: "8.1",
          title: "Les droits des personnes protégées par le RGPD",
          content: `Le RGPD prévoit que les utilisateurs du site Europetsitter disposent des droits suivants : droit à l'information, droit d'accès, droit de rectification, droit à l'effacement, droit à la limitation du traitement, droit d'opposition, droit à la portabilité et droit de ne pas faire l'objet d'une décision individuelle automatisée (articles 13 à 22 du RGPD).`
        },
        {
          id: "8.2",
          title: "Les activités de traitement des données à caractère personnel",
          content: `Dans le cadre de son activité commerciale, EuroPetSitter réalise des activités de traitement des données à caractère personnel pour lesquelles elle est responsable des données qu'elle collecte. EuroPetSitter collecte des données relatives à ses Utilisateurs. Ces données sont collectées de manière licite, loyale et transparente.`
        },
        {
          id: "8.3",
          title: "Transfert des données vers les pays en dehors de l'Union européenne",
          content: `Dans le cadre des activités de traitement pour lesquelles EUROPETSITTER agit en qualité de responsable du traitement, cette dernière n'est pas amenée à conserver ou à transférer des données à caractère personnel en dehors de l'Union européenne.`
        },
        {
          id: "8.4",
          title: "Sécurisation des données",
          content: `EUROPETSITTER a pris des mesures techniques et organisationnelles appropriées pour garantir la sécurisation des données à caractère personnel contre un accès ou un traitement non autorisé, leur perte ou des dégâts.`
        },
        {
          id: "8.5",
          title: "Délégué à la protection des données",
          content: `EUROPETSITTER désigne son administrateur, Monsieur Nicolas Jammaers en tant que délégué à la protection des données (DPO).`
        },
        {
          id: "8.6",
          title: "Les sous-traitants",
          content: `Dans le cadre des activités de traitement pour lesquelles EUROPETSITTER intervient en qualité de responsable du traitement, elle est amenée à communiquer des données à caractère personnel à certains sous-traitants afin de respecter ses obligations légales et contractuelles.`
        }
      ]
    },
    {
      id: 2,
      title: "Cookies",
      content: `EuroPetSitter entend informer les visiteurs du site Internet de l'utilisation de cookies. Les cookies sont de petits fichiers texte qui sont enregistrés sur le disque dur de l'ordinateur de l'Utilisateur du Site. Ils contiennent des informations comme par exemple la préférence linguistique de l'internaute, de manière à ce qu'il ne doive pas à nouveau introduire ces informations lors d'une prochaine visite sur ce même site Internet.

Certains cookies permettent un affichage graphique optimal, d'autres permettent qu'une application Internet fonctionne correctement. Si vous ne voulez pas qu'un site Internet installe des cookies sur votre ordinateur, vous pouvez paramétrer votre navigateur à cet effet.

Lorsque vous désactivez les cookies, tenez toutefois compte du fait que certains éléments graphiques ne s'afficheront peut-être pas correctement ou que vous ne pourrez pas utiliser certaines applications.`
    }
  ]
};