import React, { useState, useEffect } from 'react';
import { Star, AlertCircle } from 'lucide-react';
import { useAuth } from '../hooks/useAuth';
import { useReviews } from '../hooks/useReviews';
import { useReservations } from '../hooks/useReservations';
import { usePetSitterProfile } from '../hooks/usePetSitterProfile';
import type { Review } from '../types';

interface ReviewSectionProps {
  sitterId: string;
  onReviewAdded?: () => void;
}

export default function ReviewSection({ sitterId, onReviewAdded }: ReviewSectionProps) {
  const { user } = useAuth();
  const { loadReviews, addReview, loading } = useReviews(sitterId);
  const { loadProfile } = usePetSitterProfile(sitterId);
  const { reservations, loading: reservationsLoading } = useReservations(undefined, user?.uid);
  const [reviews, setReviews] = useState<Review[]>([]);
  const [newReview, setNewReview] = useState({
    rating: 5,
    comment: '',
    serviceType: ''
  });
  const [showForm, setShowForm] = useState(false);
  const [submitMessage, setSubmitMessage] = useState<{ type: 'success' | 'error'; text: string } | null>(null);

  useEffect(() => {
    loadAndSetReviews();
  }, [sitterId]);

  const loadAndSetReviews = async () => {
    try {
      const loadedReviews = await loadReviews();
      setReviews(loadedReviews);
    } catch (error) {
      console.error('Erreur lors du chargement des avis:', error);
    }
  };

  const canLeaveReview = () => {
    if (!reservations || !user) return false;

    // Vérifier si l'utilisateur a déjà laissé un avis
    const hasExistingReview = reviews.some(review => review.ownerId === user.uid);
    if (hasExistingReview) return false;

    // Vérifier si l'utilisateur a une réservation confirmée et terminée avec ce pet sitter
    const hasCompletedReservation = reservations.some(reservation => {
      const endDate = new Date(reservation.endDate);
      const now = new Date();
      return (
        reservation.sitterId === sitterId &&
        reservation.status === 'confirmed' &&
        endDate < now
      );
    });

    return hasCompletedReservation;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user || !canLeaveReview()) return;

    try {
      await addReview({
        sitterId,
        ownerId: user.uid,
        ownerName: user.displayName || 'Anonyme',
        ownerAvatar: user.photoURL || undefined,
        rating: newReview.rating,
        comment: newReview.comment,
        serviceType: newReview.serviceType
      });

      setNewReview({ rating: 5, comment: '', serviceType: '' });
      setShowForm(false);
      setSubmitMessage({
        type: 'success',
        text: 'Votre avis a été publié avec succès.'
      });

      // Recharger les avis et le profil
      await loadAndSetReviews();
      await loadProfile();
      onReviewAdded?.();
    } catch (error) {
      setSubmitMessage({
        type: 'error',
        text: error instanceof Error ? error.message : 'Une erreur est survenue lors de la soumission de votre avis.'
      });
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h3 className="text-xl font-semibold">Avis ({reviews.length})</h3>
        {user && !showForm && (
          <>
            {canLeaveReview() ? (
              <button
                onClick={() => setShowForm(true)}
                className="text-primary-500 hover:text-primary-600"
              >
                Laisser un avis
              </button>
            ) : (
              <div className="flex items-center text-sm text-gray-500">
                <AlertCircle className="w-4 h-4 mr-2" />
                <span>Vous pourrez laisser un avis une fois votre réservation terminée</span>
              </div>
            )}
          </>
        )}
      </div>

      {submitMessage && (
        <div className={`p-4 rounded-lg ${
          submitMessage.type === 'success' ? 'bg-green-50 text-green-700' : 'bg-red-50 text-red-700'
        }`}>
          {submitMessage.text}
        </div>
      )}

      {showForm && (
        <form onSubmit={handleSubmit} className="bg-gray-50 p-6 rounded-lg">
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Note
              </label>
              <div className="flex gap-2">
                {[1, 2, 3, 4, 5].map((star) => (
                  <button
                    key={star}
                    type="button"
                    onClick={() => setNewReview({ ...newReview, rating: star })}
                    className="focus:outline-none"
                  >
                    <Star
                      className={`w-6 h-6 ${
                        star <= newReview.rating
                          ? 'text-yellow-400 fill-current'
                          : 'text-gray-300'
                      }`}
                    />
                  </button>
                ))}
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Service utilisé
              </label>
              <select
                value={newReview.serviceType}
                onChange={(e) => setNewReview({ ...newReview, serviceType: e.target.value })}
                className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-primary-200 focus:border-primary-500"
                required
              >
                <option value="">Sélectionnez un service</option>
                <option value="hebergement">Hébergement</option>
                <option value="garde">Garde</option>
                <option value="visite">Visite</option>
                <option value="promenade">Promenade</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Votre avis
              </label>
              <textarea
                value={newReview.comment}
                onChange={(e) => setNewReview({ ...newReview, comment: e.target.value })}
                className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-primary-200 focus:border-primary-500"
                rows={4}
                required
                minLength={10}
                maxLength={500}
                placeholder="Partagez votre expérience... (minimum 10 caractères)"
              />
            </div>

            <div className="flex justify-end gap-4">
              <button
                type="button"
                onClick={() => {
                  setShowForm(false);
                  setSubmitMessage(null);
                }}
                className="px-4 py-2 text-gray-600 hover:text-gray-800"
              >
                Annuler
              </button>
              <button
                type="submit"
                disabled={loading || !newReview.serviceType || newReview.comment.length < 10}
                className="px-4 py-2 bg-primary-500 text-white rounded-lg hover:bg-primary-600 disabled:opacity-50"
              >
                {loading ? 'Envoi...' : 'Publier'}
              </button>
            </div>
          </div>
        </form>
      )}

      <div className="space-y-6">
        {reviews.map((review) => (
          <div key={review.id} className="border-b pb-6">
            <div className="flex justify-between items-start">
              <div className="flex items-center">
                <div className="w-10 h-10 rounded-full bg-gray-200 overflow-hidden">
                  {review.ownerAvatar ? (
                    <img
                      src={review.ownerAvatar}
                      alt={review.ownerName}
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    <div className="w-full h-full flex items-center justify-center bg-primary-100 text-primary-500">
                      {review.ownerName[0].toUpperCase()}
                    </div>
                  )}
                </div>
                <div className="ml-4">
                  <div className="font-medium">{review.ownerName}</div>
                  <div className="text-sm text-gray-500">
                    {new Date(review.date).toLocaleDateString()}
                  </div>
                </div>
              </div>
              <div className="flex">
                {Array.from({ length: 5 }).map((_, i) => (
                  <Star
                    key={i}
                    className={`w-5 h-5 ${
                      i < review.rating
                        ? 'text-yellow-400 fill-current'
                        : 'text-gray-300'
                    }`}
                  />
                ))}
              </div>
            </div>
            <div className="mt-4">
              <div className="text-sm text-primary-500 mb-2">
                Service utilisé : {review.serviceType}
              </div>
              <p className="text-gray-600">{review.comment}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}