import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Clock, User, ArrowRight } from 'lucide-react';
import { BLOG_POSTS } from '../data/blogPosts';

export default function BlogList() {
  return (
    <div>
      <motion.h1 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="text-4xl font-bold text-center mb-12"
      >
        Le blog d'EuroPetSitter.fr
      </motion.h1>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {BLOG_POSTS.map((post, index) => (
          <motion.article
            key={post.slug}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
            className="group bg-white rounded-xl shadow-lg overflow-hidden hover:shadow-xl transition-all duration-300 transform hover:-translate-y-2"
          >
            <Link to={`/blog/${post.slug}`} className="block h-full">
              <div className="relative h-48 overflow-hidden">
                <img
                  src={post.image}
                  alt={post.title}
                  className="absolute inset-0 w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-300"
                />
                <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-10 transition-opacity duration-300" />
              </div>
              <div className="p-6">
                <h2 className="text-xl font-bold text-gray-900 mb-2 line-clamp-2 group-hover:text-primary-500 transition-colors duration-300">
                  {post.title}
                </h2>
                <p className="text-gray-600 mb-4 line-clamp-3 group-hover:text-gray-900 transition-colors duration-300">
                  {post.excerpt}
                </p>
                <div className="flex items-center justify-between text-sm text-gray-500">
                  <div className="flex items-center group-hover:text-primary-500 transition-colors duration-300">
                    <User className="w-4 h-4 mr-1" />
                    <span>{post.author}</span>
                  </div>
                  <div className="flex items-center group-hover:text-primary-500 transition-colors duration-300">
                    <Clock className="w-4 h-4 mr-1" />
                    <span>{post.date}</span>
                  </div>
                </div>
                <div className="mt-4 flex items-center text-primary-500 font-medium">
                  <span className="transform translate-x-0 group-hover:translate-x-2 transition-transform duration-300">
                    Lire l'article
                  </span>
                  <ArrowRight className="w-4 h-4 ml-1 transform translate-x-0 group-hover:translate-x-2 transition-transform duration-300" />
                </div>
              </div>
            </Link>
          </motion.article>
        ))}
      </div>
    </div>
  );
}