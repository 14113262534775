import React from 'react';
import BlockedDatesCalendar from '../BlockedDatesCalendar';

interface AvailabilityContentProps {
  formData: any;
  updateFormData: (step: string, data: any) => void;
}

export default function AvailabilityContent({ formData, updateFormData }: AvailabilityContentProps) {
  return (
    <div className="space-y-6">
      <div>
        <h3 className="font-medium mb-2">Dates d'indisponibilité</h3>
        <p className="text-gray-600 text-sm mb-4">
          Marquez vos indisponibilités dans le calendrier ci-dessous "date par date". Vous pouvez également mentionner ces dates dans votre zone "Description" : Fermer du 1 au 15 avril, tout le mois de juillet, le 25 décembre et le 1er janvier, par exemple.
        </p>
        <BlockedDatesCalendar
          blockedDates={formData.availability.blockedDates}
          onDateSelect={(date) => {
            updateFormData('availability', {
              ...formData.availability,
              blockedDates: [...formData.availability.blockedDates, date]
            });
          }}
          onDateRemove={(date) => {
            updateFormData('availability', {
              ...formData.availability,
              blockedDates: formData.availability.blockedDates.filter(d => d !== date)
            });
          }}
        />
      </div>
    </div>
  );
}