import React, { useState, useEffect } from 'react';
import { collection, query, getDocs } from 'firebase/firestore';
import { db } from '../config/firebase';
import { motion } from 'framer-motion';
import type { PetSitter } from '../types';
import PetSitterCard from '../components/PetSitterCard';
import SearchFilters from '../components/SearchFilters';
import { MOCK_SITTERS } from '../data/mockData';

export type FilterState = {
  animalType: string;
  serviceType: string;
  location: string;
  coordinates: { latitude: number; longitude: number } | null;
  radius: number;
  options: {
    option1: string;
    option2: string;
    option3: string;
    option4: string;
    option5: string;
  };
};

export default function SearchPage() {
  const [filters, setFilters] = useState<FilterState>({
    animalType: '',
    serviceType: '',
    location: '',
    coordinates: null,
    radius: 10,
    options: {
      option1: '',
      option2: '',
      option3: '',
      option4: '',
      option5: ''
    }
  });
  const [sitters, setSitters] = useState<PetSitter[]>([]);
  const [filteredSitters, setFilteredSitters] = useState<PetSitter[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadSitters();
  }, []);

  const loadSitters = async () => {
    try {
      const q = collection(db, 'sitterProfiles');
      const querySnapshot = await getDocs(q);
      const sittersData: PetSitter[] = [];
      
      querySnapshot.forEach((doc) => {
        const sitterData = doc.data();
        if (sitterData.services?.length > 0 && !sitterData.suspended) {
          sittersData.push({
            id: doc.id,
            ...sitterData
          } as PetSitter);
        }
      });

      const activeMockSitters = MOCK_SITTERS.filter(sitter => !sitter.suspended);
      const allSitters = [...sittersData, ...activeMockSitters];
      setSitters(allSitters);
      setFilteredSitters(allSitters);
      setLoading(false);
    } catch (error) {
      console.error('Erreur lors du chargement des pet sitters:', error);
      const activeMockSitters = MOCK_SITTERS.filter(sitter => !sitter.suspended);
      setSitters(activeMockSitters);
      setFilteredSitters(activeMockSitters);
      setLoading(false);
    }
  };

  const calculateDistance = (lat1: number, lon1: number, lat2: number, lon2: number): number => {
    const toRad = (value: number) => (value * Math.PI) / 180;
    const R = 6371;

    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;

    return distance;
  };

  useEffect(() => {
    const filtered = sitters.filter(sitter => {
      if (sitter.suspended) {
        return false;
      }

      const matchesAnimal = !filters.animalType || 
        sitter.animals.includes(filters.animalType);
      
      const matchesService = !filters.serviceType ||
        sitter.services.some(s => s.type === filters.serviceType);

      let matchesRadius = true;
      if (filters.coordinates && sitter.coordinates) {
        const distance = calculateDistance(
          filters.coordinates.latitude,
          filters.coordinates.longitude,
          sitter.coordinates.latitude,
          sitter.coordinates.longitude
        );
        matchesRadius = distance <= filters.radius;
      }

      let matchesOptions = true;
      if (filters.animalType && filters.serviceType) {
        const serviceOptions = sitter.serviceOptions || {};
        
        if (filters.animalType.includes('chien')) {
          if ((filters.serviceType === 'hebergement' || filters.serviceType === 'garde') && filters.options.option1) {
            matchesOptions = serviceOptions.hebergementChien?.includes(filters.options.option1) || 
                           serviceOptions.gardeChien?.includes(filters.options.option1) || false;
          } else if (filters.serviceType === 'visite' && filters.options.option2) {
            matchesOptions = serviceOptions.visiteChien?.includes(filters.options.option2) || false;
          } else if (filters.serviceType === 'promenade' && filters.options.option3) {
            matchesOptions = serviceOptions.promenadeChien?.includes(filters.options.option3) || false;
          }
        } else if (filters.animalType === 'chat') {
          if ((filters.serviceType === 'hebergement' || filters.serviceType === 'garde') && filters.options.option4) {
            matchesOptions = serviceOptions.hebergementChat?.includes(filters.options.option4) || 
                           serviceOptions.gardeChat?.includes(filters.options.option4) || false;
          } else if (filters.serviceType === 'visite' && filters.options.option5) {
            matchesOptions = serviceOptions.visiteChat?.includes(filters.options.option5) || false;
          }
        }
      }

      return matchesAnimal && matchesService && matchesRadius && matchesOptions;
    });

    if (filters.coordinates) {
      filtered.sort((a, b) => {
        if (!a.coordinates || !b.coordinates) return 0;
        
        const distanceA = calculateDistance(
          filters.coordinates!.latitude,
          filters.coordinates!.longitude,
          a.coordinates.latitude,
          a.coordinates.longitude
        );
        
        const distanceB = calculateDistance(
          filters.coordinates!.latitude,
          filters.coordinates!.longitude,
          b.coordinates.latitude,
          b.coordinates.longitude
        );
        
        return distanceA - distanceB;
      });
    }

    setFilteredSitters(filtered);
  }, [filters, sitters]);

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 py-8">
        <div className="max-w-7xl mx-auto px-4">
          <div className="animate-pulse">
            <div className="h-8 bg-gray-200 rounded w-1/4 mb-6"></div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {[1, 2, 3].map((i) => (
                <div key={i} className="h-96 bg-gray-200 rounded"></div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="min-h-screen bg-gray-50"
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex flex-col md:flex-row gap-8">
          <SearchFilters
            filters={filters}
            onChange={setFilters}
            className="w-full md:w-80 flex-shrink-0"
            sitters={filteredSitters}
          />

          <div className="flex-1">
            <div className="mb-6">
              <h1 className="text-2xl font-bold text-gray-900">
                {filteredSitters.length} PetSitter{filteredSitters.length > 1 ? 's' : ''} disponible{filteredSitters.length > 1 ? 's' : ''}
              </h1>
              <p className="text-gray-600 mt-1">
                {filters.location ? `Près de ${filters.location}` : 'Toutes les zones'}
              </p>
              {filters.coordinates && (
                <p className="text-sm text-gray-500 mt-1">
                  Rayon de recherche : {filters.radius} km
                </p>
              )}
            </div>

            {filteredSitters.length === 0 ? (
              <div className="text-center py-12 bg-white rounded-lg shadow">
                <p className="text-gray-600">
                  Aucun PetSitter ne correspond à vos critères de recherche.
                </p>
                <button
                  onClick={() => setFilters({
                    animalType: '',
                    serviceType: '',
                    location: '',
                    coordinates: null,
                    radius: 10,
                    options: {
                      option1: '',
                      option2: '',
                      option3: '',
                      option4: '',
                      option5: ''
                    }
                  })}
                  className="mt-4 text-primary-500 hover:text-primary-600"
                >
                  Réinitialiser les critères
                </button>
              </div>
            ) : (
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                {filteredSitters.map((sitter) => (
                  <PetSitterCard
                    key={sitter.id}
                    sitter={sitter}
                    onBooking={() => {}}
                    requireAuth={true}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </motion.div>
  );
}