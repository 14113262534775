import type { BlogPost } from '../types';

export const BLOG_POSTS: BlogPost[] = [
  {
    slug: 'alimentation-saine-chat',
    title: "Une alimentation saine pour votre chat",
    excerpt: "Guide complet sur l'alimentation féline : découvrez les principes d'une nutrition équilibrée et les aliments essentiels pour la santé de votre chat.",
    image: "https://images.unsplash.com/photo-1615789591457-74a63395c990?q=80&w=1000&auto=format&fit=crop",
    author: "EuroPetSitter",
    date: "15 mars 2024",
    readTime: 10,
    content: [
      {
        type: 'paragraph',
        content: "L'alimentation est un pilier fondamental de la santé de votre chat. Un régime alimentaire équilibré est essentiel pour maintenir son système immunitaire, sa vitalité et sa longévité. En tant que carnivores stricts, les chats ont des besoins nutritionnels spécifiques qui doivent être satisfaits quotidiennement."
      },
      {
        type: 'subtitle',
        content: "Les besoins nutritionnels essentiels"
      },
      {
        type: 'list',
        items: [
          "Protéines de haute qualité (viande, poisson) - minimum 26% de la ration quotidienne",
          "Taurine - acide aminé essentiel pour la vision et le cœur",
          "Acides gras essentiels (oméga-3 et oméga-6) pour un pelage sain",
          "Vitamines A, D, E et B complexe",
          "Minéraux : calcium, phosphore, magnésium"
        ]
      },
      {
        type: 'subtitle',
        content: "Types d'alimentation recommandés"
      },
      {
        type: 'paragraph',
        content: "Il existe plusieurs options pour nourrir votre chat de manière équilibrée. Les croquettes premium offrent une solution pratique et nutritionnellement complète. Les pâtées de qualité peuvent être utilisées en complément ou comme alimentation principale. L'alimentation mixte (croquettes et pâtée) est souvent appréciée des chats et permet de varier les apports nutritionnels."
      },
      {
        type: 'subtitle',
        content: "Aliments à privilégier"
      },
      {
        type: 'list',
        items: [
          "Viandes maigres (poulet, dinde, lapin)",
          "Poissons riches en oméga-3 (saumon, sardines)",
          "Croquettes premium avec minimum 30% de protéines",
          "Pâtées sans céréales avec viande comme premier ingrédient",
          "Eau fraîche et propre en permanence"
        ]
      },
      {
        type: 'subtitle',
        content: "Aliments toxiques à éviter absolument"
      },
      {
        type: 'list',
        items: [
          "Chocolat et café (contiennent de la théobromine toxique)",
          "Oignons et ail (peuvent causer une anémie)",
          "Raisins et raisins secs (toxiques pour les reins)",
          "Lait de vache (indigeste pour les chats adultes)",
          "Aliments crus ou avariés (risques bactériens)"
        ]
      },
      {
        type: 'subtitle',
        content: "Adapter l'alimentation selon l'âge"
      },
      {
        type: 'paragraph',
        content: "Les besoins nutritionnels évoluent avec l'âge. Les chatons nécessitent une alimentation riche en protéines et en calories pour soutenir leur croissance. Les chats adultes ont besoin d'un régime équilibré pour maintenir leur poids idéal. Les seniors peuvent nécessiter une alimentation adaptée avec moins de calories mais plus de nutriments spécifiques pour soutenir leur santé articulaire et rénale."
      }
    ]
  },
  {
    slug: 'alimentation-saine-chien',
    title: "Une alimentation saine pour votre chien",
    excerpt: "Guide complet pour une alimentation canine équilibrée : découvrez comment adapter la nutrition de votre chien selon son âge, sa taille et son niveau d'activité.",
    image: "https://images.unsplash.com/photo-1623387641168-d9803ddd3f35?q=80&w=1000&auto=format&fit=crop",
    author: "EuroPetSitter",
    date: "10 mars 2024",
    readTime: 12,
    content: [
      {
        type: 'paragraph',
        content: "Une alimentation adaptée est la clé de la santé et du bien-être de votre chien. Contrairement aux chats, les chiens sont des omnivores et leurs besoins nutritionnels varient considérablement selon leur âge, leur race, leur taille et leur niveau d'activité."
      },
      {
        type: 'subtitle',
        content: "Principes fondamentaux de l'alimentation canine"
      },
      {
        type: 'list',
        items: [
          "Protéines de qualité (18-25% pour un adulte)",
          "Matières grasses équilibrées (5-15%)",
          "Glucides digestibles comme source d'énergie",
          "Fibres pour une bonne digestion",
          "Vitamines et minéraux essentiels"
        ]
      },
      {
        type: 'subtitle',
        content: "Adapter les portions selon le profil"
      },
      {
        type: 'paragraph',
        content: "La quantité de nourriture doit être précisément adaptée pour éviter le surpoids ou la malnutrition. Un chien actif ou de grande taille aura besoin de plus de calories qu'un chien sédentaire ou de petite taille. Par exemple, un Border Collie actif peut nécessiter jusqu'à deux fois plus de calories qu'un Bouledogue français de même poids."
      },
      {
        type: 'subtitle',
        content: "Aliments bénéfiques recommandés"
      },
      {
        type: 'list',
        items: [
          "Viandes maigres (poulet, dinde, bœuf maigre)",
          "Poissons riches en oméga-3 (saumon, sardines)",
          "Légumes (carottes, haricots verts, patates douces)",
          "Fruits en modération (pommes, poires sans pépins)",
          "Riz brun et quinoa pour les glucides complexes"
        ]
      },
      {
        type: 'subtitle',
        content: "Aliments dangereux à éviter"
      },
      {
        type: 'list',
        items: [
          "Chocolat et produits contenant de la caféine",
          "Raisins et raisins secs (toxiques pour les reins)",
          "Oignons, ail et poireaux",
          "Avocat (contient de la persine toxique)",
          "Noix de macadamia",
          "Os cuits (risques d'étouffement et de perforation)"
        ]
      },
      {
        type: 'subtitle',
        content: "Signes d'une alimentation équilibrée"
      },
      {
        type: 'paragraph',
        content: "Un chien bien nourri présente un pelage brillant, une peau saine, des selles fermes et régulières, un poids stable et une bonne énergie. La surveillance régulière du poids et de la condition physique permet d'ajuster l'alimentation si nécessaire."
      }
    ]
  },
  {
    slug: 'chien-actif-sante',
    title: "Garder son chien actif et en bonne santé",
    excerpt: "Découvrez les meilleures pratiques pour maintenir votre chien en forme physiquement et mentalement, avec des exercices adaptés et des activités stimulantes.",
    image: "https://images.unsplash.com/photo-1530281700549-e82e7bf110d6?q=80&w=1000&auto=format&fit=crop",
    author: "EuroPetSitter",
    date: "5 mars 2024",
    readTime: 15,
    content: [
      {
        type: 'paragraph',
        content: "Maintenir votre chien actif est essentiel pour sa santé physique et mentale. Un chien qui reçoit suffisamment d'exercice et de stimulation mentale est généralement plus heureux, plus équilibré et développe moins de problèmes comportementaux."
      },
      {
        type: 'subtitle',
        content: "L'importance de l'exercice quotidien"
      },
      {
        type: 'paragraph',
        content: "Les besoins en exercice varient selon la race, l'âge et la condition physique de votre chien. Un Border Collie adulte peut nécessiter jusqu'à 2 heures d'exercice par jour, tandis qu'un Bouledogue français peut se contenter de 30 minutes. L'important est d'établir une routine régulière adaptée à votre compagnon."
      },
      {
        type: 'subtitle',
        content: "Activités physiques recommandées"
      },
      {
        type: 'list',
        items: [
          "Promenades quotidiennes variées (30-60 minutes minimum)",
          "Jeux de balle et de frisbee pour le cardio",
          "Natation (excellent exercice à faible impact)",
          "Randonnées en nature",
          "Parcours d'agilité adaptés au niveau",
          "Jeux de traction avec modération"
        ]
      },
      {
        type: 'subtitle',
        content: "Stimulation mentale essentielle"
      },
      {
        type: 'paragraph',
        content: "La stimulation mentale est aussi importante que l'exercice physique. Un chien mentalement stimulé est moins susceptible de développer des comportements destructeurs ou anxieux. Voici plusieurs activités enrichissantes :"
      },
      {
        type: 'list',
        items: [
          "Jouets interactifs et puzzles pour chiens",
          "Exercices d'obéissance et nouveaux tours",
          "Jeux de recherche et de flair",
          "Socialisation avec d'autres chiens",
          "Sessions d'entraînement variées"
        ]
      },
      {
        type: 'subtitle',
        content: "Signes d'une bonne santé physique"
      },
      {
        type: 'list',
        items: [
          "Pelage brillant et peau saine",
          "Poids stable et silhouette équilibrée",
          "Bon appétit et digestion régulière",
          "Énergie constante tout au long de la journée",
          "Sommeil réparateur",
          "Comportement équilibré et sociable"
        ]
      },
      {
        type: 'subtitle',
        content: "Précautions importantes"
      },
      {
        type: 'paragraph',
        content: "Adaptez toujours l'intensité des exercices à la condition physique de votre chien. Évitez les activités intenses par temps chaud ou froid extrême. Surveillez les signes de fatigue ou d'inconfort. Consultez votre vétérinaire avant de démarrer un nouveau programme d'exercice, particulièrement pour les chiots, les seniors ou les chiens ayant des problèmes de santé."
      }
    ]
  }
];