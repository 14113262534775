import React, { useState, useEffect } from 'react';
import { Star, MapPin, Home, Users, TreePine, GraduationCap } from 'lucide-react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import { useAuth } from '../hooks/useAuth';
import type { PetSitter } from '../types';
import ReviewSection from '../components/ReviewSection';
import ReservationModal from '../components/ReservationModal';
import PhotoGallerySlider from '../components/PhotoGallerySlider';

const HOUSING_LABELS: { [key: string]: string } = {
  'maison': 'Maison',
  'appartement': 'Appartement',
  'propriete': 'Propriété à la campagne'
};

const CHILDREN_LABELS: { [key: string]: string } = {
  'no-answer': 'Non spécifié',
  'no-children': "Pas d'enfant",
  'toddlers': 'Bambins (-3 ans)',
  'children': 'Enfants (-12 ans)',
  'teenagers': 'Adolescents (+12 ans)'
};

const WALKING_AREAS_LABELS: { [key: string]: string } = {
  'off-leash': 'Zone sans laisse à proximité',
  'park': 'Parc',
  'beach': 'Plage',
  'forest': 'Forêt',
  'countryside': 'Campagne'
};

const EXPERIENCE_LABELS: { [key: string]: string } = {
  'volunteer': 'Expérience en tant que bénévole dans le domaine du bien-être animal',
  'behavior': 'Expérience avec des problèmes de comportement',
  'rescue': 'Expérience avec des animaux de compagnie de sauvetage',
  'training': 'Expérience avec les techniques de dressage de chien'
};

const YEARS_OF_EXPERIENCE_LABELS: { [key: string]: string } = {
  'less-1': "Moins de 1 an d'expérience",
  'less-5': "Moins de 5 ans d'expérience",
  'more-5': "Plus de 5 ans d'expérience",
  'more-10': "Plus de 10 ans d'expérience"
};

export default function PetSitterDetailsPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user, userData } = useAuth();
  const [sitter, setSitter] = useState<PetSitter | null>(null);
  const [loading, setLoading] = useState(true);
  const [isReservationModalOpen, setIsReservationModalOpen] = useState(false);

  useEffect(() => {
    loadSitterProfile();
  }, [id]);

  const loadSitterProfile = async () => {
    if (!id) return;
    
    try {
      setLoading(true);
      const docRef = doc(db, 'sitterProfiles', id);
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        setSitter({ id: docSnap.id, ...docSnap.data() } as PetSitter);
      }
    } catch (error) {
      console.error('Erreur lors du chargement du profil:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleReservationClick = () => {
    if (!user) {
      navigate('/auth');
      return;
    }

    if (userData?.role === 'sitter') {
      alert("En tant que PetSitter, vous ne pouvez pas réserver de services.");
      return;
    }

    setIsReservationModalOpen(true);
  };

  const handleReservationConfirm = () => {
    console.log('Réservation confirmée');
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 py-8 flex items-center justify-center">
        <div className="animate-pulse">
          <div className="h-8 bg-gray-200 rounded w-48 mb-4"></div>
          <div className="h-4 bg-gray-200 rounded w-32"></div>
        </div>
      </div>
    );
  }

  if (!sitter) {
    return (
      <div className="min-h-screen bg-gray-50 py-8 flex items-center justify-center">
        <p className="text-gray-500">PetSitter non trouvé</p>
      </div>
    );
  }

  const displayName = sitter.businessName || sitter.name;

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white rounded-xl shadow-lg overflow-hidden">
          {/* Photo Gallery */}
          {sitter.photos && sitter.photos.length > 0 ? (
            <PhotoGallerySlider photos={sitter.photos} />
          ) : (
            <div className="h-64 bg-gradient-to-br from-primary-500 to-primary-600" />
          )}

          {/* Profile Content */}
          <div className="p-8">
            <div className="flex justify-between items-start mb-6">
              <div className="flex items-center">
                <div className="w-20 h-20 rounded-full overflow-hidden border-4 border-white shadow-lg mr-4">
                  <img
                    src={sitter.avatar || `https://source.unsplash.com/400x400/?portrait&random=${sitter.id}`}
                    alt={displayName}
                    className="w-full h-full object-cover"
                  />
                </div>
                <div>
                  <h1 className="text-2xl font-bold text-gray-900">{displayName}</h1>
                  <div className="flex items-center mt-2 text-gray-600">
                    <MapPin className="w-4 h-4 mr-1" />
                    <span>{sitter.location}</span>
                  </div>
                </div>
              </div>
              <div className="flex items-center">
                <Star className="w-6 h-6 text-yellow-400 fill-current" />
                <span className="ml-1 font-medium text-lg">{sitter.rating.toFixed(1)}</span>
              </div>
            </div>

            <div className="prose max-w-none mb-8">
              <p className="text-gray-600">{sitter.description}</p>
            </div>

            {/* Spécificités */}
            {sitter.specificities && (
              <div className="mb-8">
                <h3 className="font-medium mb-4">Spécificités</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {/* Type d'habitation */}
                  {sitter.specificities.housingType && (
                    <div className="flex items-start">
                      <Home className="w-5 h-5 mr-3 text-primary-500 flex-shrink-0 mt-1" />
                      <div>
                        <h4 className="font-medium text-sm">Type d'habitation</h4>
                        <p className="text-gray-600">{HOUSING_LABELS[sitter.specificities.housingType]}</p>
                      </div>
                    </div>
                  )}

                  {/* Enfants */}
                  {sitter.specificities.children && (
                    <div className="flex items-start">
                      <Users className="w-5 h-5 mr-3 text-primary-500 flex-shrink-0 mt-1" />
                      <div>
                        <h4 className="font-medium text-sm">Enfants</h4>
                        <p className="text-gray-600">{CHILDREN_LABELS[sitter.specificities.children]}</p>
                      </div>
                    </div>
                  )}

                  {/* Zones de promenade */}
                  {sitter.specificities.walkingAreas && sitter.specificities.walkingAreas.length > 0 && (
                    <div className="flex items-start">
                      <TreePine className="w-5 h-5 mr-3 text-primary-500 flex-shrink-0 mt-1" />
                      <div>
                        <h4 className="font-medium text-sm">Zones de promenade</h4>
                        <ul className="list-none p-0 m-0 text-gray-600">
                          {sitter.specificities.walkingAreas.map(area => (
                            <li key={area}>{WALKING_AREAS_LABELS[area]}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  )}

                  {/* Expérience */}
                  {sitter.specificities.experience && sitter.specificities.experience.length > 0 && (
                    <div className="flex items-start">
                      <GraduationCap className="w-5 h-5 mr-3 text-primary-500 flex-shrink-0 mt-1" />
                      <div>
                        <h4 className="font-medium text-sm">Expérience</h4>
                        <ul className="list-none p-0 m-0 text-gray-600">
                          {sitter.specificities.experience.map(exp => (
                            <li key={exp}>{EXPERIENCE_LABELS[exp]}</li>
                          ))}
                        </ul>
                        {sitter.specificities.yearsOfExperience && (
                          <p className="text-primary-500 mt-2">
                            {YEARS_OF_EXPERIENCE_LABELS[sitter.specificities.yearsOfExperience]}
                          </p>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}

            {/* Types d'animaux acceptés */}
            <div className="mb-8">
              <h3 className="font-medium mb-4">Animaux acceptés</h3>
              <div className="flex flex-wrap gap-2">
                {sitter.animals.map((animal) => (
                  <span
                    key={animal}
                    className="px-3 py-1 bg-primary-100 text-primary-700 rounded-full text-sm"
                  >
                    {animal}
                  </span>
                ))}
              </div>
            </div>

            {/* Services proposés */}
            <div className="border-t pt-6">
              <h3 className="font-medium mb-4">Services proposés</h3>
              <div className="space-y-3">
                {sitter.services.map((service) => (
                  <div 
                    key={service.type}
                    className="flex flex-col p-4 bg-gray-50 rounded-lg"
                  >
                    <div className="flex justify-between items-start mb-2">
                      <span className="font-medium">{service.description}</span>
                      <div className="text-right">
                        <div className="font-medium">{service.price}€/jour</div>
                        {service.priceWeek && (
                          <div className="text-sm text-gray-600 mt-1">
                            {service.priceWeek}€/semaine
                          </div>
                        )}
                      </div>
                    </div>
                    {service.priceWeek && (
                      <div className="text-sm text-gray-500 mt-1">
                        Tarif hebdomadaire disponible pour les réservations de 7 jours ou plus
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>

            {/* Section des avis */}
            <div className="border-t pt-6 mt-8">
              <ReviewSection 
                sitterId={sitter.id}
                onReviewAdded={loadSitterProfile}
              />
            </div>

            {/* Bouton de réservation */}
            <div className="mt-8">
              <button
                onClick={handleReservationClick}
                className="w-full max-w-sm mx-auto block bg-primary-500 text-white px-6 py-3 rounded-lg hover:bg-primary-600 transition"
              >
                Réserver
              </button>
            </div>
          </div>
        </div>

        {/* Modal de réservation */}
        {sitter && (
          <ReservationModal
            isOpen={isReservationModalOpen}
            onClose={() => setIsReservationModalOpen(false)}
            sitter={sitter}
          />
        )}
      </div>
    </div>
  );
}