import React, { useState } from 'react';
import { format, startOfMonth, endOfMonth, eachDayOfInterval, isSameMonth, isToday, isBefore, isWithinInterval, parseISO, startOfDay, addHours } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useCalendar } from '../hooks/useCalendar';

interface CalendarProps {
  sitterId: string;
  onDateSelect: (date: Date) => void;
  mode?: 'edit' | 'view';
  selectedDate?: string;
  endDate?: string;
}

export default function Calendar({ sitterId, onDateSelect, mode = 'view', selectedDate, endDate }: CalendarProps) {
  const [currentMonth, setCurrentMonth] = React.useState(new Date());
  const { isDateBlocked, isDateBooked, loading } = useCalendar(sitterId);
  const [isSelectingPeriod, setIsSelectingPeriod] = useState(false);

  const monthStart = startOfMonth(currentMonth);
  const monthEnd = endOfMonth(currentMonth);
  const daysInMonth = eachDayOfInterval({ start: monthStart, end: monthEnd });

  const isDateSelectable = (date: Date) => {
    const today = startOfDay(new Date());
    const formattedDate = format(addHours(date, 2), 'yyyy-MM-dd');
    
    // Vérifier si la date n'est pas dans le passé
    if (isBefore(date, today) && !isToday(date)) {
      return false;
    }

    // Vérifier si la date n'est pas bloquée ou réservée
    if (isDateBlocked(formattedDate) || isDateBooked(formattedDate)) {
      return false;
    }

    return true;
  };

  const isPeriodValid = (start: Date, end: Date) => {
    const dates = eachDayOfInterval({ start, end });
    return dates.every(date => {
      const formattedDate = format(addHours(date, 2), 'yyyy-MM-dd');
      return !isDateBlocked(formattedDate) && !isDateBooked(formattedDate);
    });
  };

  const handleDateSelect = (date: Date) => {
    const clickedDate = format(addHours(date, 2), 'yyyy-MM-dd');

    // Si on clique sur la date de début déjà sélectionnée, on réinitialise tout
    if (clickedDate === selectedDate) {
      onDateSelect(new Date(0));
      setIsSelectingPeriod(false);
      return;
    }

    // Si on clique sur la date de fin déjà sélectionnée, on revient à la date de début
    if (clickedDate === endDate) {
      onDateSelect(parseISO(selectedDate!));
      setIsSelectingPeriod(false);
      return;
    }

    // Si aucune date n'est sélectionnée ou si on a réinitialisé
    if (!selectedDate || selectedDate === format(new Date(0), 'yyyy-MM-dd')) {
      onDateSelect(date);
      setIsSelectingPeriod(true);
      return;
    }

    // Si on est en train de sélectionner une période
    if (isSelectingPeriod) {
      const start = parseISO(selectedDate);
      
      // Si la date sélectionnée est avant la date de début, on la définit comme nouvelle date de début
      if (isBefore(date, start)) {
        onDateSelect(date);
        return;
      }
      
      // Vérifier si la période est valide
      if (isPeriodValid(start, date)) {
        onDateSelect(date);
        setIsSelectingPeriod(false);
      } else {
        alert("La période sélectionnée contient des dates indisponibles. Veuillez choisir une autre période.");
        onDateSelect(new Date(0));
        setIsSelectingPeriod(false);
      }
    } else {
      // On commence une nouvelle sélection
      onDateSelect(date);
      setIsSelectingPeriod(true);
    }
  };

  const isInRange = (date: Date) => {
    if (!selectedDate || !endDate || selectedDate === format(new Date(0), 'yyyy-MM-dd')) return false;
    const start = startOfDay(parseISO(selectedDate));
    const end = startOfDay(parseISO(endDate));
    return isWithinInterval(startOfDay(date), { start, end });
  };

  // Jours de la semaine en français
  const WEEKDAYS = ['L', 'Ma', 'Me', 'J', 'V', 'S', 'D'];

  if (loading) {
    return (
      <div className="bg-white rounded-lg p-4">
        <div className="animate-pulse">
          <div className="h-6 bg-gray-200 rounded w-1/3 mb-4"></div>
          <div className="grid grid-cols-7 gap-1">
            {Array.from({ length: 35 }).map((_, i) => (
              <div key={i} className="h-10 bg-gray-200 rounded"></div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg p-4">
      <div className="flex justify-between items-center mb-4">
        <h3 className="font-medium">
          {format(currentMonth, 'MMMM yyyy', { locale: fr })}
        </h3>
        <div className="flex space-x-2">
          <button
            onClick={() => setCurrentMonth(prev => new Date(prev.getFullYear(), prev.getMonth() - 1))}
            className="p-2 hover:bg-gray-100 rounded-lg"
          >
            ←
          </button>
          <button
            onClick={() => setCurrentMonth(prev => new Date(prev.getFullYear(), prev.getMonth() + 1))}
            className="p-2 hover:bg-gray-100 rounded-lg"
          >
            →
          </button>
        </div>
      </div>

      <div className="grid grid-cols-7 gap-1">
        {WEEKDAYS.map((day, index) => (
          <div key={`weekday-${index}`} className="text-center text-sm font-medium text-gray-500 py-2">
            {day}
          </div>
        ))}

        {Array.from({ length: monthStart.getDay() - 1 >= 0 ? monthStart.getDay() - 1 : 6 }).map((_, i) => (
          <div key={`empty-start-${i}`} className="h-10" />
        ))}

        {daysInMonth.map((date) => {
          const formattedDate = format(addHours(date, 2), 'yyyy-MM-dd');
          const selectable = isDateSelectable(date);
          const isSelected = selectedDate === formattedDate && selectedDate !== format(new Date(0), 'yyyy-MM-dd');
          const isEnd = endDate === formattedDate;
          const isRange = isInRange(date);
          const blocked = isDateBlocked(formattedDate);
          const booked = isDateBooked(formattedDate);

          return (
            <button
              key={formattedDate}
              onClick={() => selectable && handleDateSelect(date)}
              disabled={!selectable || blocked || booked}
              className={`
                h-10 rounded-lg flex items-center justify-center text-sm transition-colors relative
                ${isSelected ? 'bg-primary-500 text-white font-semibold' : ''}
                ${isEnd ? 'bg-primary-500 text-white font-semibold' : ''}
                ${isRange && !isSelected && !isEnd ? 'bg-primary-200 text-primary-800 font-medium' : ''}
                ${!isSelected && !isEnd && !isRange && selectable ? 'bg-primary-50 hover:bg-primary-100' : ''}
                ${(blocked || booked || !selectable) ? 'bg-gray-100 text-gray-400 cursor-not-allowed' : ''}
                ${!isSameMonth(date, currentMonth) ? 'opacity-50' : ''}
                ${selectable && !isSelected && !isEnd && !isRange ? 'text-primary-700' : ''}
                ${isRange ? 'hover:bg-primary-300' : ''}
              `}
            >
              {format(date, 'd')}
              {(isSelected || isEnd) && (
                <div className="absolute inset-0 border-2 border-primary-600 rounded-lg pointer-events-none"></div>
              )}
            </button>
          );
        })}
      </div>

      <div className="mt-4 space-y-2">
        <div className="flex items-center gap-2 text-sm">
          <div className="w-4 h-4 rounded-full bg-primary-50 border border-primary-500"></div>
          <span>Disponible</span>
        </div>
        <div className="flex items-center gap-2 text-sm">
          <div className="w-4 h-4 rounded-full bg-primary-500"></div>
          <span>Date sélectionnée</span>
        </div>
        <div className="flex items-center gap-2 text-sm">
          <div className="w-4 h-4 rounded-full bg-primary-200"></div>
          <span>Période sélectionnée</span>
        </div>
        <div className="flex items-center gap-2 text-sm">
          <div className="w-4 h-4 rounded-full bg-gray-200"></div>
          <span>Indisponible</span>
        </div>
      </div>
    </div>
  );
}