import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { motion } from 'framer-motion';
import { Search, PawPrint } from 'lucide-react';

export default function Hero() {
  const navigate = useNavigate();
  const { userData } = useAuth();

  const handleSearchClick = () => {
    if (userData?.role === 'sitter') {
      alert("En tant que pet sitter, vous ne pouvez pas réserver de services.");
      return;
    }
    navigate('/search');
  };

  return (
    <div className="relative bg-white overflow-hidden">
      {/* Background pattern */}
      <div className="absolute inset-0 opacity-5">
        <div className="absolute inset-0" style={{
          backgroundImage: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23FFB908' fill-opacity='1'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
        }} />
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="relative pt-20 pb-24 sm:pt-24 sm:pb-32">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-center"
          >
            <motion.h1 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
              className="text-4xl sm:text-5xl md:text-6xl font-bold text-gray-900 tracking-tight mb-8"
            >
              Trouvez en France votre{' '}
              <span className="text-primary-500 inline-block">PetSitter</span>{' '}
              <br className="hidden sm:block" />
              et le service animalier adéquat !
            </motion.h1>

            <motion.p 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.4 }}
              className="text-xl text-gray-600 max-w-2xl mx-auto mb-12"
            >
              Une application simple, efficace et gratuite pour les détenteurs d'animaux de compagnie. Vous sélectionnez un type d'animal, le service animalier désiré avec option, votre code postal, un rayon de recherche et le tour est joué !
            </motion.p>
            
            {userData?.role !== 'sitter' && (
              <motion.div 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.6 }}
                className="flex flex-col sm:flex-row justify-center gap-6"
              >
                <motion.button 
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={handleSearchClick}
                  className="group relative inline-flex items-center justify-center bg-primary-500 text-white px-8 py-4 text-lg font-semibold overflow-hidden rounded-xl transition-all duration-300 ease-out hover:bg-primary-600 shadow-lg hover:shadow-xl"
                >
                  <span className="absolute right-0 w-12 h-32 -mt-12 transition-all duration-1000 transform translate-x-12 bg-white opacity-10 rotate-12 group-hover:-translate-x-96 ease"></span>
                  <Search className="w-6 h-6 mr-3" />
                  Chercher un PetSitter
                </motion.button>

                <motion.button 
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={() => navigate('/auth')}
                  className="group relative inline-flex items-center justify-center bg-white text-primary-500 border-2 border-primary-500 px-8 py-4 text-lg font-semibold overflow-hidden rounded-xl transition-all duration-300 ease-out hover:bg-primary-50 shadow-lg hover:shadow-xl"
                >
                  <span className="absolute right-0 w-12 h-32 -mt-12 transition-all duration-1000 transform translate-x-12 bg-primary-500 opacity-10 rotate-12 group-hover:-translate-x-96 ease"></span>
                  <PawPrint className="w-6 h-6 mr-3" />
                  Devenir PetSitter
                </motion.button>
              </motion.div>
            )}
          </motion.div>

          {/* Decorative dots */}
          <div className="absolute top-1/4 left-0 w-72 h-72 bg-primary-500 rounded-full mix-blend-multiply filter blur-2xl opacity-10 animate-blob"></div>
          <div className="absolute top-1/3 right-0 w-72 h-72 bg-primary-500 rounded-full mix-blend-multiply filter blur-2xl opacity-10 animate-blob animation-delay-2000"></div>
        </div>
      </div>
    </div>
  );
}