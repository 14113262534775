import React from 'react';
import ServiceOptionsSelector from '../ServiceOptionsSelector';

const HOUSING_OPTIONS = [
  { id: 'maison', label: 'Maison' },
  { id: 'appartement', label: 'Appartement' },
  { id: 'propriete', label: 'Propriété à la campagne' }
];

const CHILDREN_OPTIONS = [
  { id: 'no-answer', label: 'Je ne réponds pas à cette question' },
  { id: 'no-children', label: "Pas d'enfants" },
  { id: 'toddlers', label: 'Bambins à la maison (-6 ans)' },
  { id: 'children', label: 'Enfants à la maison (6-12 ans)' },
  { id: 'teenagers', label: 'Adolescents à la maison (+12 ans)' }
];

const WALKING_AREAS = [
  { id: 'off-leash', label: 'Zone sans laisse à proximité' },
  { id: 'park', label: 'Parc' },
  { id: 'beach', label: 'Plage' },
  { id: 'forest', label: 'Forêt' },
  { id: 'countryside', label: 'Campagne' }
];

const EXPERIENCE_OPTIONS = [
  { id: 'volunteer', label: 'Expérience en tant que bénévole dans le domaine du bien-être animal' },
  { id: 'behavior', label: 'Expérience avec les problèmes de comportement' },
  { id: 'rescue', label: 'Expérience avec des animaux de sauvetage' },
  { id: 'training', label: 'Expérience avec les techniques de dressage de chien' }
];

const YEARS_OF_EXPERIENCE = [
  { id: 'less-1', label: "Moins de 1 an d'expérience" },
  { id: 'less-5', label: "Moins de 5 ans d'expérience" },
  { id: 'more-5', label: "Plus de 5 ans d'expérience" },
  { id: 'more-10', label: "Plus de 10 ans d'expérience" }
];

interface SpecificitiesContentProps {
  formData: any;
  updateFormData: (step: string, data: any) => void;
}

export default function SpecificitiesContent({ formData, updateFormData }: SpecificitiesContentProps) {
  return (
    <div className="space-y-8">
      <div>
        <h3 className="font-medium mb-4">Type du lieu de l'hébergement</h3>
        <ServiceOptionsSelector
          title="Sélectionnez le type de votre habitation"
          options={HOUSING_OPTIONS}
          selectedOptions={[formData.specificities?.housingType || '']}
          onChange={([value]) => updateFormData('specificities', { housingType: value })}
          multiple={false}
        />
      </div>

      <div>
        <h3 className="font-medium mb-4">Enfants</h3>
        <ServiceOptionsSelector
          title="Présence d'enfants sur le lieu de garde"
          options={CHILDREN_OPTIONS}
          selectedOptions={[formData.specificities?.children || '']}
          onChange={([value]) => updateFormData('specificities', { children: value })}
          multiple={false}
        />
      </div>

      <div>
        <h3 className="font-medium mb-4">Lieux de promenade</h3>
        <ServiceOptionsSelector
          title="Type d'aires de promenade près du lieu de garde"
          options={WALKING_AREAS}
          selectedOptions={formData.specificities?.walkingAreas || []}
          onChange={(selected) => updateFormData('specificities', { walkingAreas: selected })}
          description="Sélectionnez les zones de promenade disponibles près de chez vous"
        />
      </div>

      <div>
        <h3 className="font-medium mb-4">Compétences supplémentaires</h3>
        <ServiceOptionsSelector
          title="Vos autres domaines de compétence"
          options={EXPERIENCE_OPTIONS}
          selectedOptions={formData.specificities?.experience || []}
          onChange={(selected) => updateFormData('specificities', { experience: selected })}
          description="Indiquez les domaines dans lesquels vous avez une expertise"
        />
      </div>

      <div>
        <h3 className="font-medium mb-4">Années d'expérience</h3>
        <ServiceOptionsSelector
          title="Votre niveau d'expérience"
          options={YEARS_OF_EXPERIENCE}
          selectedOptions={[formData.specificities?.yearsOfExperience || '']}
          onChange={([value]) => updateFormData('specificities', { yearsOfExperience: value })}
          multiple={false}
          description="Indiquez votre niveau d'expérience avec les animaux"
        />
      </div>
    </div>
  );
}