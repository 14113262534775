import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, getDoc, doc } from 'firebase/firestore';
import { db } from '../config/firebase';
import { useAuth } from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import PetSitterCard from '../components/PetSitterCard';
import type { PetSitter } from '../types';

export default function OwnerFavorites() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [favorites, setFavorites] = useState<PetSitter[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!user) {
      setLoading(false);
      return;
    }
    loadFavorites();
  }, [user]);

  const loadFavorites = async () => {
    if (!user) return;

    try {
      const favoritesRef = collection(db, 'favorites');
      const q = query(favoritesRef, where('ownerId', '==', user.uid));
      const querySnapshot = await getDocs(q);
      const sitterIds = querySnapshot.docs.map(doc => doc.data().sitterId);

      if (sitterIds.length === 0) {
        setFavorites([]);
        setLoading(false);
        return;
      }

      const sittersData: PetSitter[] = [];
      for (const sitterId of sitterIds) {
        const sitterDocRef = doc(db, 'sitterProfiles', sitterId);
        const sitterDocSnap = await getDoc(sitterDocRef);
        
        if (sitterDocSnap.exists()) {
          sittersData.push({ 
            id: sitterId, 
            ...sitterDocSnap.data() 
          } as PetSitter);
        }
      }

      setFavorites(sittersData);
    } catch (error) {
      console.error('Erreur lors du chargement des favoris:', error);
    } finally {
      setLoading(false);
    }
  };

  if (!user) {
    return (
      <div className="min-h-screen bg-gray-50 py-8">
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center py-12 bg-white rounded-lg shadow">
            <h2 className="text-xl font-semibold mb-4">Connectez-vous pour voir vos favoris</h2>
            <p className="text-gray-600 mb-6">
              Vous devez être connecté pour accéder à vos PetSitters favoris.
            </p>
            <button
              onClick={() => navigate('/auth')}
              className="bg-primary-500 text-white px-6 py-2 rounded-lg hover:bg-primary-600 transition"
            >
              Se connecter
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 py-8">
        <div className="max-w-7xl mx-auto px-4">
          <div className="animate-pulse">
            <div className="h-8 bg-gray-200 rounded w-1/4 mb-6"></div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {[1, 2, 3].map((i) => (
                <div key={i} className="h-96 bg-gray-200 rounded"></div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-7xl mx-auto px-4">
        <h1 className="text-2xl font-bold mb-8">Mes PetSitters favoris</h1>

        {favorites.length === 0 ? (
          <div className="text-center py-12 bg-white rounded-lg shadow">
            <p className="text-gray-500 mb-4">
              Vous n'avez pas encore de PetSitters favoris
            </p>
            <button
              onClick={() => navigate('/search')}
              className="text-primary-500 hover:text-primary-600 font-medium"
            >
              Découvrir des PetSitters
            </button>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {favorites.map((sitter) => (
              <PetSitterCard
                key={sitter.id}
                sitter={sitter}
                onBooking={() => {}}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}