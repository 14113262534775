import React from 'react';
import { AlertCircle } from 'lucide-react';

interface PetFormProps {
  onSubmit: (petData: PetData) => void;
  availableAnimals: string[];
}

export interface PetData {
  type: string;
  name: string;
  age: string; // Changé en string pour gérer la valeur vide
  healthInfo: string;
}

const ANIMAL_LABELS: { [key: string]: string } = {
  'petit-chien': 'Petit chien (-10kg)',
  'moyen-chien': 'Moyen chien (10-20kg)',
  'grand-chien': 'Grand chien (+20kg)',
  'chien-attaque': 'Chien d\'attaque (Cat. 1)',
  'chien-garde': 'Chien de garde (Cat. 2)',
  'chat': 'Chat',
  'lapin': 'Lapin',
  'rongeur': 'Petit rongeur',
  'oiseau': 'Oiseau',
  'volaille': 'Volaille'
};

export default function PetForm({ onSubmit, availableAnimals }: PetFormProps) {
  const [petData, setPetData] = React.useState<PetData>({
    type: '',
    name: '',
    age: '', // Initialisé comme chaîne vide
    healthInfo: ''
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit({
      ...petData,
      age: parseInt(petData.age) || 0 // Conversion en nombre lors de la soumission
    });
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Type d'animal
        </label>
        <select
          value={petData.type}
          onChange={(e) => setPetData({ ...petData, type: e.target.value })}
          className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-primary-200 focus:border-primary-500"
          required
        >
          <option value="">Sélectionnez un type d'animal</option>
          {availableAnimals.map((type) => (
            <option key={type} value={type}>
              {ANIMAL_LABELS[type] || type}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Nom de l'animal
        </label>
        <input
          type="text"
          value={petData.name}
          onChange={(e) => setPetData({ ...petData, name: e.target.value })}
          className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-primary-200 focus:border-primary-500"
          placeholder="Ex: Max"
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Âge (en années)
        </label>
        <input
          type="number"
          min="0"
          max="30"
          value={petData.age}
          onChange={(e) => setPetData({ ...petData, age: e.target.value })}
          className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-primary-200 focus:border-primary-500"
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Informations de santé
        </label>
        <div className="relative">
          <textarea
            value={petData.healthInfo}
            onChange={(e) => setPetData({ ...petData, healthInfo: e.target.value })}
            className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-primary-200 focus:border-primary-500"
            rows={4}
            placeholder="Traitements en cours, allergies, régime alimentaire spécial..."
          />
          <div className="absolute top-0 right-0 p-2">
            <AlertCircle className="w-5 h-5 text-gray-400" />
          </div>
        </div>
        <p className="mt-1 text-sm text-gray-500">
          Ces informations sont importantes pour assurer le bien-être de votre animal
        </p>
      </div>

      <button
        type="submit"
        className="w-full bg-primary-500 text-white px-6 py-2 rounded-lg hover:bg-primary-600 transition"
      >
        Continuer
      </button>
    </form>
  );
}