import React from 'react';
import { Check } from 'lucide-react';
import { motion } from 'framer-motion';

interface Option {
  id: string;
  label: string;
}

interface ServiceOptionsSelectorProps {
  title: string;
  options: Option[];
  selectedOptions: string[];
  onChange: (selected: string[]) => void;
  disabled?: boolean;
  description?: string;
  multiple?: boolean;
}

export default function ServiceOptionsSelector({
  title,
  options,
  selectedOptions = [],
  onChange,
  disabled = false,
  description,
  multiple = true
}: ServiceOptionsSelectorProps) {
  const toggleOption = (id: string) => {
    if (disabled) return;
    
    if (multiple) {
      const newSelected = selectedOptions.includes(id)
        ? selectedOptions.filter(optionId => optionId !== id)
        : [...selectedOptions, id];
      onChange(newSelected);
    } else {
      onChange([id]);
    }
  };

  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className={`space-y-3 ${disabled ? 'opacity-50' : ''}`}
    >
      <h4 className="font-medium text-gray-700">{title}</h4>
      {description && (
        <p className="text-sm text-gray-500 mb-4">{description}</p>
      )}
      <div className="space-y-2">
        {options.map((option) => (
          <motion.button
            key={option.id}
            type="button"
            onClick={() => toggleOption(option.id)}
            disabled={disabled}
            whileHover={!disabled ? { scale: 1.01 } : {}}
            whileTap={!disabled ? { scale: 0.99 } : {}}
            className={`w-full flex items-start p-3 border rounded-lg cursor-pointer transition-colors ${
              disabled ? 'cursor-not-allowed' :
              selectedOptions.includes(option.id)
                ? 'bg-primary-50 border-primary-500'
                : 'hover:bg-gray-50 border-gray-200'
            }`}
          >
            <div className="flex-shrink-0 mt-0.5">
              <div className={`w-5 h-5 border rounded mr-3 flex items-center justify-center ${
                selectedOptions.includes(option.id)
                  ? 'border-primary-500 bg-primary-500'
                  : 'border-gray-300'
              }`}>
                {selectedOptions.includes(option.id) && (
                  <Check className="w-3 h-3 text-white" />
                )}
              </div>
            </div>
            <span className="text-sm text-left">{option.label}</span>
          </motion.button>
        ))}
      </div>
    </motion.div>
  );
}