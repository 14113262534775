import React from 'react';
import { X, Calendar, Clock, Phone, Mail, PawPrint, Info } from 'lucide-react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

interface ReservationDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  reservation: any;
}

export default function ReservationDetailsModal({
  isOpen,
  onClose,
  reservation
}: ReservationDetailsModalProps) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl max-w-lg w-full mx-4 max-h-[80vh] overflow-y-auto">
        <div className="p-4 border-b border-gray-200 flex justify-between items-center">
          <h2 className="text-xl font-semibold">Détails de la réservation</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="p-4 space-y-4">
          {/* Informations du propriétaire */}
          <div className="space-y-4">
            <h3 className="text-lg font-medium">Coordonnées du détenteur</h3>
            <div className="bg-gray-50 rounded-lg p-4 space-y-2">
              <p className="flex items-center">
                <span className="font-medium mr-2">Nom :</span>
                {reservation.ownerName}
              </p>
              <p className="flex items-center">
                <Phone className="w-4 h-4 mr-2 text-gray-500" />
                {reservation.ownerPhone}
              </p>
              <p className="flex items-center">
                <Mail className="w-4 h-4 mr-2 text-gray-500" />
                {reservation.ownerEmail}
              </p>
            </div>
          </div>

          {/* Informations de l'animal */}
          <div className="space-y-4">
            <h3 className="text-lg font-medium">Informations sur l'animal</h3>
            <div className="bg-gray-50 rounded-lg p-4 space-y-2">
              <p className="flex items-center">
                <PawPrint className="w-4 h-4 mr-2 text-gray-500" />
                <span className="font-medium mr-2">Nom :</span>
                {reservation.petInfo.name}
              </p>
              <p className="flex items-center">
                <span className="font-medium mr-2">Type :</span>
                {reservation.petInfo.type}
              </p>
              <p className="flex items-center">
                <span className="font-medium mr-2">Âge :</span>
                {reservation.petInfo.age} ans
              </p>
              {reservation.petInfo.healthInfo && (
                <div>
                  <p className="flex items-center mb-1">
                    <Info className="w-4 h-4 mr-2 text-gray-500" />
                    <span className="font-medium">Informations de santé :</span>
                  </p>
                  <p className="ml-6 text-gray-600">{reservation.petInfo.healthInfo}</p>
                </div>
              )}
            </div>
          </div>

          {/* Détails de la réservation */}
          <div className="space-y-4">
            <h3 className="text-lg font-medium">Détails de la réservation</h3>
            <div className="bg-gray-50 rounded-lg p-4 space-y-2">
              <p className="flex items-center">
                <Calendar className="w-4 h-4 mr-2 text-gray-500" />
                <span className="font-medium mr-2">Période :</span>
                Du {format(new Date(reservation.startDate), 'dd MMMM yyyy', { locale: fr })} au{' '}
                {format(new Date(reservation.endDate), 'dd MMMM yyyy', { locale: fr })}
              </p>
              <p className="flex items-center">
                <Clock className="w-4 h-4 mr-2 text-gray-500" />
                <span className="font-medium mr-2">Service :</span>
                {reservation.serviceType}
              </p>
              <p className="flex items-center">
                <span className="font-medium mr-2">Durée :</span>
                {reservation.duration} jour{reservation.duration > 1 ? 's' : ''}
              </p>
              <p className="flex items-center text-lg font-semibold text-primary-500 mt-4">
                Total : {reservation.totalPrice}€
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}