import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Calendar, X } from 'lucide-react';
import { format } from 'date-fns';

interface WelcomeModalProps {
  isOpen: boolean;
  onClose: () => void;
  trialEndDate: string;
}

export default function WelcomeModal({ isOpen, onClose, trialEndDate }: WelcomeModalProps) {
  if (!isOpen) return null;

  return (
    <AnimatePresence>
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.95 }}
          className="bg-white rounded-xl max-w-md w-full mx-4 overflow-hidden"
        >
          <div className="p-6 bg-primary-500 text-white relative">
            <button
              onClick={onClose}
              className="absolute top-4 right-4 text-white hover:text-gray-200"
            >
              <X className="w-6 h-6" />
            </button>
            <h2 className="text-2xl font-bold mb-2">Bienvenue sur Europetsitter.fr !</h2>
            <p className="opacity-90">Votre aventure commence maintenant</p>
          </div>

          <div className="p-6">
            <div className="mb-6">
              <div className="flex items-start space-x-4">
                <div className="bg-primary-100 p-2 rounded-lg">
                  <Calendar className="w-6 h-6 text-primary-500" />
                </div>
                <div>
                  <h3 className="font-medium mb-1">Période d'essai gratuite</h3>
                  <p className="text-gray-600 text-sm">
                    Votre période d'essai de 3 mois se terminera le {format(new Date(trialEndDate), 'dd/MM/yyyy')}
                  </p>
                </div>
              </div>
            </div>

            <div className="space-y-4">
              <h4 className="font-medium">Prochaines étapes :</h4>
              <ul className="space-y-2 text-sm text-gray-600">
                <li className="flex items-center">
                  <span className="w-5 h-5 rounded-full bg-primary-100 text-primary-500 flex items-center justify-center mr-2 text-xs">1</span>
                  Complétez votre profil avec vos informations
                </li>
                <li className="flex items-center">
                  <span className="w-5 h-5 rounded-full bg-primary-100 text-primary-500 flex items-center justify-center mr-2 text-xs">2</span>
                  Ajoutez vos services et tarifs
                </li>
                <li className="flex items-center">
                  <span className="w-5 h-5 rounded-full bg-primary-100 text-primary-500 flex items-center justify-center mr-2 text-xs">3</span>
                  Définissez vos disponibilités
                </li>
              </ul>
            </div>

            <button
              onClick={onClose}
              className="w-full mt-6 bg-primary-500 text-white px-6 py-2 rounded-lg hover:bg-primary-600 transition"
            >
              Commencer
            </button>
          </div>
        </motion.div>
      </div>
    </AnimatePresence>
  );
}