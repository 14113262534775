import React from 'react';
import { motion } from 'framer-motion';
import { MapPin, Phone, Building } from 'lucide-react';

export default function CoordinatesPage() {
  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="bg-white rounded-xl shadow-lg overflow-hidden"
        >
          <div className="p-8">
            <h1 className="text-3xl font-bold text-gray-900 mb-8">Coordonnées</h1>

            <div className="space-y-6">
              <div className="flex items-start space-x-4">
                <Building className="w-6 h-6 text-primary-500 mt-1 flex-shrink-0" />
                <div>
                  <h2 className="font-semibold text-xl mb-2">EuroPetSitter</h2>
                  <p className="text-gray-600">B.C.E. n°0602 875 091</p>
                </div>
              </div>

              <div className="flex items-start space-x-4">
                <MapPin className="w-6 h-6 text-primary-500 mt-1 flex-shrink-0" />
                <div>
                  <h2 className="font-semibold text-xl mb-2">Adresse</h2>
                  <p className="text-gray-600">Avenue Edmond Leburton 100/9</p>
                  <p className="text-gray-600">B-4300 Waremme</p>
                </div>
              </div>

              <div className="flex items-start space-x-4">
                <Phone className="w-6 h-6 text-primary-500 mt-1 flex-shrink-0" />
                <div>
                  <h2 className="font-semibold text-xl mb-2">Contact</h2>
                  <a 
                    href="tel:+32486333451" 
                    className="text-gray-600 hover:text-primary-500 transition-colors"
                  >
                    +32 486 33 34 51
                  </a>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
}