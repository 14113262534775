import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { User, Monitor, PawPrint, ChevronDown, ChevronUp } from 'lucide-react';

const INFO_TABS = [
  {
    id: 'detenteurs',
    label: 'Info Détenteurs',
    icon: <User className="w-5 h-5" />,
    content: {
      title: "À l'attention des détenteurs d'animaux de compagnie",
      shortText: "Il est vivement recommandé de rencontrer le PetSitter choisi bien avant le début de la prestation prévue, accompagné de votre chien ou chat notamment.",
      fullText: "Il est vivement recommandé de rencontrer le PetSitter choisi bien avant le début de la prestation prévue, accompagné de votre chien ou chat notamment. Une brève conversation de quelques minutes avec le PetSitter et une visite préalable des lieux où votre animal sera gardé peuvent éviter de nombreux malentendus. Par ailleurs, assurez-vous que le PetSitter a déclaré son activité auprès de la Direction Départementale de la Protection des Populations (DDPP), nécessairement si vous envisagez de lui confier un chien de catégorie 1 ou 2. Vous cherchez à allier plaisir et revenus complémentaires sans risque financier ? Inscrivez-vous gratuitement ici comme PetSitter pour une période d'essai de 3 mois, sans engagement ultérieur aucun. Ensuite un abonnement sans renouvellement en automatique vous sera proposé à partir de 29 € TTC par trimestre !",
      image: "https://images.unsplash.com/photo-1450778869180-41d0601e046e?auto=format&fit=crop&w=800&q=80",
      cta: "Découvrir nos PetSitters",
      link: "/search"
    }
  },
  {
    id: 'application',
    label: 'Info Application',
    icon: <Monitor className="w-5 h-5" />,
    content: {
      title: "Une application simple et sécurisée",
      shortText: "EuroPetSitter.fr met en relation des propriétaires d'animaux avec des prestataires partout en France pour des services variés.",
      fullText: "EuroPetSitter.fr met en relation des propriétaires d'animaux de compagnie avec des prestataires de services animaliers assurant diverses missions partout en France : hébergement (+12h), garde (-12h), visite à domicile, promenade et excursion. Via notre application, il n'y a ni frais d'inscription ni commissions à payer par le détenteur d’animaux de compagnie. Les PetSitters fixent leurs tarifs et conservent l'intégralité de leurs gains. EuroPetSitter.fr génère ses revenus uniquement via la mise à disposition d'espaces publicitaires et fonctionne comme un annuaire numérique doté d’un moteur de recherche.",
      image: "https://www.chat-maine-coon.com/wp-content/uploads/2019/03/shutterstock_750360994-1.jpg"
    }
  },
  {
    id: 'petsitters',
    label: 'Info PetSitters',
    icon: <PawPrint className="w-5 h-5" />,
    content: {
      title: "À l'attention des prestataires de services animaliers",
      shortText: "Vous souhaitez promouvoir votre activité de services animaliers ou démarrer une nouvelle activité sans risque financier ?",
      fullText: "Vous souhaitez promouvoir votre activité de services animaliers ou démarrer une nouvelle activité sans risque financier ? Dans votre espace publicitaire, vous pourrez vous présenter à travers un texte ou des photos. Indiquez précisément les types d'animaux que vous prenez en charge, les services proposés, l'espace disponible pour les animaux, ainsi que vos tarifs journaliers et hebdomadaires. Ajoutez également des informations pertinentes comme la présence d'enfants ou non ou encore l'environnement de garde. Intéressé ? Inscrivez-vous gratuitement comme PetSitter pour une période d'essai de 3 mois, sans engagement. Satisfait ? Vous pourrez renouveler votre abonnement à votre convenance : 29 € pour 3 mois, 49 € pour 6 mois, ou 96 € pour un an, TTC, sans renouvellement automatique.",
      image: "https://images.unsplash.com/photo-1534361960057-19889db9621e?auto=format&fit=crop&w=800&q=80",
      cta: "Devenir PetSitter",
      link: "/auth"
    }
  }
];

export default function InfoTabs() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('detenteurs');
  const [showFullText, setShowFullText] = useState(false);

  const activeContent = INFO_TABS.find(tab => tab.id === activeTab)?.content;

  return (
    <section className="py-8 sm:py-16 bg-gray-50">
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-2xl sm:text-3xl font-bold text-center text-gray-900 mb-8 sm:mb-12">
          Informations pratiques
        </h2>

        <div className="flex flex-col sm:flex-row justify-center gap-4 sm:space-x-4 mb-8 sm:mb-12">
          {INFO_TABS.map((tab) => (
            <button
              key={tab.id}
              onClick={() => {
                setActiveTab(tab.id);
                setShowFullText(false);
              }}
              className={`flex items-center justify-center space-x-2 px-4 sm:px-6 py-3 rounded-full transition-colors ${
                activeTab === tab.id
                  ? 'bg-primary-500 text-white'
                  : 'bg-white text-gray-600 hover:bg-gray-100'
              }`}
            >
              {tab.icon}
              <span>{tab.label}</span>
            </button>
          ))}
        </div>

        {activeContent && (
          <div className="bg-white rounded-xl shadow-lg overflow-hidden max-w-4xl mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 sm:gap-8">
              <div className="p-6 sm:p-10">
                <h3 className="text-xl sm:text-2xl font-bold text-gray-900 mb-4 sm:mb-6">
                  {activeContent.title}
                </h3>
                <div className="space-y-4">
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className="text-sm sm:text-base text-gray-600 leading-relaxed"
                  >
                    <div className={`${showFullText ? 'text-justify' : ''}`}>
                      {showFullText ? activeContent.fullText : activeContent.shortText}
                    </div>
                  </motion.div>
                  <AnimatePresence>
                    {activeContent.fullText !== activeContent.shortText && (
                      <motion.button
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        onClick={() => setShowFullText(!showFullText)}
                        className="flex items-center text-primary-500 hover:text-primary-600 font-medium mt-2"
                      >
                        {showFullText ? (
                          <>
                            Voir moins
                            <ChevronUp className="w-4 h-4 ml-1" />
                          </>
                        ) : (
                          <>
                            Voir plus
                            <ChevronDown className="w-4 h-4 ml-1" />
                          </>
                        )}
                      </motion.button>
                    )}
                  </AnimatePresence>
                </div>
                {activeContent.cta && activeContent.link && (
                  <button 
                    onClick={() => navigate(activeContent.link)}
                    className="w-full sm:w-auto bg-primary-500 text-white px-6 py-2 rounded-full hover:bg-primary-600 transition mt-6 sm:mt-8"
                  >
                    {activeContent.cta}
                  </button>
                )}
              </div>
              <div className="relative h-64 md:h-auto order-first md:order-last">
                <img
                  src={activeContent.image}
                  alt={activeContent.title}
                  className="absolute inset-0 w-full h-full object-cover"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}