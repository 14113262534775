import { useState, useEffect, useRef } from 'react';
import { MapPin } from 'lucide-react';

interface AddressAutocompleteProps {
  value: string;
  onChange: (address: string, coordinates?: { latitude: number; longitude: number }) => void;
  className?: string;
}

export default function AddressAutocomplete({ value, onChange, className = '' }: AddressAutocompleteProps) {
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const searchAddress = async (query: string) => {
    if (!query.trim()) {
      setSuggestions([]);
      return;
    }

    setIsLoading(true);
    try {
      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(query)}.json?` + 
        `access_token=pk.eyJ1IjoiYnVkYTM2NSIsImEiOiJjbTE2anNkbnYwamxmMmpzODJwZXB3MWFiIn0.SyA_0s1DscP2AN9jH4FaaQ` +
        `&country=fr&types=place,postcode,address&language=fr`
      );
      const data = await response.json();
      setSuggestions(data.features);
      setShowSuggestions(true);
    } catch (error) {
      console.error('Erreur lors de la recherche d\'adresse:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const formatSuggestionLabel = (suggestion: any) => {
    const { place_type, text, context } = suggestion;
    
    if (place_type[0] === 'postcode') {
      // Pour les codes postaux
      const city = context?.find((c: any) => c.id.startsWith('place'))?.text;
      return `${text} ${city || ''}`;
    } else if (place_type[0] === 'place') {
      // Pour les villes
      const postcode = context?.find((c: any) => c.id.startsWith('postcode'))?.text;
      return `${text} ${postcode || ''}`;
    } else {
      // Pour les adresses complètes
      return suggestion.place_name;
    }
  };

  const handleSelect = (suggestion: any) => {
    const address = formatSuggestionLabel(suggestion);
    const [longitude, latitude] = suggestion.center;
    onChange(address, { latitude, longitude });
    setShowSuggestions(false);
  };

  return (
    <div ref={containerRef} className={`relative ${className}`}>
      <div className="relative">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <MapPin className="h-5 w-5 text-gray-400" />
        </div>
        <input
          type="text"
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
            searchAddress(e.target.value);
          }}
          onFocus={() => value && searchAddress(value)}
          className="pl-10 w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary-200 focus:border-primary-500"
          placeholder="Entrez une ville, un code postal ou une adresse"
        />
        {isLoading && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
            <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-primary-500"></div>
          </div>
        )}
      </div>

      {showSuggestions && suggestions.length > 0 && (
        <div className="absolute z-10 w-full mt-1 bg-white rounded-lg shadow-lg max-h-60 overflow-auto">
          {suggestions.map((suggestion) => (
            <button
              key={suggestion.id}
              onClick={() => handleSelect(suggestion)}
              className="w-full px-4 py-2 text-left hover:bg-gray-50 focus:outline-none focus:bg-gray-50"
            >
              <div className="font-medium">{formatSuggestionLabel(suggestion)}</div>
              <div className="text-xs text-gray-500">
                {suggestion.place_type[0] === 'address' ? 'Adresse' : 
                 suggestion.place_type[0] === 'postcode' ? 'Code postal' : 'Ville'}
              </div>
            </button>
          ))}
        </div>
      )}
    </div>
  );
}