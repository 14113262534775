import { useState, useEffect } from 'react';
import { collection, query, where, getDocs, doc, onSnapshot } from 'firebase/firestore';
import { db } from '../config/firebase';
import { format, addHours } from 'date-fns';

interface Availability {
  id: string;
  date: string;
  sitterId: string;
  status: 'available' | 'booked' | 'blocked';
}

export function useCalendar(sitterId: string) {
  const [availabilities, setAvailabilities] = useState<Availability[]>([]);
  const [blockedDates, setBlockedDates] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // Charger les dates bloquées depuis le profil du pet sitter
  useEffect(() => {
    const loadBlockedDates = async () => {
      try {
        const docRef = doc(db, 'sitterProfiles', sitterId);
        const unsubscribe = onSnapshot(docRef, (doc) => {
          if (doc.exists()) {
            const data = doc.data();
            setBlockedDates(data.blockedDates || []);
          }
        });

        return unsubscribe;
      } catch (err) {
        console.error('Erreur lors du chargement des dates bloquées:', err);
        setError('Erreur lors du chargement des dates bloquées');
      }
    };

    loadBlockedDates();
  }, [sitterId]);

  // Charger les réservations
  useEffect(() => {
    const q = query(
      collection(db, 'reservations'),
      where('sitterId', '==', sitterId),
      where('status', 'in', ['confirmed', 'pending'])
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const bookedDates: string[] = [];
      snapshot.forEach((doc) => {
        const reservation = doc.data();
        const start = new Date(reservation.startDate);
        const end = new Date(reservation.endDate);
        
        // Ajouter toutes les dates entre start et end
        let current = new Date(start);
        while (current <= end) {
          bookedDates.push(format(addHours(current, 2), 'yyyy-MM-dd'));
          current.setDate(current.getDate() + 1);
        }
      });

      setAvailabilities(
        bookedDates.map(date => ({
          id: `${date}-${sitterId}`,
          date,
          sitterId,
          status: 'booked'
        }))
      );
      setLoading(false);
    }, (err) => {
      setError('Erreur lors du chargement des disponibilités');
      setLoading(false);
    });

    return unsubscribe;
  }, [sitterId]);

  const isDateBlocked = (date: string): boolean => {
    return blockedDates.includes(date) || availabilities.some(a => 
      a.date === date && (a.status === 'blocked' || a.status === 'booked')
    );
  };

  const isDateBooked = (date: string): boolean => {
    return availabilities.some(a => a.date === date && a.status === 'booked');
  };

  return {
    availabilities,
    blockedDates,
    loading,
    error,
    isDateBlocked,
    isDateBooked
  };
}