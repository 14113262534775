import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { 
  Search, 
  Calendar, 
  MessageCircle, 
  Star, 
  Heart, 
  PawPrint
} from 'lucide-react';

const STEPS = [
  {
    icon: Search,
    title: "1. Recherchez",
    description: "Trouvez le PetSitter idéal en fonction de vos besoins, de votre localisation et du type de service souhaité"
  },
  {
    icon: Calendar,
    title: "2. Réservez",
    description: "Sélectionnez vos dates et soumettez votre demande directement en ligne."
  },
  {
    icon: MessageCircle,
    title: "3. Rencontrez",
    description: "Échangez avec votre PetSitter et organisez une première rencontre pour établir une relation de confiance"
  }
];

const FEATURES = [
  {
    icon: Star,
    title: "Communauté bienveillante",
    description: "Rejoignez une communauté passionnée par le bien-être animal et partagez vos expériences"
  },
  {
    icon: Heart,
    title: "Service personnalisé",
    description: "Des prestations adaptées aux besoins spécifiques de votre animal"
  },
  {
    icon: Star,
    title: "Avis vérifiés",
    description: "Consultez les avis des autres propriétaires pour faire le meilleur choix"
  },
  {
    icon: PawPrint,
    title: "Plateforme sécurisée",
    description: "Toutes vos données et réservations sont sécurisées au sein de nos serveurs"
  }
];

export default function HowItWorksPage() {
  const navigate = useNavigate();
  
  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="bg-gradient-to-br from-primary-50 to-primary-100 py-20"
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <motion.h1 
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2 }}
            className="text-4xl md:text-5xl font-bold text-gray-900 mb-6"
          >
            Comment fonctionne Europetsitter.fr ?
          </motion.h1>
          <motion.p 
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.3 }}
            className="text-xl text-gray-600 max-w-2xl mx-auto"
          >
            Découvrez comment notre plateforme connecte les détenteurs d'animaux de compagnie avec des PetSitters impliqués dans leurs missions
          </motion.p>
        </div>
      </motion.div>

      {/* Steps Section */}
      <div className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            {STEPS.map((step, index) => (
              <motion.div
                key={step.title}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="text-center"
              >
                <div className="w-16 h-16 bg-primary-500 rounded-full flex items-center justify-center mx-auto mb-6 shadow-lg">
                  <step.icon className="w-8 h-8 text-white" />
                </div>
                <h3 className="text-xl font-semibold mb-4">{step.title}</h3>
                <p className="text-gray-600">
                  {step.description}
                </p>
              </motion.div>
            ))}
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-3xl font-bold mb-4">EuroPetSitter.fr c'est aussi</h2>
          </motion.div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {FEATURES.map((feature, index) => (
              <motion.div
                key={feature.title}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow"
              >
                <feature.icon className="w-10 h-10 text-primary-500 mb-4" />
                <h3 className="font-semibold mb-2">{feature.title}</h3>
                <p className="text-gray-600 text-sm">
                  {feature.description}
                </p>
              </motion.div>
            ))}
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        className="bg-primary-500 text-white py-20"
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl font-bold mb-6">
            Prêt à trouver le PetSitter idéal ?
          </h2>
          <p className="text-xl mb-8 opacity-90">
            Rejoignez notre communauté de détenteurs d'animaux de compagnie satisfaits
          </p>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => navigate('/search')}
            className="bg-white text-primary-500 px-8 py-3 rounded-full font-semibold hover:bg-gray-100 transition shadow-lg"
          >
            Commencer maintenant
          </motion.button>
        </div>
      </motion.div>
    </div>
  );
}