import React from 'react';
import { Star, Heart, MapPin, Home, Users, TreePine } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { useFavorites } from '../hooks/useFavorites';
import { motion } from 'framer-motion';
import type { PetSitter } from '../types';

interface PetSitterCardProps {
  sitter: PetSitter;
  onBooking: (sitter: PetSitter) => void;
  requireAuth?: boolean;
}

const HOUSING_LABELS: { [key: string]: string } = {
  'maison': 'Maison',
  'appartement': 'Appartement',
  'propriete': 'Propriété à la campagne'
};

const CHILDREN_LABELS: { [key: string]: string } = {
  'no-answer': 'Non spécifié',
  'no-children': "Pas d'enfants",
  'toddlers': 'Bambins (-6 ans)',
  'children': 'Enfants (6-12 ans)',
  'teenagers': 'Adolescents (+12 ans)'
};

export default function PetSitterCard({ sitter, onBooking, requireAuth = false }: PetSitterCardProps) {
  const navigate = useNavigate();
  const { user, userData } = useAuth();
  const { toggleFavorite, isFavorite, loading: favoritesLoading } = useFavorites(user?.uid);
  const [isLoading, setIsLoading] = React.useState(false);

  const displayName = sitter.businessName || sitter.name;

  const handleFavoriteClick = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    
    if (!user) {
      navigate('/auth');
      return;
    }

    if (userData?.role === 'sitter') {
      alert("En tant que pet sitter, vous ne pouvez pas ajouter de favoris.");
      return;
    }

    if (favoritesLoading || isLoading) return;

    try {
      setIsLoading(true);
      await toggleFavorite(sitter.id);
    } catch (error) {
      console.error('Erreur lors de la gestion des favoris:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCardClick = () => {
    navigate(`/petsitter/${sitter.id}`);
  };

  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      whileHover={{ y: -4, transition: { duration: 0.2 } }}
      onClick={handleCardClick}
      className="bg-white rounded-xl shadow-md overflow-hidden transition-shadow hover:shadow-xl w-full max-w-sm mx-auto h-[540px] flex flex-col cursor-pointer"
    >
      <div className="relative h-48">
        {sitter.photos && sitter.photos.length > 0 ? (
          <img
            src={sitter.photos[0]}
            alt="Photo de couverture"
            className="w-full h-full object-cover"
          />
        ) : (
          <div className="w-full h-full bg-gradient-to-br from-primary-400 to-primary-600" />
        )}
        
        {/* Profile picture */}
        <div className="absolute -bottom-6 left-4 w-20 h-20 rounded-full border-4 border-white shadow-lg overflow-hidden z-20 bg-white">
          <img
            src={sitter.avatar || `https://source.unsplash.com/100x100/?portrait&random=${sitter.id}`}
            alt={displayName}
            className="w-full h-full object-cover"
          />
        </div>

        {user && (
          <motion.button 
            onClick={handleFavoriteClick}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            disabled={favoritesLoading || isLoading}
            className={`absolute top-2 right-2 p-2 bg-white rounded-full shadow-md transition-colors z-20 ${
              isFavorite(sitter.id) 
                ? 'text-red-500 hover:text-red-600' 
                : 'text-gray-600 hover:text-gray-700'
            } ${(favoritesLoading || isLoading) ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            <Heart 
              className={`w-5 h-5 ${isFavorite(sitter.id) ? 'fill-current' : ''}`} 
            />
          </motion.button>
        )}
      </div>
      
      <div className="p-4 pt-8 flex-1 flex flex-col">
        <div className="flex justify-between items-start mb-3">
          <div>
            <h3 className="text-lg font-semibold text-gray-900">{displayName}</h3>
            <div className="flex items-center text-sm text-gray-600 mt-1">
              <MapPin className="w-4 h-4 mr-1" />
              <span>{sitter.location}</span>
            </div>
          </div>
          <div className="flex items-center">
            <Star className="w-5 h-5 text-yellow-400 fill-current" />
            <span className="ml-1 font-medium">{sitter.rating.toFixed(1)}</span>
          </div>
        </div>

        <div className="flex flex-wrap gap-2 mb-3">
          {sitter.animals.map((animal) => (
            <span
              key={animal}
              className="px-2 py-0.5 bg-primary-100 text-primary-700 rounded-full text-xs"
            >
              {animal}
            </span>
          ))}
        </div>

        <div className="space-y-2 mb-3">
          {sitter.services.map((service) => (
            <div 
              key={service.type}
              className="flex justify-between items-center"
            >
              <span className="text-gray-600 text-sm truncate max-w-[70%]">{service.description}</span>
              <span className="font-medium text-sm">{service.price}€/jour</span>
            </div>
          ))}
        </div>

        {sitter.specificities && (
          <div className="space-y-2 mb-3">
            {sitter.specificities.housingType && (
              <div className="flex items-center text-sm text-gray-600">
                <Home className="w-4 h-4 mr-2 text-primary-500" />
                <span>{HOUSING_LABELS[sitter.specificities.housingType]}</span>
              </div>
            )}
            {sitter.specificities.children && (
              <div className="flex items-center text-sm text-gray-600">
                <Users className="w-4 h-4 mr-2 text-primary-500" />
                <span>{CHILDREN_LABELS[sitter.specificities.children]}</span>
              </div>
            )}
          </div>
        )}

        <div className="mt-auto pt-3">
          <button
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/petsitter/${sitter.id}`);
            }}
            className="w-full bg-primary-500 text-white px-4 py-2 rounded-lg hover:bg-primary-600 transition text-sm font-medium"
          >
            Voir le profil
          </button>
        </div>
      </div>
    </motion.div>
  );
}