import { useState, useEffect } from 'react';
import { collection, query, where, getDocs, addDoc, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../config/firebase';

export function useFavorites(userId?: string) {
  const [favorites, setFavorites] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (userId) {
      loadFavorites();
    } else {
      setFavorites([]);
      setLoading(false);
    }
  }, [userId]);

  const loadFavorites = async () => {
    if (!userId) {
      setFavorites([]);
      setLoading(false);
      return;
    }

    try {
      const q = query(
        collection(db, 'favorites'),
        where('ownerId', '==', userId)
      );
      const querySnapshot = await getDocs(q);
      const favoriteIds = querySnapshot.docs.map(doc => doc.data().sitterId);
      setFavorites(favoriteIds);
    } catch (error) {
      console.error('Erreur lors du chargement des favoris:', error);
      setFavorites([]);
    } finally {
      setLoading(false);
    }
  };

  const addToFavorites = async (sitterId: string) => {
    if (!userId) return;
    
    try {
      const docRef = await addDoc(collection(db, 'favorites'), {
        ownerId: userId,
        sitterId,
        createdAt: new Date().toISOString()
      });
      
      if (docRef.id) {
        setFavorites(prev => [...prev, sitterId]);
      }
    } catch (error) {
      console.error('Erreur lors de l\'ajout aux favoris:', error);
      throw error;
    }
  };

  const removeFromFavorites = async (sitterId: string) => {
    if (!userId) return;

    try {
      const q = query(
        collection(db, 'favorites'),
        where('ownerId', '==', userId),
        where('sitterId', '==', sitterId)
      );
      const querySnapshot = await getDocs(q);
      
      const deletePromises = querySnapshot.docs.map(doc => deleteDoc(doc.ref));
      await Promise.all(deletePromises);

      setFavorites(prev => prev.filter(id => id !== sitterId));
    } catch (error) {
      console.error('Erreur lors de la suppression des favoris:', error);
      throw error;
    }
  };

  const toggleFavorite = async (sitterId: string) => {
    if (!userId) return;

    try {
      if (favorites.includes(sitterId)) {
        await removeFromFavorites(sitterId);
      } else {
        await addToFavorites(sitterId);
      }
    } catch (error) {
      console.error('Erreur lors de la gestion des favoris:', error);
      throw error;
    }
  };

  return {
    favorites,
    loading,
    toggleFavorite,
    isFavorite: (sitterId: string) => favorites.includes(sitterId)
  };
}