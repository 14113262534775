import { useState, useEffect } from 'react';
import { useAuth } from './useAuth';

export type ProfileStep = 'profile' | 'services' | 'specificities' | 'availability';

interface ProfileFormData {
  profile: {
    name: string;
    phone: string;
    businessType: 'individual' | 'independent' | 'company';
    businessName: string;
    address: string;
    coordinates: { latitude: number; longitude: number } | null;
    description: string;
    avatar: string;
    photos: string[];
  };
  services: {
    animals: string[];
    services: Array<{
      type: string;
      price: number;
      priceWeek?: number;
      description: string;
    }>;
    serviceOptions: {
      hebergementChien: string[];
      visiteChien: string[];
      promenadeChien: string[];
      hebergementChat: string[];
      visiteChat: string[];
      gardeChien: string[];
      gardeChat: string[];
    };
  };
  specificities: {
    housingType: string;
    children: string;
    walkingAreas: string[];
    experience: string[];
    yearsOfExperience: string;
  };
  availability: {
    general: string[];
    blockedDates: string[];
  };
}

const initialFormData: ProfileFormData = {
  profile: {
    name: '',
    phone: '',
    businessType: 'individual',
    businessName: '',
    address: '',
    coordinates: null,
    description: '',
    avatar: '',
    photos: []
  },
  services: {
    animals: [],
    services: [],
    serviceOptions: {
      hebergementChien: [],
      visiteChien: [],
      promenadeChien: [],
      hebergementChat: [],
      visiteChat: [],
      gardeChien: [],
      gardeChat: []
    }
  },
  specificities: {
    housingType: '',
    children: '',
    walkingAreas: [],
    experience: [],
    yearsOfExperience: ''
  },
  availability: {
    general: [],
    blockedDates: []
  }
};

export function useProfileSteps() {
  const { user } = useAuth();
  const [currentStep, setCurrentStep] = useState<ProfileStep>('profile');
  const [formData, setFormData] = useState<ProfileFormData>(initialFormData);

  useEffect(() => {
    if (user) {
      const savedData = localStorage.getItem(`profile-data-${user.uid}`);
      if (savedData) {
        try {
          const parsedData = JSON.parse(savedData);
          // Ensure all required objects exist with default values
          setFormData({
            ...initialFormData,
            ...parsedData,
            specificities: {
              ...initialFormData.specificities,
              ...(parsedData.specificities || {})
            }
          });
        } catch (error) {
          console.error('Erreur lors du chargement des données du profil:', error);
          setFormData(initialFormData);
        }
      }

      const savedStep = localStorage.getItem(`profile-step-${user.uid}`);
      if (savedStep && ['profile', 'services', 'specificities', 'availability'].includes(savedStep)) {
        setCurrentStep(savedStep as ProfileStep);
      }
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      localStorage.setItem(`profile-data-${user.uid}`, JSON.stringify(formData));
      localStorage.setItem(`profile-step-${user.uid}`, currentStep);
    }
  }, [formData, currentStep, user]);

  const updateFormData = (
    step: ProfileStep,
    data: Partial<typeof formData[typeof step]>
  ) => {
    setFormData(prev => ({
      ...prev,
      [step]: { ...prev[step], ...data }
    }));
  };

  const nextStep = () => {
    if (currentStep === 'profile') setCurrentStep('services');
    else if (currentStep === 'services') setCurrentStep('specificities');
    else if (currentStep === 'specificities') setCurrentStep('availability');
  };

  const previousStep = () => {
    if (currentStep === 'services') setCurrentStep('profile');
    else if (currentStep === 'specificities') setCurrentStep('services');
    else if (currentStep === 'availability') setCurrentStep('specificities');
  };

  const resetForm = () => {
    if (user) {
      localStorage.removeItem(`profile-data-${user.uid}`);
      localStorage.removeItem(`profile-step-${user.uid}`);
    }
    setFormData(initialFormData);
    setCurrentStep('profile');
  };

  const isLastStep = currentStep === 'availability';
  const isFirstStep = currentStep === 'profile';

  return {
    currentStep,
    setCurrentStep,
    formData,
    updateFormData,
    nextStep,
    previousStep,
    isLastStep,
    isFirstStep,
    resetForm
  };
}