import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { useReservations } from '../hooks/useReservations';
import { Calendar, MapPin, Clock, Star, MessageCircle, ExternalLink } from 'lucide-react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import ReviewModal from '../components/ReviewModal';

interface ReservationCardProps {
  reservation: any;
  onReviewClick?: () => void;
  showReviewButton?: boolean;
}

function ReservationCard({ reservation, onReviewClick, showReviewButton }: ReservationCardProps) {
  const navigate = useNavigate();
  const startDate = format(new Date(reservation.startDate), 'dd MMMM yyyy', { locale: fr });
  const endDate = format(new Date(reservation.endDate), 'dd MMMM yyyy', { locale: fr });

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'confirmed':
        return 'text-green-500';
      case 'pending':
        return 'text-orange-500';
      case 'rejected':
        return 'text-red-500';
      default:
        return 'text-gray-500';
    }
  };

  const getStatusText = (status: string) => {
    switch (status) {
      case 'confirmed':
        return 'Confirmée';
      case 'pending':
        return 'En attente';
      case 'rejected':
        return 'Refusée';
      default:
        return status;
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow p-6">
      <div className="flex justify-between items-start mb-4">
        <div>
          <h3 className="font-semibold text-lg mb-2">{reservation.sitterName}</h3>
          <div className="space-y-2 text-sm text-gray-600">
            <div className="flex items-center">
              <Calendar className="w-4 h-4 mr-2" />
              <span>Du {startDate} au {endDate}</span>
            </div>
            <div className="flex items-center">
              <Clock className="w-4 h-4 mr-2" />
              <span>Service : {reservation.serviceType}</span>
            </div>
            {reservation.petInfo && (
              <div className="flex items-center">
                <Star className="w-4 h-4 mr-2" />
                <span>Animal : {reservation.petInfo.name} ({reservation.petInfo.type})</span>
              </div>
            )}
          </div>
        </div>

        <div className="text-right">
          <div className="font-semibold text-lg text-primary-500">
            {reservation.totalPrice}€
          </div>
          <div className="text-sm text-gray-500">
            {reservation.duration} jour{reservation.duration > 1 ? 's' : ''}
          </div>
          <div className={`mt-2 text-sm ${getStatusColor(reservation.status)}`}>
            {getStatusText(reservation.status)}
          </div>
        </div>
      </div>

      <div className="border-t pt-4 mt-4 flex justify-between items-center">
        <button
          onClick={() => navigate(`/petsitter/${reservation.sitterId}`)}
          className="text-primary-500 hover:text-primary-600 text-sm flex items-center"
        >
          <ExternalLink className="w-4 h-4 mr-1" />
          Voir le profil
        </button>

        {showReviewButton && onReviewClick && !reservation.hasReview && (
          <button
            onClick={onReviewClick}
            className="bg-primary-500 text-white px-4 py-2 rounded-lg hover:bg-primary-600 transition flex items-center text-sm"
          >
            <MessageCircle className="w-4 h-4 mr-2" />
            Laisser un avis
          </button>
        )}
      </div>
    </div>
  );
}

export default function OwnerDashboard() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { reservations, loading } = useReservations(undefined, user?.uid);
  const [activeTab, setActiveTab] = useState<'upcoming' | 'past'>('upcoming');
  const [selectedReservation, setSelectedReservation] = useState<any>(null);
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);

  const filterReservations = () => {
    const now = new Date();
    return reservations.filter(reservation => {
      const endDate = new Date(reservation.endDate);
      return activeTab === 'upcoming' ? endDate >= now : endDate < now;
    });
  };

  const handleReviewClick = (reservation: any) => {
    setSelectedReservation(reservation);
    setIsReviewModalOpen(true);
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 py-8">
        <div className="max-w-4xl mx-auto px-4">
          <div className="animate-pulse">
            <div className="h-8 bg-gray-200 rounded w-1/4 mb-6"></div>
            <div className="space-y-4">
              {[1, 2, 3].map((i) => (
                <div key={i} className="h-32 bg-gray-200 rounded"></div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-4xl mx-auto px-4">
        <h1 className="text-2xl font-bold mb-8">Mes réservations</h1>

        <div className="flex space-x-4 mb-6">
          <button
            onClick={() => setActiveTab('upcoming')}
            className={`px-4 py-2 rounded-lg transition-colors ${
              activeTab === 'upcoming'
                ? 'bg-primary-500 text-white'
                : 'bg-white text-gray-600 hover:bg-gray-50'
            }`}
          >
            À venir
          </button>
          <button
            onClick={() => setActiveTab('past')}
            className={`px-4 py-2 rounded-lg transition-colors ${
              activeTab === 'past'
                ? 'bg-primary-500 text-white'
                : 'bg-white text-gray-600 hover:bg-gray-50'
            }`}
          >
            Passées
          </button>
        </div>

        <div className="space-y-4">
          {filterReservations().map((reservation) => (
            <ReservationCard
              key={reservation.id}
              reservation={reservation}
              showReviewButton={activeTab === 'past' && reservation.status === 'confirmed'}
              onReviewClick={() => handleReviewClick(reservation)}
            />
          ))}

          {filterReservations().length === 0 && (
            <div className="text-center py-12 bg-white rounded-lg shadow">
              <p className="text-gray-500 mb-4">
                Aucune réservation {activeTab === 'upcoming' ? 'à venir' : 'passée'}
              </p>
              <button
                onClick={() => navigate('/search')}
                className="text-primary-500 hover:text-primary-600 font-medium"
              >
                Rechercher un PetSitter
              </button>
            </div>
          )}
        </div>

        {selectedReservation && (
          <ReviewModal
            isOpen={isReviewModalOpen}
            onClose={() => setIsReviewModalOpen(false)}
            sitterId={selectedReservation.sitterId}
            sitterName={selectedReservation.sitterName}
            reservationId={selectedReservation.id}
          />
        )}
      </div>
    </div>
  );
}