import React from 'react';
import { motion } from 'framer-motion';

export default function CookiesPage() {
  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="bg-white rounded-xl shadow-lg overflow-hidden"
        >
          <div className="p-6 sm:p-8">
            <h1 className="text-3xl font-bold text-gray-900 mb-8">Politique de confidentialité</h1>

            <div className="prose prose-lg max-w-none">
              <h2 className="text-2xl font-semibold mb-4">SOMMAIRE</h2>
              <ol className="list-decimal pl-6 mb-8">
                <li>Données personnelles</li>
                <li>Cookies</li>
              </ol>

              <h2 className="text-2xl font-semibold mb-4">1. Données personnelles</h2>

              <h3 className="text-xl font-semibold mb-3">1.1. Les droits des personnes protégées par le RGPD</h3>
              <p className="mb-4">
                Le RGPD prévoit que les utilisateurs du site Europetsitter disposent des droits suivants : droit à l'information, droit d'accès, droit de rectification, droit à l'effacement, droit à la limitation du traitement, droit d'opposition, droit à la portabilité et droit de ne pas faire l'objet d'une décision individuelle automatisée (articles 13 à 22 du RGPD).
              </p>

              <h3 className="text-xl font-semibold mb-3">1.2. Les activités de traitement des données à caractère personnel</h3>
              <p className="mb-4">
                Dans le cadre de son activité commerciale, EuroPetSitter réalise des activités de traitement des données à caractère personnel pour lesquelles elle est responsable des données qu'elle collecte. EuroPetSitter collecte des données relatives à ses Utilisateurs. Ces données sont collectées de manière licite, loyale et transparente.
              </p>

              <h3 className="text-xl font-semibold mb-3">1.3. Transfert des données vers les pays en dehors de l'Union européenne</h3>
              <p className="mb-4">
                Dans le cadre des activités de traitement pour lesquelles EUROPETSITTER agit en qualité de responsable du traitement, cette dernière n'est pas amenée à conserver ou à transférer des données à caractère personnel en dehors de l'Union européenne.
              </p>

              <h3 className="text-xl font-semibold mb-3">1.4. Sécurisation des données</h3>
              <p className="mb-4">
                EUROPETSITTER a pris des mesures techniques et organisationnelles appropriées pour garantir la sécurisation des données à caractère personnel contre un accès ou un traitement non autorisé, leur perte ou des dégâts.
              </p>

              <h3 className="text-xl font-semibold mb-3">1.5. Délégué à la protection des données</h3>
              <p className="mb-4">
                EUROPETSITTER désigne son administrateur, Monsieur Nicolas Jammaers en tant que délégué à la protection des données (DPO).
              </p>

              <h3 className="text-xl font-semibold mb-3">1.6. Les sous-traitants</h3>
              <p className="mb-4">
                Dans le cadre des activités de traitement pour lesquelles EUROPETSITTER intervient en qualité de responsable du traitement, elle est amenée à communiquer des données à caractère personnel à certains sous-traitants afin de respecter ses obligations légales et contractuelles.
              </p>

              <h2 className="text-2xl font-semibold mb-4">2. Cookies</h2>
              <p className="mb-4">
                EuroPetSitter entend informer les visiteurs du site Internet de l'utilisation de cookies. Les cookies sont de petits fichiers texte qui sont enregistrés sur le disque dur de l'ordinateur de l'Utilisateur du Site. Ils contiennent des informations comme par exemple la préférence linguistique de l'internaute, de manière à ce qu'il ne doive pas à nouveau introduire ces informations lors d'une prochaine visite sur ce même site Internet.
              </p>
              <p className="mb-4">
                Certains cookies permettent un affichage graphique optimal, d'autres permettent qu'une application Internet fonctionne correctement. Si vous ne voulez pas qu'un site Internet installe des cookies sur votre ordinateur, vous pouvez paramétrer votre navigateur à cet effet.
              </p>
              <p>
                Lorsque vous désactivez les cookies, tenez toutefois compte du fait que certains éléments graphiques ne s'afficheront peut-être pas correctement ou que vous ne pourrez pas utiliser certaines applications.
              </p>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
}