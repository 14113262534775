import React from 'react';
import { motion } from 'framer-motion';

export default function AboutPage() {
  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h1 className="text-3xl font-bold text-primary-500 mb-2">À PROPOS DE NOUS</h1>
          <h2 className="text-3xl font-bold text-gray-900 mb-4">
            Philosophie et Ambition d'EuroPetSitter.fr
          </h2>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 mb-16">
          <motion.div 
            initial={{ opacity: 0, x: -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            className="space-y-6"
          >
            <h3 className="text-2xl font-bold text-gray-900">Notre philosophie</h3>
            <p className="text-gray-600 leading-relaxed">
              Via EuroPetSitter.fr les détenteurs d'animaux de compagnie ne paient ni frais d'inscription ni commission de 20% sur chaque prestation, contrairement à la majorité des autres applications similaires à celle-ci. Tout PetSitter affilié à EuroPetSitter.fr fixe ses tarifs à sa convenance et conserve l'intégralité des sommes perçues.
            </p>
            <p className="text-gray-600 leading-relaxed">
              Notre modèle économique repose uniquement sur la vente d'espaces publicitaires permettant aux PetSitters de se présenter, de détailler leurs services et de partager leurs coordonnées. EuroPetSitter.fr est un répertoire doté d'un moteur de recherche, facilitant la mise en relation entre propriétaires d'animaux de compagnie et PetSitters.
            </p>
          </motion.div>
          
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            whileInView={{ opacity: 1, scale: 1 }}
            viewport={{ once: true }}
            className="relative h-[400px] rounded-xl overflow-hidden shadow-xl"
          >
            <img
              src="https://images.unsplash.com/photo-1450778869180-41d0601e046e?auto=format&fit=crop&w=800&q=80"
              alt="Dog looking away"
              className="absolute inset-0 w-full h-full object-cover"
            />
          </motion.div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            whileInView={{ opacity: 1, scale: 1 }}
            viewport={{ once: true }}
            className="relative h-[400px] rounded-xl overflow-hidden shadow-xl lg:order-1 order-2"
          >
            <img
              src="https://images.unsplash.com/photo-1534361960057-19889db9621e?auto=format&fit=crop&w=800&q=80"
              alt="Golden Retriever"
              className="absolute inset-0 w-full h-full object-cover"
            />
          </motion.div>

          <motion.div 
            initial={{ opacity: 0, x: 20 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            className="space-y-6 lg:order-2 order-1"
          >
            <h3 className="text-2xl font-bold text-gray-900">Notre ambition</h3>
            <p className="text-gray-600 leading-relaxed">
              EuroPetSitter.fr vise à faciliter en toute confiance la mise en relation, partout en France, entre les propriétaires d'animaux de compagnie et des prestataires de services animaliers. Nos PetSitters affiliés offrent divers services tels que :
            </p>
            <ul className="list-disc list-inside space-y-2 text-gray-600 pl-4">
              <li>Hébergement (+ de 12h de garde de jour ou garde de nuit)</li>
              <li>Garde (- de 12h de garde de jour)</li>
              <li>Visite à domicile pour nourrissage, administration de médicaments</li>
              <li>Promenade de chiens</li>
              <li>Excursion à la campagne, à la plage ou en forêt</li>
            </ul>
          </motion.div>
        </div>
      </div>
    </div>
  );
}