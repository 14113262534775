import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ArrowLeft, User, Calendar, Clock, Share2 } from 'lucide-react';
import { BLOG_POSTS } from '../data/blogPosts';

interface BlogArticleProps {
  slug: string;
}

interface BlogContent {
  type: 'paragraph' | 'subtitle' | 'list';
  content?: string;
  items?: string[];
}

export default function BlogArticle({ slug }: BlogArticleProps) {
  const navigate = useNavigate();
  const article = BLOG_POSTS.find(post => post.slug === slug);

  if (!article) {
    return (
      <div className="text-center py-12">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">
          Article non trouvé
        </h2>
        <button
          onClick={() => navigate('/blog')}
          className="text-primary-500 hover:text-primary-600"
        >
          Retour aux articles
        </button>
      </div>
    );
  }

  return (
    <motion.article
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="max-w-4xl mx-auto"
    >
      <button
        onClick={() => navigate('/blog')}
        className="flex items-center text-gray-600 hover:text-gray-900 mb-8"
      >
        <ArrowLeft className="w-5 h-5 mr-2" />
        Retour aux articles
      </button>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
      >
        <div className="relative h-96 rounded-xl overflow-hidden mb-8">
          <img
            src={article.image}
            alt={article.title}
            className="absolute inset-0 w-full h-full object-cover"
          />
        </div>

        <div className="bg-white rounded-xl shadow-lg p-8">
          <h1 className="text-4xl font-bold text-gray-900 mb-6">
            {article.title}
          </h1>

          <div className="flex flex-wrap items-center gap-6 text-gray-600 mb-8">
            <div className="flex items-center">
              <User className="w-5 h-5 mr-2" />
              {article.author}
            </div>
            <div className="flex items-center">
              <Calendar className="w-5 h-5 mr-2" />
              {article.date}
            </div>
            <div className="flex items-center">
              <Clock className="w-5 h-5 mr-2" />
              {article.readTime} min de lecture
            </div>
          </div>

          <div className="prose prose-lg max-w-none">
            {article.content.map((section: BlogContent, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                {section.type === 'paragraph' && section.content && (
                  <p>{section.content}</p>
                )}
                {section.type === 'subtitle' && section.content && (
                  <h2 className="text-2xl font-bold mt-8 mb-4">{section.content}</h2>
                )}
                {section.type === 'list' && section.items && (
                  <ul className="list-disc pl-6 space-y-2">
                    {section.items.map((item, i) => (
                      <li key={i}>{item}</li>
                    ))}
                  </ul>
                )}
              </motion.div>
            ))}
          </div>

          <div className="border-t mt-12 pt-8">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-4">
                <img
                  src={`https://source.unsplash.com/100x100/?portrait&random=${article.author}`}
                  alt={article.author}
                  className="w-12 h-12 rounded-full"
                />
                <div>
                  <div className="font-medium">{article.author}</div>
                  <div className="text-sm text-gray-500">Expert PetSitter</div>
                </div>
              </div>
              <button className="flex items-center text-gray-600 hover:text-gray-900">
                <Share2 className="w-5 h-5 mr-2" />
                Partager
              </button>
            </div>
          </div>
        </div>
      </motion.div>
    </motion.article>
  );
}