import React, { useState, useEffect } from 'react';
import { format, startOfMonth, endOfMonth, eachDayOfInterval, isSameMonth, isToday, isBefore, startOfDay, addMonths, parse } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Calendar as CalendarIcon, X } from 'lucide-react';

interface BlockedDatesCalendarProps {
  blockedDates: string[];
  onDateSelect: (date: string) => void;
  onDateRemove: (date: string) => void;
}

export default function BlockedDatesCalendar({ 
  blockedDates,
  onDateSelect,
  onDateRemove
}: BlockedDatesCalendarProps) {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [localBlockedDates, setLocalBlockedDates] = useState<string[]>(blockedDates);

  // Sync local state with props
  useEffect(() => {
    setLocalBlockedDates(blockedDates);
  }, [blockedDates]);

  const isDateBlocked = (date: Date) => {
    const formattedDate = format(date, 'yyyy-MM-dd');
    return localBlockedDates.includes(formattedDate);
  };

  const handleDateClick = (date: Date) => {
    const today = startOfDay(new Date());
    if (isBefore(date, today) && !isToday(date)) return;

    const formattedDate = format(date, 'yyyy-MM-dd');
    if (isDateBlocked(date)) {
      onDateRemove(formattedDate);
      setLocalBlockedDates(prev => prev.filter(d => d !== formattedDate));
    } else {
      onDateSelect(formattedDate);
      setLocalBlockedDates(prev => [...prev, formattedDate]);
    }
  };

  const handleRemoveDate = (date: string) => {
    onDateRemove(date);
    setLocalBlockedDates(prev => prev.filter(d => d !== date));
  };

  const monthStart = startOfMonth(currentMonth);
  const monthEnd = endOfMonth(currentMonth);
  const daysInMonth = eachDayOfInterval({ start: monthStart, end: monthEnd });
  const startingDayIndex = monthStart.getDay() - 1;
  const emptyDays = startingDayIndex >= 0 ? startingDayIndex : 6;

  const WEEKDAYS = ['L', 'Ma', 'Me', 'J', 'V', 'S', 'D'];

  return (
    <div className="bg-white rounded-lg p-4">
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center">
          <CalendarIcon className="w-5 h-5 text-primary-500 mr-2" />
          <span className="font-medium">Dates bloquées</span>
        </div>
        <div className="flex items-center space-x-4">
          <span className="text-sm text-gray-600">
            {format(currentMonth, 'MMMM yyyy', { locale: fr })}
          </span>
          <div className="flex space-x-2">
            <button
              onClick={() => setCurrentMonth(prev => addMonths(prev, -1))}
              className="p-2 hover:bg-gray-100 rounded-lg"
            >
              ←
            </button>
            <button
              onClick={() => setCurrentMonth(prev => addMonths(prev, 1))}
              className="p-2 hover:bg-gray-100 rounded-lg"
            >
              →
            </button>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-7 gap-1">
        {WEEKDAYS.map((day, index) => (
          <div key={`weekday-${index}`} className="text-center text-sm font-medium text-gray-500 py-2">
            {day}
          </div>
        ))}

        {Array.from({ length: emptyDays }).map((_, i) => (
          <div key={`empty-start-${i}`} className="h-10" />
        ))}

        {daysInMonth.map((date) => {
          const isBlocked = isDateBlocked(date);
          const isPast = isBefore(date, startOfDay(new Date())) && !isToday(date);

          return (
            <button
              key={date.toISOString()}
              onClick={() => handleDateClick(date)}
              disabled={isPast}
              className={`
                h-10 rounded-lg flex items-center justify-center text-sm transition-colors relative
                ${isBlocked ? 'bg-red-500 text-white hover:bg-red-600' : ''}
                ${!isBlocked && !isPast ? 'hover:bg-gray-100' : ''}
                ${isPast ? 'text-gray-300 cursor-not-allowed' : ''}
              `}
            >
              {format(date, 'd')}
            </button>
          );
        })}
      </div>

      <div className="mt-6 pt-6 border-t">
        <h4 className="font-medium text-sm mb-3">Dates bloquées sélectionnées :</h4>
        <div className="flex flex-wrap gap-2">
          {localBlockedDates
            .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
            .map((date) => (
              <div
                key={date}
                className="flex items-center bg-red-100 text-red-700 px-3 py-1 rounded-full text-sm"
              >
                {format(parse(date, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy', { locale: fr })}
                <button
                  onClick={() => handleRemoveDate(date)}
                  className="ml-2 hover:text-red-800"
                >
                  <X className="w-4 h-4" />
                </button>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}