import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AlertTriangle } from 'lucide-react';
import { useAuth } from '../hooks/useAuth';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

export default function SubscriptionAlert() {
  const navigate = useNavigate();
  const { userData } = useAuth();

  if (!userData || userData.role !== 'sitter') return null;

  const isExpired = userData.subscriptionStatus === 'expired';
  const isTrial = userData.subscriptionStatus === 'trial';
  const trialEndDate = userData.trialEndDate ? new Date(userData.trialEndDate) : null;

  if (!isExpired && !isTrial) return null;

  return (
    <div className={`p-4 ${isExpired ? 'bg-red-50' : 'bg-yellow-50'} rounded-lg mb-6`}>
      <div className="flex">
        <AlertTriangle className={`h-5 w-5 ${isExpired ? 'text-red-400' : 'text-yellow-400'}`} />
        <div className="ml-3">
          <h3 className={`text-sm font-medium ${isExpired ? 'text-red-800' : 'text-yellow-800'}`}>
            {isExpired ? 'Compte suspendu' : 'Période d\'essai en cours'}
          </h3>
          <div className={`mt-2 text-sm ${isExpired ? 'text-red-700' : 'text-yellow-700'}`}>
            {isExpired ? (
              <p>
                Votre période d'essai est terminée. Pour continuer à utiliser nos services,
                veuillez souscrire à un abonnement.
              </p>
            ) : (
              <p>
                Votre période d'essai se termine le{' '}
                {trialEndDate && format(trialEndDate, 'dd MMMM yyyy', { locale: fr })}.
              </p>
            )}
          </div>
          <div className="mt-4">
            <button
              onClick={() => navigate('/subscription')}
              className={`inline-flex items-center px-4 py-2 rounded-md text-sm font-medium ${
                isExpired
                  ? 'bg-red-500 text-white hover:bg-red-600'
                  : 'bg-yellow-500 text-white hover:bg-yellow-600'
              }`}
            >
              {isExpired ? 'Souscrire maintenant' : 'Voir les abonnements'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}