import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { AppLayout } from './layouts/AppLayout';
import { AppRoutes } from './routes/AppRoutes';
import PromotionalBanner from './components/PromotionalBanner';

const App = () => {
  return (
    <Router>
      <AnimatePresence mode="wait">
        <div className="min-h-screen bg-gray-50 flex flex-col">
          <PromotionalBanner />
          <AppLayout>
            <AppRoutes />
          </AppLayout>
        </div>
      </AnimatePresence>
    </Router>
  );
};

export default App;