export const SERVICE_OPTIONS = {
  hebergementChien: [
    { id: 'ext-espace-privatif', label: 'En extérieur dans un espace privatif clos (chenil)' },
    { id: 'partage-sans-acces', label: 'Partage l\'espace de vie du petsitter sans accès à un espace extérieur clos' },
    { id: 'partage-avec-acces', label: 'Partage l\'espace de vie du petsitter avec accès à un espace clos extérieur (cour ou jardin)' },
    { id: 'int-sans-acces', label: 'En intérieur dans une pièce dédiée sans accès à un espace extérieur clos' },
    { id: 'int-avec-acces', label: 'En intérieur dans une pièce dédiée avec accès à un espace extérieur clos (cour ou jardin)' }
  ],
  gardeChien: [
    { id: 'ext-espace-privatif', label: 'En extérieur dans un espace privatif clos (chenil)' },
    { id: 'partage-sans-acces', label: 'Partage l\'espace de vie du petsitter sans accès à un espace extérieur clos' },
    { id: 'partage-avec-acces', label: 'Partage l\'espace de vie du petsitter avec accès à un espace clos extérieur (cour ou jardin)' },
    { id: 'int-sans-acces', label: 'En intérieur dans une pièce dédiée sans accès à un espace extérieur clos' },
    { id: 'int-avec-acces', label: 'En intérieur dans une pièce dédiée avec accès à un espace extérieur clos (cour ou jardin)' }
  ],
  visiteChien: [
    { id: '1-visite', label: '1x visite à domicile par jour + nourrissage' },
    { id: '2-visites', label: '2x visite à domicile par jour + nourrissage' },
    { id: '1-visite-1-promenade', label: '1x visite à domicile par jour + 1x promenade du chien + nourrissage' },
    { id: '2-visites-2-promenades', label: '2x visites à domicile par jour + 2x promenade du chien + nourrissage' }
  ],
  promenadeChien: [
    { id: 'promenade-1x', label: 'Promenade du chien 1x par jour' },
    { id: 'promenade-2x', label: 'Promenade du chien 2x par jour' }
  ],
  hebergementChat: [
    { id: 'chat-partage-sans-acces', label: 'Partage l\'espace de vie du petsitter sans accès à un espace extérieur clos' },
    { id: 'chat-partage-avec-acces', label: 'Partage l\'espace de vie du petsitter avec accès à un espace clos extérieur (cour ou jardin)' },
    { id: 'chat-int-sans-acces', label: 'En intérieur dans une pièce dédiée sans accès à un espace extérieur clos' },
    { id: 'chat-int-avec-acces', label: 'En intérieur dans une pièce dédiée avec accès à un espace extérieur clos (cour ou jardin)' }
  ],
  gardeChat: [
    { id: 'chat-partage-sans-acces', label: 'Partage l\'espace de vie du petsitter sans accès à un espace extérieur clos' },
    { id: 'chat-partage-avec-acces', label: 'Partage l\'espace de vie du petsitter avec accès à un espace clos extérieur (cour ou jardin)' },
    { id: 'chat-int-sans-acces', label: 'En intérieur dans une pièce dédiée sans accès à un espace extérieur clos' },
    { id: 'chat-int-avec-acces', label: 'En intérieur dans une pièce dédiée avec accès à un espace extérieur clos (cour ou jardin)' }
  ],
  visiteChat: [
    { id: 'chat-1-visite', label: '1x visite à domicile par jour + nourrissage' },
    { id: 'chat-2-visites', label: '2x visite à domicile par jour + nourrissage' }
  ]
};