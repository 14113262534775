import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { useReservations } from '../hooks/useReservations';
import { Check, X, Calendar, Clock, MapPin, Eye } from 'lucide-react';
import ReservationDetailsModal from '../components/ReservationDetailsModal';
import SubscriptionAlert from '../components/SubscriptionAlert';

interface ReservationCardProps {
  reservation: any;
  onAccept: (id: string) => void;
  onReject: (id: string) => void;
  onViewDetails: (reservation: any) => void;
}

function ReservationCard({ reservation, onAccept, onReject, onViewDetails }: ReservationCardProps) {
  const startDate = new Date(reservation.startDate).toLocaleDateString();
  const endDate = new Date(reservation.endDate).toLocaleDateString();

  return (
    <div className="bg-white rounded-lg shadow-md p-6 mb-4">
      <div className="flex justify-between items-start mb-4">
        <div>
          <h3 className="font-semibold text-lg">{reservation.ownerName}</h3>
          <div className="flex items-center text-gray-600 mt-1">
            <Calendar className="w-4 h-4 mr-1" />
            <span>Du {startDate} au {endDate}</span>
          </div>
          <div className="flex items-center text-gray-600 mt-1">
            <Clock className="w-4 h-4 mr-1" />
            <span>Service : {reservation.serviceType}</span>
          </div>
        </div>
        <div className="text-right">
          <div className="font-semibold text-lg text-primary-500">
            {reservation.totalPrice}€
          </div>
          <div className="text-sm text-gray-500">
            {reservation.duration} jours
          </div>
        </div>
      </div>

      <div className="border-t pt-4 flex justify-end space-x-4">
        <button
          onClick={() => onViewDetails(reservation)}
          className="px-4 py-2 text-gray-600 hover:text-gray-800 flex items-center"
        >
          <Eye className="w-4 h-4 mr-2" />
          Voir détails
        </button>
        <button
          onClick={() => onReject(reservation.id)}
          className="px-4 py-2 border border-red-500 text-red-500 rounded-lg hover:bg-red-50 flex items-center"
        >
          <X className="w-4 h-4 mr-2" />
          Refuser
        </button>
        <button
          onClick={() => onAccept(reservation.id)}
          className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 flex items-center"
        >
          <Check className="w-4 h-4 mr-2" />
          Accepter
        </button>
      </div>
    </div>
  );
}

export default function PetSitterDashboard() {
  const navigate = useNavigate();
  const { user, userData, loading, initialized, checkSubscriptionStatus } = useAuth();
  const { reservations, loading: reservationsLoading, updateReservationStatus } = useReservations(user?.uid);
  const [pendingReservations, setPendingReservations] = useState([]);
  const [activeReservations, setActiveReservations] = useState([]);
  const [selectedReservation, setSelectedReservation] = useState(null);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);

  useEffect(() => {
    if (!loading && initialized) {
      if (!user) {
        navigate('/auth');
        return;
      }

      if (userData?.role !== 'sitter') {
        navigate('/');
        return;
      }

      if (!checkSubscriptionStatus()) {
        navigate('/subscription');
        return;
      }
    }
  }, [user, userData, loading, initialized, navigate, checkSubscriptionStatus]);

  useEffect(() => {
    if (reservations) {
      setPendingReservations(reservations.filter(r => r.status === 'pending'));
      setActiveReservations(reservations.filter(r => r.status === 'confirmed'));
    }
  }, [reservations]);

  const handleAccept = async (reservationId: string) => {
    try {
      await updateReservationStatus(reservationId, 'confirmed');
    } catch (error) {
      console.error('Erreur lors de l\'acceptation de la réservation:', error);
    }
  };

  const handleReject = async (reservationId: string) => {
    try {
      await updateReservationStatus(reservationId, 'rejected');
    } catch (error) {
      console.error('Erreur lors du refus de la réservation:', error);
    }
  };

  const handleViewDetails = (reservation: any) => {
    setSelectedReservation(reservation);
    setIsDetailsModalOpen(true);
  };

  if (loading || !initialized) {
    return (
      <div className="min-h-screen bg-gray-50 py-8">
        <div className="max-w-4xl mx-auto px-4">
          <div className="animate-pulse">
            <div className="h-8 bg-gray-200 rounded w-1/4 mb-6"></div>
            <div className="space-y-4">
              {[1, 2, 3].map((i) => (
                <div key={i} className="h-32 bg-gray-200 rounded"></div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-4xl mx-auto px-4">
        <SubscriptionAlert />
        
        <h1 className="text-2xl font-bold mb-8">Tableau des réservations</h1>

        {/* Réservations en attente */}
        <section className="mb-12">
          <h2 className="text-xl font-semibold mb-4">
            Demandes de réservation en attente ({pendingReservations.length})
          </h2>
          {pendingReservations.length === 0 ? (
            <div className="bg-white rounded-lg shadow-md p-6 text-center text-gray-500">
              Aucune demande de réservation en attente
            </div>
          ) : (
            <div className="space-y-4">
              {pendingReservations.map((reservation) => (
                <ReservationCard
                  key={reservation.id}
                  reservation={reservation}
                  onAccept={handleAccept}
                  onReject={handleReject}
                  onViewDetails={handleViewDetails}
                />
              ))}
            </div>
          )}
        </section>

        {/* Réservations actives */}
        <section>
          <h2 className="text-xl font-semibold mb-4">
            Réservations confirmées ({activeReservations.length})
          </h2>
          {activeReservations.length === 0 ? (
            <div className="bg-white rounded-lg shadow-md p-6 text-center text-gray-500">
              Aucune réservation confirmée
            </div>
          ) : (
            <div className="space-y-4">
              {activeReservations.map((reservation) => (
                <div key={reservation.id} className="bg-white rounded-lg shadow-md p-6">
                  <div className="flex justify-between items-start">
                    <div>
                      <h3 className="font-semibold text-lg">{reservation.ownerName}</h3>
                      <div className="flex items-center text-gray-600 mt-1">
                        <Calendar className="w-4 h-4 mr-1" />
                        <span>
                          Du {new Date(reservation.startDate).toLocaleDateString()} 
                          au {new Date(reservation.endDate).toLocaleDateString()}
                        </span>
                      </div>
                      <div className="flex items-center text-gray-600 mt-1">
                        <Clock className="w-4 h-4 mr-1" />
                        <span>Service : {reservation.serviceType}</span>
                      </div>
                    </div>
                    <div className="text-right">
                      <div className="font-semibold text-lg text-primary-500">
                        {reservation.totalPrice}€
                      </div>
                      <div className="text-sm text-gray-500">
                        {reservation.duration} jours
                      </div>
                      <button
                        onClick={() => handleViewDetails(reservation)}
                        className="mt-2 text-primary-500 hover:text-primary-600 flex items-center justify-end"
                      >
                        <Eye className="w-4 h-4 mr-1" />
                        Voir détails
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </section>

        {/* Modal de détails */}
        {selectedReservation && (
          <ReservationDetailsModal
            isOpen={isDetailsModalOpen}
            onClose={() => setIsDetailsModalOpen(false)}
            reservation={selectedReservation}
          />
        )}
      </div>
    </div>
  );
}