import { useState, useEffect } from 'react';
import { collection, query, where, orderBy, onSnapshot, updateDoc, doc, Timestamp } from 'firebase/firestore';
import { db } from '../config/firebase';
import { useAuth } from './useAuth';

export interface Notification {
  id: string;
  userId: string;
  type: 'reservation_request' | 'reservation_accepted' | 'reservation_rejected';
  title: string;
  message: string;
  read: boolean;
  createdAt: Timestamp;
  data?: {
    reservationId?: string;
    sitterId?: string;
    ownerId?: string;
  };
}

export function useNotifications() {
  const { user } = useAuth();
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [loading, setLoading] = useState(true);
  const [unreadCount, setUnreadCount] = useState(0);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!user) {
      setNotifications([]);
      setUnreadCount(0);
      setLoading(false);
      return;
    }

    try {
      // Utilisation d'une requête plus simple pour éviter les problèmes d'index
      const q = query(
        collection(db, 'notifications'),
        where('userId', '==', user.uid)
      );

      const unsubscribe = onSnapshot(
        q,
        (snapshot) => {
          const notifs: Notification[] = [];
          let unread = 0;

          snapshot.forEach((doc) => {
            const notification = {
              id: doc.id,
              ...doc.data(),
              createdAt: doc.data().createdAt
            } as Notification;
            notifs.push(notification);
            if (!notification.read) unread++;
          });

          // Trier les notifications côté client
          notifs.sort((a, b) => b.createdAt.toMillis() - a.createdAt.toMillis());

          setNotifications(notifs);
          setUnreadCount(unread);
          setLoading(false);
          setError(null);
        },
        (err) => {
          console.error('Erreur de chargement des notifications:', err);
          setError('Erreur lors du chargement des notifications');
          setLoading(false);
        }
      );

      return () => unsubscribe();
    } catch (err) {
      console.error('Erreur lors de la configuration des notifications:', err);
      setError('Erreur lors de la configuration des notifications');
      setLoading(false);
    }
  }, [user]);

  const markAsRead = async (notificationId: string) => {
    if (!user) return;

    try {
      const notifRef = doc(db, 'notifications', notificationId);
      await updateDoc(notifRef, {
        read: true,
        updatedAt: Timestamp.now()
      });
    } catch (error) {
      console.error('Erreur lors du marquage de la notification:', error);
      throw error;
    }
  };

  const markAllAsRead = async () => {
    if (!user) return;

    try {
      const updatePromises = notifications
        .filter(n => !n.read)
        .map(n => updateDoc(doc(db, 'notifications', n.id), {
          read: true,
          updatedAt: Timestamp.now()
        }));

      await Promise.all(updatePromises);
    } catch (error) {
      console.error('Erreur lors du marquage des notifications:', error);
      throw error;
    }
  };

  return {
    notifications,
    unreadCount,
    loading,
    error,
    markAsRead,
    markAllAsRead
  };
}