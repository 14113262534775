import React from 'react';
import ImageUpload from '../ImageUpload';
import PhotoGallery from '../PhotoGallery';
import AddressAutocomplete from '../AddressAutocomplete';

interface ProfileContentProps {
  formData: any;
  updateFormData: (step: string, data: any) => void;
}

export default function ProfileContent({ formData, updateFormData }: ProfileContentProps) {
  const handleAddressChange = (address: string, coordinates?: { latitude: number; longitude: number }) => {
    updateFormData('profile', { 
      address,
      coordinates: coordinates || null
    });
  };

  const handlePhoneChange = (value: string) => {
    // Remove any non-digit characters
    const cleanedValue = value.replace(/\D/g, '');
    
    // Only update if we have 10 digits or less
    if (cleanedValue.length <= 10) {
      updateFormData('profile', { phone: cleanedValue });
    }
  };

  // Format phone number for display (XX XX XX XX XX)
  const formatPhoneNumber = (value: string) => {
    const cleaned = value.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})$/);
    if (match) {
      return `${match[1]} ${match[2]} ${match[3]} ${match[4]} ${match[5]}`;
    }
    return cleaned;
  };

  const handleTvaChange = (value: string) => {
    // Convert to uppercase
    value = value.toUpperCase();
    
    // If empty, add FR prefix
    if (!value) {
      value = 'FR';
    }
    
    // Always ensure it starts with FR
    if (!value.startsWith('FR')) {
      value = 'FR' + value.replace(/[^0-9]/g, '');
    } else {
      // Remove any non-numeric characters after FR
      value = 'FR' + value.slice(2).replace(/[^0-9]/g, '');
    }
    
    // Limit to FR + 11 digits
    if (value.length > 13) {
      value = value.slice(0, 13);
    }
    
    updateFormData('profile', { tvaNumber: value });
  };

  const isTvaValid = (value: string) => {
    return /^FR\d{11}$/.test(value);
  };

  const showBusinessFields = formData.profile.businessType === 'independent' || formData.profile.businessType === 'company';

  return (
    <div className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Photo ou logo de votre profil
        </label>
        <ImageUpload
          value={formData.profile.avatar}
          onChange={(url) => updateFormData('profile', { avatar: url })}
          onError={(error) => alert(error)}
          round={true}
        />       
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Galerie photos
        </label>
        <PhotoGallery
          photos={formData.profile.photos || []}
          onChange={(photos) => updateFormData('profile', { photos })}
          maxPhotos={10}         
          description="Montrez le lieu de garde (attention aux systèmes de sécurité), la pièce dédiée, le jardin, les aires de promenade, par exemple."
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Dénomination commerciale ou Pseudo (public)
          </label>
          <input
            type="text"
            value={formData.profile.name}
            onChange={(e) => updateFormData('profile', { name: e.target.value })}
            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary-200 focus:border-primary-500"
            placeholder="Nom de votre entreprise / pseudo"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Prénom + Nom (non public)
          </label>
          <input
            type="text"
            value={formData.profile.businessName}
            onChange={(e) => updateFormData('profile', { businessName: e.target.value })}
            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary-200 focus:border-primary-500"
            placeholder="Prénom + nom"
          />
        </div>

        <div className="md:col-span-2">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Adresse postale (public)
          </label>
          <AddressAutocomplete
            value={formData.profile.address}
            onChange={handleAddressChange}
          />
          <p className="mt-1 text-sm text-gray-500">
            Sélectionnez une adresse dans la liste des suggestions pour permettre votre localisation sur la carte
          </p>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Numéro de téléphone (non public)
          </label>
          <div className="relative">
            <input
              type="tel"
              value={formatPhoneNumber(formData.profile.phone || '')}
              onChange={(e) => handlePhoneChange(e.target.value)}
              className={`w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary-200 focus:border-primary-500 ${
                formData.profile.phone && formData.profile.phone.length !== 10 ? 'border-red-500' : ''
              }`}
              placeholder="Ex: 06 12 34 56 78"
              maxLength={14} // Account for spaces in formatted display
            />
            {formData.profile.phone && formData.profile.phone.length !== 10 && (
              <p className="absolute text-xs text-red-500 mt-1">
                Le numéro doit contenir exactement 10 chiffres
              </p>
            )}
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Statut (public)
          </label>
          <select
            value={formData.profile.businessType}
            onChange={(e) => updateFormData('profile', { 
              businessType: e.target.value,
              // Reset business fields when switching to individual
              ...(e.target.value === 'individual' ? { tvaNumber: '', siretNumber: '' } : {})
            })}
            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary-200 focus:border-primary-500"
          >
            <option value="individual">Particulier / Privé</option>
            <option value="independent">Personne physique / Indépendant</option>
            <option value="company">Personne morale / Société</option>
          </select>
        </div>

        {showBusinessFields && (
          <>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Numéro de TVA (obligatoire)
              </label>
              <div className="relative">
                <input
                  type="text"
                  value={formData.profile.tvaNumber || 'FR'}
                  onChange={(e) => handleTvaChange(e.target.value)}
                  className={`w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary-200 focus:border-primary-500 ${
                    formData.profile.tvaNumber && !isTvaValid(formData.profile.tvaNumber) ? 'border-red-500' : ''
                  }`}
                  placeholder="FR12345678901"
                  required
                  maxLength={13}
                />
                {formData.profile.tvaNumber && !isTvaValid(formData.profile.tvaNumber) && (
                  <p className="absolute text-xs text-red-500 mt-1">
                    Le numéro de TVA doit commencer par FR suivi de 11 chiffres
                  </p>
                )}
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Numéro SIRET (obligatoire)
              </label>
              <input
                type="text"
                value={formData.profile.siretNumber || ''}
                onChange={(e) => {
                  // Only allow numbers and limit to 14 digits
                  const value = e.target.value.replace(/\D/g, '').slice(0, 14);
                  updateFormData('profile', { siretNumber: value });
                }}
                className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary-200 focus:border-primary-500"
                placeholder="12345678901234"
                maxLength={14}
                required
              />
              <p className="mt-1 text-xs text-gray-500">
                Le numéro SIRET doit contenir exactement 14 chiffres
              </p>
            </div>
          </>
        )}

        <div className="md:col-span-2">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Description (public)
          </label>
          <textarea
            value={formData.profile.description}
            onChange={(e) => updateFormData('profile', { description: e.target.value })}
            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary-200 focus:border-primary-500"
            rows={4}
            placeholder="Expliquez votre activité, décrivez votre vision du métier, votre passion pour nos amis les animaux et attirez à vous une clientèle fidèle !"
          />
        </div>
      </div>
    </div>
  );
}