import React from 'react';
import { motion } from 'framer-motion';
import { ChevronRight } from 'lucide-react';
import { TERMS_CONTENT } from '../data/termsContent';

export default function TermsPage() {
  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="bg-white rounded-xl shadow-lg overflow-hidden"
        >
          <div className="p-6 sm:p-8">
            <h1 className="text-3xl font-bold text-gray-900 mb-8">Conditions générales</h1>

            {/* Table des matières */}
            <div className="bg-gray-50 rounded-lg p-6 mb-8">
              <h2 className="text-xl font-semibold mb-4">SOMMAIRE</h2>
              <ol className="space-y-2">
                {TERMS_CONTENT.sections.map((section, index) => (
                  <li key={section.id} className="flex items-center text-gray-700 hover:text-primary-500">
                    <ChevronRight className="w-4 h-4 mr-2 text-primary-500" />
                    <a href={`#section-${section.id}`} className="hover:underline">
                      {section.id}. {section.title}
                    </a>
                  </li>
                ))}
              </ol>
            </div>

            <div className="prose prose-lg max-w-none">
              {TERMS_CONTENT.sections.map((section) => (
                <section key={section.id} id={`section-${section.id}`} className="mb-12">
                  <h2 className="text-2xl font-semibold text-gray-900 mb-4 pb-2 border-b border-gray-200">
                    {section.id}. {section.title}
                  </h2>
                  <div className="space-y-4 text-gray-700">
                    {section.content.split('\n\n').map((paragraph, idx) => (
                      <p key={idx} className="whitespace-pre-wrap">
                        {paragraph}
                      </p>
                    ))}
                  </div>
                </section>
              ))}
            </div>

            {/* Back to top button */}
            <div className="text-center mt-8">
              <a 
                href="#" 
                className="inline-flex items-center text-primary-500 hover:text-primary-600"
              >
                Retour en haut
              </a>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
}