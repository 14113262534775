import { addDoc, collection, Timestamp } from 'firebase/firestore';
import { db } from '../config/firebase';

export async function createNotification({
  userId,
  type,
  title,
  message,
  data = {}
}: {
  userId: string;
  type: 'reservation_request' | 'reservation_accepted' | 'reservation_rejected';
  title: string;
  message: string;
  data?: Record<string, any>;
}) {
  try {
    await addDoc(collection(db, 'notifications'), {
      userId,
      type,
      title,
      message,
      read: false,
      createdAt: Timestamp.now(),
      data
    });
  } catch (error) {
    console.error('Erreur lors de la création de la notification:', error);
    throw error;
  }
}