import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { LoadingSpinner } from '../components/common/LoadingSpinner';
import HomePage from '../pages/HomePage';
import SearchPage from '../pages/SearchPage';
import AboutPage from '../pages/AboutPage';
import HowItWorksPage from '../pages/HowItWorksPage';
import AuthPage from '../pages/AuthPage';
import SubscriptionPage from '../pages/SubscriptionPage';
import BlogPage from '../pages/BlogPage';
import ContactPage from '../pages/ContactPage';
import CookiesPage from '../pages/CookiesPage';
import TermsPage from '../pages/TermsPage';
import CoordinatesPage from '../pages/CoordinatesPage';
import PetSitterDetailsPage from '../pages/PetSitterDetailsPage';
import PetSitterDashboard from '../pages/PetSitterDashboard';
import PetSitterProfilePage from '../pages/PetSitterProfilePage';
import SettingsPage from '../pages/SettingsPage';
import OwnerDashboard from '../pages/OwnerDashboard';
import OwnerFavorites from '../pages/OwnerFavorites';
import { PrivateRoute } from './PrivateRoute';

export function AppRoutes() {
  const location = useLocation();

  return (
    <React.Suspense fallback={<LoadingSpinner />}>
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          {/* Public Routes */}
          <Route path="/" element={<HomePage />} />
          <Route path="/search" element={<SearchPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/how-it-works" element={<HowItWorksPage />} />
          <Route path="/auth" element={<AuthPage />} />
          <Route path="/subscription" element={<SubscriptionPage />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/blog/:slug" element={<BlogPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/cookies" element={<CookiesPage />} />
          <Route path="/terms" element={<TermsPage />} />
          <Route path="/coordinates" element={<CoordinatesPage />} />
          <Route path="/petsitter/:id" element={<PetSitterDetailsPage />} />

          {/* PetSitter Routes */}
          <Route
            path="/petsitter/dashboard"
            element={
              <PrivateRoute allowedRole="sitter">
                <PetSitterDashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/petsitter/profile"
            element={
              <PrivateRoute allowedRole="sitter">
                <PetSitterProfilePage />
              </PrivateRoute>
            }
          />
          <Route
            path="/petsitter/settings"
            element={
              <PrivateRoute allowedRole="sitter">
                <SettingsPage />
              </PrivateRoute>
            }
          />

          {/* Owner Routes */}
          <Route
            path="/owner/bookings"
            element={
              <PrivateRoute allowedRole="owner">
                <OwnerDashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/owner/favorites"
            element={
              <PrivateRoute allowedRole="owner">
                <OwnerFavorites />
              </PrivateRoute>
            }
          />
        </Routes>
      </AnimatePresence>
    </React.Suspense>
  );
}