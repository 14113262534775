import React, { useState } from 'react';
import { MapPin, PawPrint, Briefcase, Check, ChevronDown } from 'lucide-react';
import { motion } from 'framer-motion';
import type { FilterState } from '../pages/SearchPage';
import type { PetSitter } from '../types';
import SearchMap from './SearchMap';
import AddressAutocomplete from './AddressAutocomplete';

interface SearchFiltersProps {
  filters: FilterState;
  onChange: (filters: FilterState) => void;
  className?: string;
  sitters: PetSitter[];
}

interface SingleSelectProps {
  options: Array<{ id: string; label: string }>;
  value: string;
  onChange: (value: string) => void;
  icon: React.ReactNode;
  placeholder: string;
}

const ANIMAL_OPTIONS = [
  { id: 'petit-chien', label: 'Petit Chien (-10kg)' },
  { id: 'moyen-chien', label: 'Moyen chien (10-20kg)' },
  { id: 'grand-chien', label: 'Grand chien (+20kg)' },
  { id: 'chien-attaque', label: 'Chien d\'attaque (Cat. 1)' },
  { id: 'chien-garde', label: 'Chien de garde (Cat. 2)' },
  { id: 'chat', label: 'Chat' },
  { id: 'lapin', label: 'Lapin' },
  { id: 'rongeur', label: 'Petit rongeur' },
  { id: 'oiseau', label: 'Oiseau' },
  { id: 'volaille', label: 'Volaille' },
  { id: 'nac', label: 'NAC (Nouveaux Animaux de Compagnie)' }
];

function SingleSelect({ options, value, onChange, icon, placeholder }: SingleSelectProps) {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (id: string) => {
    onChange(id === value ? '' : id);
    setIsOpen(false);
  };

  const getDisplayValue = () => {
    if (!value) return placeholder;
    const option = options.find(opt => opt.id === value);
    return option ? option.label : placeholder;
  };

  return (
    <div className="relative">
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className="w-full min-h-[42px] px-4 py-2 bg-white border rounded-lg flex items-center justify-between hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-200 focus:border-primary-500"
      >
        <div className="flex items-center flex-1 min-w-0">
          <span className="text-gray-400 flex-shrink-0 mr-2">{icon}</span>
          <span className="text-gray-700 truncate">{getDisplayValue()}</span>
        </div>
        <ChevronDown className="h-5 w-5 text-gray-400 flex-shrink-0 ml-2 transition-transform" />
      </button>
      
      {isOpen && (
        <div className="absolute z-10 mt-1 w-full bg-white border rounded-lg shadow-lg">
          <div className="py-1 max-h-60 overflow-auto">
            {options.map((option) => (
              <button
                key={option.id}
                type="button"
                onClick={() => handleSelect(option.id)}
                className={`w-full px-4 py-2 text-left hover:bg-gray-50 flex items-center ${
                  value === option.id ? 'bg-primary-50 text-primary-700' : 'text-gray-700'
                }`}
              >
                <div className={`flex-shrink-0 w-4 h-4 border rounded mr-2 ${
                  value === option.id 
                    ? 'border-primary-500 bg-primary-500' 
                    : 'border-gray-300'
                } flex items-center justify-center`}>
                  {value === option.id && (
                    <Check className="w-3 h-3 text-white" />
                  )}
                </div>
                <span className="flex-1 whitespace-normal text-sm">{option.label}</span>
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default function SearchFilters({
  filters,
  onChange,
  className = '',
  sitters = []
}: SearchFiltersProps) {
  const handleLocationChange = (address: string, coordinates?: { latitude: number; longitude: number }) => {
    onChange({
      ...filters,
      location: address,
      coordinates: coordinates || null
    });
  };

  const handleRadiusChange = (value: number) => {
    onChange({ ...filters, radius: value });
  };

  const handleReset = () => {
    onChange({
      animalType: '',
      serviceType: '',
      location: '',
      coordinates: null,
      radius: 10,
      options: {
        option1: '',
        option2: '',
        option3: '',
        option4: '',
        option5: ''
      }
    });
  };

  return (
    <motion.div 
      initial={{ opacity: 0, x: -20 }}
      animate={{ opacity: 1, x: 0 }}
      className={`bg-white rounded-lg shadow-lg p-6 space-y-6 ${className}`}
    >
      <div>
        <h2 className="text-lg font-semibold mb-6">Critères de recherche</h2>

        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Localisation
            </label>
            <AddressAutocomplete
              value={filters.location}
              onChange={handleLocationChange}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Type d'animal
            </label>
            <SingleSelect
              options={ANIMAL_OPTIONS}
              value={filters.animalType}
              onChange={(value) => onChange({ ...filters, animalType: value })}
              icon={<PawPrint className="h-5 w-5" />}
              placeholder="Sélectionnez un type d'animal"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Type de service
            </label>
            <SingleSelect
              options={[
                { id: 'hebergement', label: 'Hébergement (garde de +12h)' },
                { id: 'garde', label: 'Garde (-12h)' },
                { id: 'visite', label: 'Visite à domicile' },
                { id: 'promenade', label: 'Promenade' },
                { id: 'excursion', label: 'Excursion' }
              ]}
              value={filters.serviceType}
              onChange={(value) => onChange({ ...filters, serviceType: value })}
              icon={<Briefcase className="h-5 w-5" />}
              placeholder="Sélectionnez un service"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Rayon de recherche: {filters.radius} km
            </label>
            <input
              type="range"
              min="1"
              max="50"
              value={filters.radius}
              onChange={(e) => handleRadiusChange(parseInt(e.target.value))}
              className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-primary-500"
            />
            <div className="flex justify-between text-sm text-gray-600 mt-1">
              <span>1 km</span>
              <span>50 km</span>
            </div>
          </div>

          <motion.button
            onClick={handleReset}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            className="w-full px-4 py-2 text-sm text-primary-500 hover:bg-primary-50 rounded-lg transition border border-primary-500"
          >
            Réinitialiser les filtres
          </motion.button>

          <div className="mt-6">
            <h3 className="text-sm font-medium text-gray-700 mb-2">
              PetSitters disponibles
            </h3>
            <div className="h-[600px]">
              <SearchMap sitters={sitters} userLocation={filters.coordinates} />
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}