import React from 'react';
import AnimalTypeSelector from '../AnimalTypeSelector';
import ServiceOptionsSelector from '../ServiceOptionsSelector';
import { SERVICE_OPTIONS } from '../../data/serviceOptions';

interface ServicesContentProps {
  formData: any;
  updateFormData: (step: string, data: any) => void;
}

export default function ServicesContent({ formData, updateFormData }: ServicesContentProps) {
  return (
    <div className="space-y-6">
      <div>
        <h3 className="font-medium mb-3">Types d'animaux acceptés</h3>
        <AnimalTypeSelector
          animals={[
            { id: 'petit-chien', label: 'Petit Chien (-10kg)' },
            { id: 'moyen-chien', label: 'Moyen chien (10-20kg)' },
            { id: 'grand-chien', label: 'Grand chien (+20kg)' },
            { id: 'chien-attaque', label: "Chien d'attaque (Cat. 1)" },
            { id: 'chien-garde', label: 'Chien de garde (Cat. 2)' },
            { id: 'chat', label: 'Chat' },
            { id: 'lapin', label: 'Lapin' },
            { id: 'rongeur', label: 'Petit rongeur' },
            { id: 'oiseau', label: 'Oiseau' },
            { id: 'volaille', label: 'Volaille' },
            { id: 'nac', label: 'NAC (Nouveaux Animaux de Compagnie)' }
          ]}
          selectedAnimals={formData.services.animals}
          onChange={(selected) => updateFormData('services', { animals: selected })}
        />
      </div>

      <div>
        <h3 className="font-medium mb-3">Services proposés</h3>
        {[
          { id: 'hebergement', label: 'Hébergement (garde de +12h)', showPricing: true },
          { id: 'garde', label: 'Garde (garde de -12h)', showPricing: true },
          { id: 'visite', label: 'Visite sur le lieu de vie de l\'animal', showPricing: true },
          { id: 'promenade', label: 'Chien en promenade', showPricing: true },
          { id: 'excursion', label: 'Chien en excursion', showPricing: false }
        ].map((serviceType) => {
          const service = formData.services.services.find(s => s.type === serviceType.id);
          const hasChiens = formData.services.animals.some(animal => 
            ['petit-chien', 'moyen-chien', 'grand-chien', 'chien-attaque', 'chien-garde'].includes(animal)
          );
          const hasChats = formData.services.animals.includes('chat');
          
          return (
            <div key={serviceType.id} className="mb-6">
              <div
                className={`border rounded-lg p-4 transition-colors ${
                  service ? 'border-primary-500 bg-primary-50' : ''
                }`}
              >
                <div className="flex flex-col space-y-4">
                  <div className="flex items-start">
                    <input
                      type="checkbox"
                      checked={!!service}
                      onChange={() => {
                        const newServices = service
                          ? formData.services.services.filter(s => s.type !== serviceType.id)
                          : [...formData.services.services, {
                              type: serviceType.id,
                              price: '',
                              priceWeek: '',
                              description: serviceType.label
                            }];
                        updateFormData('services', { services: newServices });
                      }}
                      className="mt-1 rounded border-gray-300 text-primary-500 focus:ring-primary-500"
                    />
                    <div className="ml-3 flex-grow">
                      <h3 className="font-medium">{serviceType.label}</h3>
                    </div>
                  </div>

                  {service && serviceType.showPricing && (
                    <div className="ml-7 space-y-3">
                      <div className="flex flex-col space-y-2">
                        <label className="text-sm text-gray-600">Prix par jour</label>
                        <div className="flex items-center">
                          <input
                            type="number"
                            min="0"
                            placeholder="Prix/jour"
                            className="w-24 px-2 py-1 border rounded"
                            value={service.price}
                            onChange={(e) => {
                              const newServices = formData.services.services.map(s =>
                                s.type === serviceType.id
                                  ? { ...s, price: e.target.value }
                                  : s
                              );
                              updateFormData('services', { services: newServices });
                            }}
                          />
                          <span className="ml-2 text-gray-500">€/jour</span>
                        </div>
                      </div>

                      <div className="flex flex-col space-y-2">
                        <label className="text-sm text-gray-600">Prix par semaine (optionnel)</label>
                        <div className="flex items-center">
                          <input
                            type="number"
                            min="0"
                            placeholder="Prix/semaine"
                            className="w-24 px-2 py-1 border rounded"
                            value={service.priceWeek}
                            onChange={(e) => {
                              const newServices = formData.services.services.map(s =>
                                s.type === serviceType.id
                                  ? { ...s, priceWeek: e.target.value }
                                  : s
                              );
                              updateFormData('services', { services: newServices });
                            }}
                          />
                          <span className="ml-2 text-gray-500">€/sem</span>
                        </div>
                      </div>
                    </div>
                  )}

                  {service && (
                    <div className="ml-7">
                      <label className="text-sm text-gray-600 block mb-1">Description du service</label>
                      <textarea
                        placeholder="Décrivez ce service en détail..."
                        className="w-full px-3 py-2 border rounded"
                        value={service.description}
                        onChange={(e) => {
                          const newServices = formData.services.services.map(s =>
                            s.type === serviceType.id
                              ? { ...s, description: e.target.value }
                              : s
                          );
                          updateFormData('services', { services: newServices });
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>

              {/* Options spécifiques au service */}
              {service && (
                <div className="mt-2 ml-8">
                  {/* Options pour les chiens */}
                  {hasChiens && (
                    <>
                      {serviceType.id === 'hebergement' && (
                        <ServiceOptionsSelector
                          title="Options d'hébergement pour chiens"
                          options={SERVICE_OPTIONS.hebergementChien}
                          selectedOptions={formData.services.serviceOptions.hebergementChien || []}
                          onChange={(selected) => {
                            updateFormData('services', {
                              serviceOptions: {
                                ...formData.services.serviceOptions,
                                hebergementChien: selected
                              }
                            });
                          }}
                        />
                      )}
                      {serviceType.id === 'garde' && (
                        <ServiceOptionsSelector
                          title="Options de garde pour chiens"
                          options={SERVICE_OPTIONS.gardeChien}
                          selectedOptions={formData.services.serviceOptions.gardeChien || []}
                          onChange={(selected) => {
                            updateFormData('services', {
                              serviceOptions: {
                                ...formData.services.serviceOptions,
                                gardeChien: selected
                              }
                            });
                          }}
                        />
                      )}
                      {serviceType.id === 'visite' && (
                        <ServiceOptionsSelector
                          title="Options de visite pour chiens"
                          options={SERVICE_OPTIONS.visiteChien}
                          selectedOptions={formData.services.serviceOptions.visiteChien || []}
                          onChange={(selected) => {
                            updateFormData('services', {
                              serviceOptions: {
                                ...formData.services.serviceOptions,
                                visiteChien: selected
                              }
                            });
                          }}
                        />
                      )}
                      {serviceType.id === 'promenade' && (
                        <ServiceOptionsSelector
                          title="Options de promenade"
                          options={SERVICE_OPTIONS.promenadeChien}
                          selectedOptions={formData.services.serviceOptions.promenadeChien || []}
                          onChange={(selected) => {
                            updateFormData('services', {
                              serviceOptions: {
                                ...formData.services.serviceOptions,
                                promenadeChien: selected
                              }
                            });
                          }}
                        />
                      )}
                    </>
                  )}

                  {/* Options pour les chats */}
                  {hasChats && (
                    <>
                      {serviceType.id === 'hebergement' && (
                        <ServiceOptionsSelector
                          title="Options d'hébergement pour chats"
                          options={SERVICE_OPTIONS.hebergementChat}
                          selectedOptions={formData.services.serviceOptions.hebergementChat || []}
                          onChange={(selected) => {
                            updateFormData('services', {
                              serviceOptions: {
                                ...formData.services.serviceOptions,
                                hebergementChat: selected
                              }
                            });
                          }}
                        />
                      )}
                      {serviceType.id === 'garde' && (
                        <ServiceOptionsSelector
                          title="Options de garde pour chats"
                          options={SERVICE_OPTIONS.gardeChat}
                          selectedOptions={formData.services.serviceOptions.gardeChat || []}
                          onChange={(selected) => {
                            updateFormData('services', {
                              serviceOptions: {
                                ...formData.services.serviceOptions,
                                gardeChat: selected
                              }
                            });
                          }}
                        />
                      )}
                      {serviceType.id === 'visite' && (
                        <ServiceOptionsSelector
                          title="Options de visite pour chats"
                          options={SERVICE_OPTIONS.visiteChat}
                          selectedOptions={formData.services.serviceOptions.visiteChat || []}
                          onChange={(selected) => {
                            updateFormData('services', {
                              serviceOptions: {
                                ...formData.services.serviceOptions,
                                visiteChat: selected
                              }
                            });
                          }}
                        />
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}