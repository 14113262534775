import { useState, useEffect } from 'react';
import { collection, query, where, getDocs, addDoc, updateDoc, doc } from 'firebase/firestore';
import { db } from '../config/firebase';
import { createNotification } from '../services/notificationService';

interface Reservation {
  id: string;
  sitterId: string;
  ownerId: string;
  startDate: string;
  endDate: string;
  serviceType: string;
  status: 'pending' | 'confirmed' | 'rejected' | 'completed';
  totalPrice: number;
  createdAt: string;
}

export function useReservations(sitterId?: string, ownerId?: string) {
  const [reservations, setReservations] = useState<Reservation[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (sitterId || ownerId) {
      loadReservations();
    }
  }, [sitterId, ownerId]);

  const loadReservations = async () => {
    try {
      let q = collection(db, 'reservations');
      
      if (sitterId) {
        q = query(q, where('sitterId', '==', sitterId));
      }
      if (ownerId) {
        q = query(q, where('ownerId', '==', ownerId));
      }

      const querySnapshot = await getDocs(q);
      const reservationsData: Reservation[] = [];
      
      querySnapshot.forEach((doc) => {
        reservationsData.push({
          id: doc.id,
          ...doc.data()
        } as Reservation);
      });

      setReservations(reservationsData);
      setLoading(false);
    } catch (err) {
      setError('Erreur lors du chargement des réservations');
      setLoading(false);
    }
  };

  const createReservation = async (reservationData: Omit<Reservation, 'id' | 'createdAt'>) => {
    try {
      const docRef = await addDoc(collection(db, 'reservations'), {
        ...reservationData,
        createdAt: new Date().toISOString(),
        status: 'pending'
      });

      // Créer une notification pour le pet sitter
      await createNotification({
        userId: reservationData.sitterId,
        type: 'reservation_request',
        title: 'Nouvelle demande de réservation',
        message: `Vous avez reçu une nouvelle demande de réservation pour la période du ${new Date(reservationData.startDate).toLocaleDateString()} au ${new Date(reservationData.endDate).toLocaleDateString()}.`,
        data: {
          reservationId: docRef.id,
          ownerId: reservationData.ownerId
        }
      });

      const newReservation = {
        id: docRef.id,
        ...reservationData,
        createdAt: new Date().toISOString(),
        status: 'pending'
      };

      setReservations([...reservations, newReservation]);
      return docRef.id;
    } catch (err) {
      setError('Erreur lors de la création de la réservation');
      throw err;
    }
  };

  const updateReservationStatus = async (
    reservationId: string,
    status: 'confirmed' | 'rejected' | 'completed'
  ) => {
    try {
      const reservation = reservations.find(r => r.id === reservationId);
      if (!reservation) throw new Error('Réservation non trouvée');

      await updateDoc(doc(db, 'reservations', reservationId), { status });
      
      // Créer une notification pour le propriétaire
      await createNotification({
        userId: reservation.ownerId,
        type: status === 'confirmed' ? 'reservation_accepted' : 'reservation_rejected',
        title: status === 'confirmed' ? 'Réservation confirmée' : 'Réservation refusée',
        message: status === 'confirmed'
          ? `Votre réservation pour la période du ${new Date(reservation.startDate).toLocaleDateString()} au ${new Date(reservation.endDate).toLocaleDateString()} a été confirmée.`
          : `Votre réservation pour la période du ${new Date(reservation.startDate).toLocaleDateString()} au ${new Date(reservation.endDate).toLocaleDateString()} a été refusée.`,
        data: {
          reservationId,
          sitterId: reservation.sitterId
        }
      });
      
      setReservations(
        reservations.map(r =>
          r.id === reservationId ? { ...r, status } : r
        )
      );
    } catch (err) {
      setError('Erreur lors de la mise à jour du statut');
      throw err;
    }
  };

  return {
    reservations,
    loading,
    error,
    createReservation,
    updateReservationStatus,
    refresh: loadReservations
  };
}