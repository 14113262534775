import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Camera, X, Loader2 } from 'lucide-react';
import { useAuth } from '../hooks/useAuth';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../config/firebase';

interface ImageUploadProps {
  value: string;
  onChange: (url: string) => void;
  onError?: (error: string) => void;
  className?: string;
  round?: boolean;
}

export default function ImageUpload({ 
  value, 
  onChange, 
  onError,
  className = '',
  round = false
}: ImageUploadProps) {
  const { user } = useAuth();
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleError = useCallback((message: string) => {
    setError(message);
    onError?.(message);
  }, [onError]);

  const clearError = useCallback(() => {
    setError(null);
  }, []);

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    if (!user) {
      handleError('Vous devez être connecté pour ajouter une image');
      return;
    }

    if (acceptedFiles.length === 0) return;

    const file = acceptedFiles[0];
    
    // Validate file size
    if (file.size > 5 * 1024 * 1024) {
      handleError('L\'image ne doit pas dépasser 5MB');
      return;
    }

    // Validate file type
    const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
    if (!validTypes.includes(file.type)) {
      handleError('Format non supporté. Utilisez JPG, PNG ou GIF');
      return;
    }

    clearError();
    setUploading(true);

    try {
      // For demo purposes, we'll use a placeholder URL
      // In production, you would upload to a storage service like Firebase Storage
      const imageUrl = URL.createObjectURL(file);

      // Update Firestore with the URL
      const sitterRef = doc(db, 'sitterProfiles', user.uid);
      await updateDoc(sitterRef, { 
        avatar: imageUrl
      });

      onChange(imageUrl);
    } catch (error) {
      handleError(error instanceof Error ? error.message : 'Une erreur est survenue');
    } finally {
      setUploading(false);
    }
  }, [user, onChange, handleError, clearError]);

  const handleRemove = async () => {
    if (!value || !user) return;

    setUploading(true);
    clearError();

    try {
      const sitterRef = doc(db, 'sitterProfiles', user.uid);
      await updateDoc(sitterRef, { avatar: '' });
      onChange('');
    } catch (error) {
      handleError('Une erreur est survenue lors de la suppression de l\'image');
    } finally {
      setUploading(false);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
      'image/gif': ['.gif']
    },
    maxFiles: 1,
    disabled: uploading,
    maxSize: 5 * 1024 * 1024
  });

  return (
    <div className={`${className}`}>
      {error && (
        <div className="bg-red-50 text-red-600 px-4 py-2 rounded-lg text-sm mb-4">
          {error}
        </div>
      )}

      <div {...getRootProps()} className="relative cursor-pointer">
        <input {...getInputProps()} />
        
        {value ? (
          <div className="relative w-32 h-32 mx-auto">
            <img
              src={value}
              alt="Photo de profil"
              className={`w-full h-full object-cover ${round ? 'rounded-full' : 'rounded-lg'} border-4 border-white shadow-lg`}
            />
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleRemove();
              }}
              disabled={uploading}
              className="absolute -top-2 -right-2 p-1.5 bg-red-500 text-white rounded-full hover:bg-red-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed shadow-lg"
            >
              <X className="w-4 h-4" />
            </button>
            {uploading && (
              <div className={`absolute inset-0 bg-black bg-opacity-50 ${round ? 'rounded-full' : 'rounded-lg'} flex items-center justify-center`}>
                <Loader2 className="w-8 h-8 text-white animate-spin" />
              </div>
            )}
          </div>
        ) : (
          <div className={`w-32 h-32 mx-auto ${round ? 'rounded-full' : 'rounded-lg'} border-4 border-dashed border-gray-300 hover:border-primary-500 transition-colors flex flex-col items-center justify-center bg-gray-50`}>
            {uploading ? (
              <Loader2 className="w-8 h-8 text-primary-500 animate-spin" />
            ) : (
              <>
                <Camera className="w-8 h-8 text-gray-400 mb-2" />
                <span className="text-xs text-gray-500 text-center">
                  Ajouter une photo
                </span>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}