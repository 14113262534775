import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { X, LogIn, Calendar, Settings, LogOut, UserCircle, Phone, Search, Info, HelpCircle, BookOpen } from 'lucide-react';
import { User } from 'firebase/auth';
import { motion, AnimatePresence } from 'framer-motion';

interface MobileMenuProps {
  isOpen: boolean;
  onClose: () => void;
  onAuthClick: () => void;
  user: User | null;
  userData: any;
  onLogout: () => void;
}

export default function MobileMenu({ 
  isOpen, 
  onClose, 
  onAuthClick, 
  user, 
  userData,
  onLogout 
}: MobileMenuProps) {
  const navigate = useNavigate();

  const handleSearchClick = () => {
    if (userData?.role === 'sitter') {
      alert("En tant que PetSitter, vous ne pouvez pas réserver de services.");
      return;
    }
    navigate('/search');
    onClose();
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          className="fixed inset-0 z-50 bg-black bg-opacity-50"
          onClick={onClose}
        >
          <motion.div
            initial={{ x: "100%" }}
            animate={{ x: 0 }}
            exit={{ x: "100%" }}
            transition={{ 
              type: "spring",
              damping: 30,
              stiffness: 300
            }}
            className="fixed inset-y-0 right-0 w-80 bg-white shadow-xl"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="p-4 flex justify-end">
              <button 
                onClick={onClose}
                className="text-gray-500 hover:text-gray-700 transition-colors"
              >
                <X className="w-6 h-6" />
              </button>
            </div>

            <nav className="px-4 py-2">
              <div className="space-y-4">
                {user && (
                  <motion.div
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    className="flex items-center space-x-3 p-4 border-b"
                  >
                    <div className="w-10 h-10 rounded-full bg-primary-100 flex items-center justify-center">
                      {user.displayName?.[0].toUpperCase() || 'U'}
                    </div>
                    <div>
                      <div className="font-medium">{user.displayName}</div>
                      <div className="text-sm text-gray-500">{user.email}</div>
                    </div>
                  </motion.div>
                )}

                {user ? (
                  userData?.role === 'sitter' ? (
                    <>
                      <motion.div
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ delay: 0.1 }}
                      >
                        <Link
                          to="/petsitter/dashboard"
                          onClick={onClose}
                          className="flex items-center space-x-2 p-4 rounded-lg hover:bg-primary-50 text-gray-700 w-full"
                        >
                          <Calendar className="w-5 h-5" />
                          <span>Réservation(s)</span>
                        </Link>
                      </motion.div>

                      <motion.div
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ delay: 0.2 }}
                      >
                        <Link
                          to="/petsitter/profile"
                          onClick={onClose}
                          className="flex items-center space-x-2 p-4 rounded-lg hover:bg-primary-50 text-gray-700 w-full"
                        >
                          <UserCircle className="w-5 h-5" />
                          <span>Configuration du profil</span>
                        </Link>
                      </motion.div>

                      <motion.div
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ delay: 0.3 }}
                      >
                        <Link
                          to="/petsitter/settings"
                          onClick={onClose}
                          className="flex items-center space-x-2 p-4 rounded-lg hover:bg-primary-50 text-gray-700 w-full"
                        >
                          <Settings className="w-5 h-5" />
                          <span>Mot de passe et Statut</span>
                        </Link>
                      </motion.div>

                      <motion.div
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ delay: 0.4 }}
                      >
                        <Link
                          to="/contact"
                          onClick={onClose}
                          className="flex items-center space-x-2 p-4 rounded-lg hover:bg-primary-50 text-gray-700 w-full"
                        >
                          <Phone className="w-5 h-5" />
                          <span>Contact</span>
                        </Link>
                      </motion.div>

                      <motion.div
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ delay: 0.5 }}
                        className="border-t pt-4"
                      >
                        <button
                          onClick={() => {
                            onLogout();
                            onClose();
                          }}
                          className="flex items-center space-x-2 p-4 rounded-lg hover:bg-red-50 text-red-600 w-full"
                        >
                          <LogOut className="w-5 h-5" />
                          <span>Déconnexion</span>
                        </button>
                      </motion.div>
                    </>
                  ) : (
                    <>
                      <motion.div
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ delay: 0.1 }}
                      >
                        <button
                          onClick={handleSearchClick}
                          className="flex items-center space-x-2 p-4 rounded-lg hover:bg-primary-50 text-gray-700 w-full"
                        >
                          <Search className="w-5 h-5" />
                          <span>Chercher un PetSitter</span>
                        </button>
                      </motion.div>

                      <motion.div
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ delay: 0.2 }}
                      >
                        <Link
                          to="/owner/bookings"
                          onClick={onClose}
                          className="flex items-center space-x-2 p-4 rounded-lg hover:bg-primary-50 text-gray-700"
                        >
                          <Calendar className="w-5 h-5" />
                          <span>Mes réservations</span>
                        </Link>
                      </motion.div>

                      <motion.div
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ delay: 0.3 }}
                        className="border-t pt-4"
                      >
                        <button
                          onClick={() => {
                            onLogout();
                            onClose();
                          }}
                          className="flex items-center space-x-2 p-4 rounded-lg hover:bg-red-50 text-red-600 w-full"
                        >
                          <LogOut className="w-5 h-5" />
                          <span>Déconnexion</span>
                        </button>
                      </motion.div>
                    </>
                  )
                ) : (
                  <>
                    <motion.div
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: 0.1 }}
                    >
                      <button
                        onClick={handleSearchClick}
                        className="flex items-center space-x-2 p-4 rounded-lg hover:bg-primary-50 text-gray-700 w-full"
                      >
                        <Search className="w-5 h-5" />
                        <span>Chercher un PetSitter</span>
                      </button>
                    </motion.div>

                    <motion.div
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: 0.2 }}
                    >
                      <Link
                        to="/about"
                        onClick={onClose}
                        className="flex items-center space-x-2 p-4 rounded-lg hover:bg-primary-50 text-gray-700 w-full"
                      >
                        <Info className="w-5 h-5" />
                        <span>À propos de nous</span>
                      </Link>
                    </motion.div>

                    <motion.div
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: 0.3 }}
                    >
                      <Link
                        to="/how-it-works"
                        onClick={onClose}
                        className="flex items-center space-x-2 p-4 rounded-lg hover:bg-primary-50 text-gray-700 w-full"
                      >
                        <HelpCircle className="w-5 h-5" />
                        <span>Comment ça marche</span>
                      </Link>
                    </motion.div>

                    <motion.div
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: 0.4 }}
                    >
                      <Link
                        to="/blog"
                        onClick={onClose}
                        className="flex items-center space-x-2 p-4 rounded-lg hover:bg-primary-50 text-gray-700 w-full"
                      >
                        <BookOpen className="w-5 h-5" />
                        <span>Notre Blog</span>
                      </Link>
                    </motion.div>

                    <motion.div
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: 0.5 }}
                    >
                      <Link
                        to="/contact"
                        onClick={onClose}
                        className="flex items-center space-x-2 p-4 rounded-lg hover:bg-primary-50 text-gray-700 w-full"
                      >
                        <Phone className="w-5 h-5" />
                        <span>Contact</span>
                      </Link>
                    </motion.div>

                    <motion.div
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: 0.6 }}
                      className="border-t pt-4"
                    >
                      <button
                        onClick={() => {
                          onAuthClick();
                          onClose();
                        }}
                        className="flex items-center space-x-2 p-4 rounded-lg hover:bg-primary-50 text-gray-700 w-full"
                      >
                        <LogIn className="w-5 h-5" />
                        <span>Se connecter</span>
                      </button>
                    </motion.div>
                  </>
                )}
              </div>
            </nav>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}