import React from 'react';
import { useParams } from 'react-router-dom';
import BlogList from '../components/BlogList';
import BlogArticle from '../components/BlogArticle';

export default function BlogPage() {
  const { slug } = useParams();

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {slug ? <BlogArticle slug={slug} /> : <BlogList />}
      </div>
    </div>
  );
}