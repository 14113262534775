import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

const FAQ_ITEMS = [
  {
    question: "Comment choisir le bon PetSitter ?",
    answer: "Pour choisir le bon PetSitter, nous vous recommandons de lire attentivement les profils et les avis, vérifier les qualifications et l'expérience, organiser une rencontre au préalable avec votre animal, discuter en détail de vos attentes et des besoins spécifiques de votre animal."
  },
  {
    question: "Quels sont les avantages d'utilisation de EuroPetSitter.fr ?",
    answer: `Gratuité : L'accès au bloc de recherche et la prise de contact direct avec le PetSitter de votre choix sont entièrement gratuits. EuroPetSitter.fr ne prélève aucune commission sur les rémunérations versées aux PetSitters affiliés à notre application. Ce modèle économique rare, voire inexistant sur les autres applications similaires, vous garantit un prix transparent.

Rapidité : En seulement 6 critères, vous obtenez une liste de PetSitters correspondant le plus précisément à vos attentes, vous évitant ainsi perte de temps en contacts infructueux.

Clarté : Le profil du PetSitter vous fournit les informations essentielles : type d'animaux pris en charge, missions acceptées avec options possibles, localisation, tarifs journalier et hebdomadaire. En outre, vous en saurez plus sur son cadre de vie (appartement ou maison à la campagne avec présence d'enfants en bas âge ou non) ainsi que la possibilité de venir chercher et ramener votre animal, d'administrer des médicaments et bien plus encore.`
  },
  {
    question: "Comment placer son annonce sur EuroPetSitter.fr ?",
    answer: "Afin de pouvoir placer son profil, le prestataire de services animaliers doit obligatoirement s'identifier et souscrire un abonnement. Tout nouvel inscrit bénéficiera d'un premier et unique abonnement gratuit d'une durée de 3 mois, ceci à titre d'essai et sans engagement ultérieur aucun. Une fois votre profil complété valablement, il sera mis en ligne. Dès lors tous les détenteurs d'animaux de compagnie pourront en prendre connaissance et vous contacter directement."
  },
  {
    question: "Comment supprimer mon compte en tant que PetSitter ?",
    answer: "Pas d'inquiétude, si vous ne souhaitez plus promouvoir vos services sur notre plateforme, temporairement ou définitivement, il vous suffit simplement d'utiliser l'option 'suspendre mon compte' ou 'supprimer mon compte' selon le cas dans votre espace client et votre profil ne sera plus visible. Il n'y a pas de renouvellement en automatique des abonnements sur notre plateforme !"
  },
  {
    question: "Comment réserver et payer un service auprès du PetSitter ?",
    answer: "Vous pouvez soumettre directement auprès du PetSitter de votre choix la demande de réservation d'un service animalier. Le montant des prestations est à payer directement à votre PetSitter. EuroPetSitter.fr ne prélève aucune commission sur ces rémunérations."
  }
];

export default function FAQ() {
  const [openItem, setOpenItem] = useState<number | null>(null);

  return (
    <section className="py-16 bg-white">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-center text-gray-900 mb-12">
          Questions fréquentes
        </h2>

        <div className="space-y-4">
          {FAQ_ITEMS.map((item, index) => (
            <div
              key={index}
              className="border rounded-lg overflow-hidden"
            >
              <button
                onClick={() => setOpenItem(openItem === index ? null : index)}
                className="w-full px-6 py-4 flex justify-between items-center hover:bg-gray-50"
              >
                <span className="font-medium text-left">{item.question}</span>
                {openItem === index ? (
                  <ChevronUp className="w-5 h-5 text-gray-400" />
                ) : (
                  <ChevronDown className="w-5 h-5 text-gray-400" />
                )}
              </button>
              
              {openItem === index && (
                <div className="px-6 py-4 bg-gray-50">
                  <p className="text-gray-600 whitespace-pre-line">{item.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}