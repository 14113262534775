import { PawPrint } from 'lucide-react';

interface AnimalType {
  id: string;
  label: string;
}

interface AnimalTypeSelectorProps {
  animals: AnimalType[];
  selectedAnimals: string[];
  onChange: (selected: string[]) => void;
}

export default function AnimalTypeSelector({ 
  animals, 
  selectedAnimals, 
  onChange 
}: AnimalTypeSelectorProps) {
  const toggleAnimal = (id: string) => {
    onChange(
      selectedAnimals.includes(id)
        ? selectedAnimals.filter(animalId => animalId !== id)
        : [...selectedAnimals, id]
    );
  };

  return (
    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
      {animals.map((animal) => (
        <button
          key={animal.id}
          type="button"
          onClick={() => toggleAnimal(animal.id)}
          className={`flex items-center p-3 border rounded-lg transition-colors ${
            selectedAnimals.includes(animal.id)
              ? 'bg-primary-50 border-primary-500'
              : 'hover:bg-gray-50 border-gray-200'
          }`}
        >
          <PawPrint className={`w-5 h-5 mr-2 flex-shrink-0 ${
            selectedAnimals.includes(animal.id)
              ? 'text-primary-500'
              : 'text-gray-400'
          }`} />
          <span className="text-sm text-left">{animal.label}</span>
        </button>
      ))}
    </div>
  );
}