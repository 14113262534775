import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { UserCircle, ChevronDown, LayoutDashboard, Settings, LogOut, Heart, Calendar, Phone, Search, Info, HelpCircle, BookOpen } from 'lucide-react';
import { useAuth } from '../hooks/useAuth';
import NotificationBell from './NotificationBell';
import MobileMenu from './MobileMenu';

export default function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, userData, logout } = useAuth();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsUserMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    setIsMobileMenuOpen(false);
    setIsUserMenuOpen(false);
  }, [location.pathname]);

  const handleSearchClick = () => {
    if (userData?.role === 'sitter') {
      alert("En tant que PetSitter, vous ne pouvez pas réserver de services.");
      return;
    }
    navigate('/search');
  };

  const handleAuthClick = () => {
    navigate('/auth');
  };

  const handleLogout = async () => {
    try {
      await logout();
      setIsUserMenuOpen(false);
      navigate('/');
    } catch (error) {
      console.error('Erreur lors de la déconnexion:', error);
      alert('Une erreur est survenue lors de la déconnexion. Veuillez réessayer.');
    }
  };

  const handleUserMenuClick = () => {
    setIsUserMenuOpen(!isUserMenuOpen);
  };

  const UserMenu = () => {
    if (!user || !userData) return null;

    const menuItems = userData.role === 'sitter' ? [
      {
        to: '/petsitter/dashboard',
        icon: <LayoutDashboard className="w-4 h-4 mr-2" />,
        label: 'Réservation(s)'
      },
      {
        to: '/petsitter/profile',
        icon: <LayoutDashboard className="w-4 h-4 mr-2" />,
        label: 'Configuration du profil'
      },
      {
        to: '/petsitter/settings',
        icon: <Settings className="w-4 h-4 mr-2" />,
        label: 'Mot de passe et Statut'
      },
      {
        to: '/contact',
        icon: <Phone className="w-4 h-4 mr-2" />,
        label: 'Contact'
      }
    ] : [
      {
        to: '/owner/bookings',
        icon: <Calendar className="w-4 h-4 mr-2" />,
        label: 'Mes réservations'
      },
      {
        to: '/owner/favorites',
        icon: <Heart className="w-4 h-4 mr-2" />,
        label: 'Mes favoris'
      }
    ];

    return (
      <div className="relative" ref={menuRef}>
        <button
          onClick={handleUserMenuClick}
          className="flex items-center space-x-2 text-gray-600 hover:text-gray-900"
        >
          <div className="w-8 h-8 rounded-full bg-primary-100 flex items-center justify-center">
            {user.displayName?.[0].toUpperCase() || 'U'}
          </div>
          <span>{user.displayName}</span>
          <ChevronDown className={`w-4 h-4 transform transition-transform ${isUserMenuOpen ? 'rotate-180' : ''}`} />
        </button>

        {isUserMenuOpen && (
          <div className="absolute right-0 top-full mt-2 w-60 bg-white rounded-lg shadow-lg py-1 z-[100]">
            {menuItems.map((item) => (
              <Link
                key={item.to}
                to={item.to}
                className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                onClick={() => setIsUserMenuOpen(false)}
              >
                {item.icon}
                {item.label}
              </Link>
            ))}
            <button
              onClick={handleLogout}
              className="flex items-center w-full px-4 py-2 text-sm text-red-600 hover:bg-gray-100"
            >
              <LogOut className="w-4 h-4 mr-2" />
              Déconnexion
            </button>
          </div>
        )}
      </div>
    );
  };

  return (
    <nav className="bg-white shadow-sm relative z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-20 items-center">
          {/* Logo and brand name for desktop */}
          <div className="flex items-center space-x-4">
            <Link 
              to="/"
              className="flex items-center hover:opacity-80 transition-opacity"
            >
              <img 
                src="https://firebasestorage.googleapis.com/v0/b/europetsitter-fd612.firebasestorage.app/o/logo%20Europetsitter.avif?alt=media&token=939040f2-c959-4b6b-8484-28128a62b145"
                alt="EuroPetSitter" 
                className="h-12 w-auto"
              />
            </Link>
            <span className="hidden md:block font-bold text-gray-900">EuroPetSitter.fr</span>
          </div>

          <div className="hidden md:flex items-center space-x-8">
            {(!userData || userData?.role !== 'sitter') && (
              <button 
                onClick={handleSearchClick}
                className="text-gray-600 hover:text-gray-900"
              >
                Chercher un PetSitter
              </button>
            )}
            <Link to="/about" className="text-gray-600 hover:text-gray-900">
              À propos de nous
            </Link>
            <Link to="/how-it-works" className="text-gray-600 hover:text-gray-900">
              Comment fonctionne EuroPetSitter.fr
            </Link>
            <Link to="/blog" className="text-gray-600 hover:text-gray-900">
              Notre Blog
            </Link>
            <Link to="/contact" className="text-gray-600 hover:text-gray-900">
              Contact
            </Link>
            
            {user && userData ? (
              <div className="flex items-center space-x-4">
                <NotificationBell />
                <UserMenu />
              </div>
            ) : (
              <button 
                onClick={handleAuthClick}
                className="bg-primary-500 text-white px-4 py-2 rounded-full hover:bg-primary-600 transition"
              >
                Connexion
              </button>
            )}
          </div>

          {/* Mobile header */}
          <div className="md:hidden flex items-center w-full">
            <div className="flex-1 flex justify-end">
              {user && <NotificationBell />}
              <button 
                onClick={() => setIsMobileMenuOpen(true)}
                className="text-gray-600 hover:text-gray-900 p-2 ml-4"
              >
                {userData?.role === 'sitter' ? (
                  <UserCircle className="w-6 h-6" />
                ) : (
                  <div className="w-6 h-5 flex flex-col justify-between">
                    <span className="block w-full h-0.5 bg-current"></span>
                    <span className="block w-full h-0.5 bg-current"></span>
                    <span className="block w-full h-0.5 bg-current"></span>
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      <MobileMenu 
        isOpen={isMobileMenuOpen}
        onClose={() => setIsMobileMenuOpen(false)}
        onAuthClick={handleAuthClick}
        user={user}
        userData={userData}
        onLogout={handleLogout}
      />
    </nav>
  );
}