import React from 'react';
import { Check } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const SUBSCRIPTION_PLANS = [
  {
    id: 'trimestriel',
    title: 'Trimestriel',
    price: '29€',
    period: '3 mois',
    pricePerMonth: '9,66€ TTC le mois',
    features: [
      '3 mois d\'accès à l\'application',
      'Portail PetSitter personnalisé',
      'Rendez public votre profil'
    ]
  },
  {
    id: 'semestriel',
    title: 'Semestriel',
    price: '49€',
    period: '6 mois',
    pricePerMonth: '8,16€ TTC le mois',
    features: [
      '6 mois d\'accès à l\'application',
      'Portail PetSitter personnalisé',
      'Rendez public votre profil'
    ],
    highlighted: true
  },
  {
    id: 'annuel',
    title: 'Annuel',
    price: '96€',
    period: '12 mois',
    pricePerMonth: '8,00€ TTC le mois',
    features: [
      '12 mois d\'accès à l\'application',
      'Portail PetSitter personnalisé',
      'Rendez public votre profil'
    ]
  }
];

export default function SubscriptionPage() {
  const navigate = useNavigate();

  const handleSubscribe = (planId: string) => {
    // TODO: Implémenter la logique d'abonnement
    console.log('Souscription au plan:', planId);
    navigate('/auth');
  };

  return (
    <div className="min-h-screen bg-gray-50 py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            ABONNEMENTS
          </h1>
          <h2 className="text-3xl font-bold text-gray-900 mb-6">
            Nos plans d'adhésion
          </h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Choisissez l'abonnement qui vous convient le mieux et profitez de notre application
            performante pour accroître votre notoriété sur le Web et votre activité de PetSitter !
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {SUBSCRIPTION_PLANS.map((plan) => (
            <div
              key={plan.id}
              className={`relative bg-white rounded-2xl shadow-lg overflow-hidden transform hover:scale-105 transition-transform ${
                plan.highlighted ? 'ring-4 ring-primary-500' : ''
              }`}
            >
              {plan.highlighted && (
                <div className="absolute top-0 right-0 bg-primary-500 text-white px-4 py-1 rounded-bl-lg">
                  Populaire
                </div>
              )}
              
              <div className="p-8">
                <h3 className="text-2xl font-bold text-gray-900 mb-4">{plan.title}</h3>
                <div className="flex items-baseline mb-2">
                  <span className="text-4xl font-bold">{plan.price}</span>
                  <span className="text-gray-500 ml-2">/ {plan.period}</span>
                </div>
                <p className="text-gray-500 text-sm mb-6">
                  Soit {plan.pricePerMonth}
                </p>

                <ul className="space-y-4 mb-8">
                  {plan.features.map((feature, index) => (
                    <li key={index} className="flex items-center">
                      <Check className="w-5 h-5 text-primary-500 mr-2" />
                      <span className="text-gray-600">{feature}</span>
                    </li>
                  ))}
                </ul>

                <button
                  onClick={() => handleSubscribe(plan.id)}
                  className={`w-full py-3 px-6 rounded-full font-semibold transition-colors ${
                    plan.highlighted
                      ? 'bg-primary-500 text-white hover:bg-primary-600'
                      : 'bg-white text-primary-500 border-2 border-primary-500 hover:bg-primary-50'
                  }`}
                >
                  S'abonner
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}