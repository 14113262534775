import React from 'react';
import { X } from 'lucide-react';
import { useAuth } from '../hooks/useAuth';
import { useReservations } from '../hooks/useReservations';
import type { PetSitter, Service } from '../types';
import Calendar from './Calendar';
import PetForm, { PetData } from './PetForm';
import { format, addHours, differenceInDays } from 'date-fns';
import { fr } from 'date-fns/locale';

interface ReservationModalProps {
  isOpen: boolean;
  onClose: () => void;
  sitter: PetSitter;
}

export default function ReservationModal({
  isOpen,
  onClose,
  sitter
}: ReservationModalProps) {
  const { user } = useAuth();
  const { createReservation } = useReservations();
  const [currentStep, setCurrentStep] = React.useState<'pet' | 'service' | 'dates' | 'confirmation'>('pet');
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [petData, setPetData] = React.useState<PetData | null>(null);
  const [selectedService, setSelectedService] = React.useState<Service | null>(null);
  const [startDate, setStartDate] = React.useState('');
  const [endDate, setEndDate] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);

  if (!isOpen) return null;

  const handlePetSubmit = (data: PetData) => {
    if (!phoneNumber.trim()) {
      alert("Veuillez entrer votre numéro de téléphone");
      return;
    }
    setPetData(data);
    setCurrentStep('service');
  };

  const handleDateSelect = (date: Date) => {
    const adjustedDate = addHours(date, 2);
    const formattedDate = format(adjustedDate, 'yyyy-MM-dd');
    
    if (!startDate || (startDate && endDate)) {
      setStartDate(formattedDate);
      setEndDate('');
    } else if (formattedDate > startDate) {
      setEndDate(formattedDate);
    } else {
      setStartDate(formattedDate);
      setEndDate('');
    }
  };

  const calculateTotalPrice = () => {
    if (!selectedService || !startDate || !endDate) return 0;

    const start = new Date(startDate);
    const end = new Date(endDate);
    const days = differenceInDays(end, start) + 1;

    // Si le prix hebdomadaire est défini et que la durée est d'au moins 7 jours
    if (selectedService.priceWeek && days >= 7) {
      const weeks = Math.floor(days / 7);
      const remainingDays = days % 7;
      
      return (weeks * selectedService.priceWeek) + (remainingDays * selectedService.price);
    }

    // Sinon, utiliser le prix journalier
    return days * selectedService.price;
  };

  const formatPriceDetails = () => {
    if (!selectedService || !startDate || !endDate) return '';

    const start = new Date(startDate);
    const end = new Date(endDate);
    const days = differenceInDays(end, start) + 1;

    if (selectedService.priceWeek && days >= 7) {
      const weeks = Math.floor(days / 7);
      const remainingDays = days % 7;
      
      let details = '';
      if (weeks > 0) {
        details += `${weeks} semaine${weeks > 1 ? 's' : ''} × ${selectedService.priceWeek}€`;
      }
      if (remainingDays > 0) {
        if (details) details += ' + ';
        details += `${remainingDays} jour${remainingDays > 1 ? 's' : ''} × ${selectedService.price}€`;
      }
      return details;
    }

    return `${days} jour${days > 1 ? 's' : ''} × ${selectedService.price}€`;
  };

  const handleSubmit = async () => {
    if (!user || !selectedService || !startDate || !endDate || !petData || !phoneNumber) return;

    setLoading(true);
    setError(null);

    try {
      const totalPrice = calculateTotalPrice();
      const duration = differenceInDays(new Date(endDate), new Date(startDate)) + 1;

      await createReservation({
        sitterId: sitter.id,
        ownerId: user.uid,
        ownerName: user.displayName || 'Anonyme',
        ownerPhone: phoneNumber,
        startDate,
        endDate,
        serviceType: selectedService.type,
        totalPrice,
        duration,
        status: 'pending',
        petInfo: {
          ...petData,
          age: parseInt(petData.age) || 0
        }
      });

      onClose();
      alert('Votre demande de réservation a été envoyée avec succès !');
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Une erreur est survenue');
    } finally {
      setLoading(false);
    }
  };

  const getStepTitle = () => {
    switch (currentStep) {
      case 'pet':
        return 'Informations sur votre animal';
      case 'service':
        return 'Choix du service';
      case 'dates':
        return 'Sélection des dates';
      case 'confirmation':
        return 'Confirmation';
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl max-w-2xl w-full mx-4 max-h-[80vh] overflow-y-auto">
        <div className="p-4 border-b border-gray-200 flex justify-between items-center">
          <div>
            <h2 className="text-xl font-semibold">Réserver avec {sitter.name}</h2>
            <p className="text-sm text-gray-500 mt-1">{getStepTitle()}</p>
          </div>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="p-4">
          {/* Render content based on current step */}
          {currentStep === 'pet' && (
            <div className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Votre numéro de téléphone
                </label>
                <input
                  type="tel"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary-200 focus:border-primary-500"
                  placeholder="Ex: 06 12 34 56 78"
                  required
                />
              </div>
              <PetForm
                onSubmit={handlePetSubmit}
                availableAnimals={sitter.animals}
              />
            </div>
          )}

          {currentStep === 'service' && (
            <div className="space-y-6">
              <h3 className="font-medium mb-4">Sélectionnez un service</h3>
              <div className="space-y-3">
                {sitter.services.map((service) => (
                  <button
                    key={service.type}
                    onClick={() => {
                      setSelectedService(service);
                      setCurrentStep('dates');
                    }}
                    className={`w-full p-4 text-left border rounded-lg transition-colors ${
                      selectedService?.type === service.type
                        ? 'border-primary-500 bg-primary-50'
                        : 'hover:bg-gray-50'
                    }`}
                  >
                    <div className="font-medium">{service.description}</div>
                    <div className="text-sm text-gray-600 mt-1">
                      {service.price}€/jour
                      {service.priceWeek && ` • ${service.priceWeek}€/semaine`}
                    </div>
                  </button>
                ))}
              </div>
            </div>
          )}

          {currentStep === 'dates' && (
            <div className="space-y-6">
              <div className="bg-primary-50 rounded-lg p-4">
                <h3 className="font-medium mb-2">Récapitulatif</h3>
                <div className="space-y-2 text-sm">
                  <p><span className="font-medium">Animal :</span> {petData?.name} ({petData?.type})</p>
                  <p><span className="font-medium">Service :</span> {selectedService?.description}</p>
                  <p><span className="font-medium">Prix :</span> {selectedService?.price}€/jour</p>
                  {selectedService?.priceWeek && (
                    <p><span className="font-medium">Prix hebdomadaire :</span> {selectedService.priceWeek}€/semaine</p>
                  )}
                </div>
              </div>

              <div>
                <h3 className="font-medium mb-4">Sélectionnez vos dates</h3>
                <Calendar
                  sitterId={sitter.id}
                  onDateSelect={handleDateSelect}
                  selectedDate={startDate}
                  endDate={endDate}
                />
              </div>

              {startDate && endDate && (
                <div className="bg-gray-50 p-4 rounded-lg">
                  <div className="space-y-2">
                    <div className="text-sm text-gray-600">{formatPriceDetails()}</div>
                    <div className="flex justify-between items-center">
                      <span>Total estimé :</span>
                      <span className="font-bold text-lg">{calculateTotalPrice()}€</span>
                    </div>
                  </div>
                  <button
                    onClick={() => setCurrentStep('confirmation')}
                    className="w-full mt-4 bg-primary-500 text-white px-6 py-2 rounded-lg hover:bg-primary-600 transition"
                  >
                    Continuer
                  </button>
                </div>
              )}
            </div>
          )}

          {currentStep === 'confirmation' && (
            <div className="space-y-6">
              <h3 className="font-medium mb-4">Confirmation de la réservation</h3>
              
              <div className="bg-gray-50 rounded-lg p-6 space-y-4">
                <div className="space-y-2">
                  <h4 className="font-medium">Détails de l'animal</h4>
                  <p>Nom : {petData?.name}</p>
                  <p>Type : {petData?.type}</p>
                  <p>Âge : {petData?.age} ans</p>
                  {petData?.healthInfo && (
                    <p>Informations de santé : {petData.healthInfo}</p>
                  )}
                </div>

                <div className="border-t pt-4 space-y-2">
                  <h4 className="font-medium">Détails de la réservation</h4>
                  <p>Service : {selectedService?.description}</p>
                  <p>Du : {format(new Date(startDate), 'dd MMMM yyyy', { locale: fr })}</p>
                  <p>Au : {format(new Date(endDate), 'dd MMMM yyyy', { locale: fr })}</p>
                  <p>Téléphone : {phoneNumber}</p>
                  <div className="text-sm text-gray-600">{formatPriceDetails()}</div>
                  <p className="font-bold">Total : {calculateTotalPrice()}€</p>
                </div>
              </div>

              {error && (
                <div className="bg-red-50 text-red-700 p-4 rounded-lg">
                  {error}
                </div>
              )}

              <button
                onClick={handleSubmit}
                disabled={loading}
                className="w-full bg-primary-500 text-white px-6 py-3 rounded-lg hover:bg-primary-600 transition disabled:opacity-50"
              >
                {loading ? 'Envoi en cours...' : 'Confirmer la réservation'}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}