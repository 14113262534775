import { useState } from 'react';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import type { PetSitter } from '../types';

export function usePetSitterProfile(sitterId: string) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const loadProfile = async () => {
    try {
      setLoading(true);
      const docRef = doc(db, 'sitterProfiles', sitterId);
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        return { id: docSnap.id, ...docSnap.data() } as PetSitter;
      }
      return null;
    } catch (err) {
      setError('Erreur lors du chargement du profil');
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const updateProfile = async (profileData: Partial<PetSitter>) => {
    try {
      setLoading(true);
      setError(null);

      // Clean and validate data
      const cleanedData = {
        name: profileData.name || '',
        email: profileData.email || '',
        avatar: profileData.avatar || '',
        photos: profileData.photos || [],
        description: profileData.description || '',
        location: profileData.location || '',
        address: profileData.address || '',
        coordinates: profileData.coordinates || null,
        services: (profileData.services || []).map(service => ({
          type: service.type,
          price: Number(service.price) || 0,
          priceWeek: service.priceWeek ? Number(service.priceWeek) : null,
          description: service.description || ''
        })),
        animals: profileData.animals || [],
        availability: profileData.availability || [],
        blockedDates: profileData.blockedDates || [],
        serviceOptions: profileData.serviceOptions || {},
        specificities: profileData.specificities || {},
        rating: profileData.rating || 0,
        suspended: false,
        updatedAt: new Date().toISOString()
      };

      const docRef = doc(db, 'sitterProfiles', sitterId);
      await updateDoc(docRef, cleanedData);
    } catch (err) {
      console.error('Error updating profile:', err);
      setError('Erreur lors de la mise à jour du profil');
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    error,
    loadProfile,
    updateProfile
  };
}