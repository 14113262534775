import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyA988hwc28a9sCkkglGuOSeN9qvc1ZYFXo",
  authDomain: "europetsitter-fd612.firebaseapp.com",
  projectId: "europetsitter-fd612",
  storageBucket: "europetsitter-fd612.appspot.com",
  messagingSenderId: "78748656734",
  appId: "1:78748656734:web:d6a7383199774d4bdb80cf",
  measurementId: "G-B38YTKLKRQ"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);