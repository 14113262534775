import React from 'react';
import { motion } from 'framer-motion';
import Hero from '../components/Hero';
import FeaturedSitters from '../components/FeaturedSitters';
import InfoTabs from '../components/InfoTabs';
import FAQ from '../components/FAQ';
import BlogSection from '../components/BlogSection';

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.3
    }
  }
};

const sectionVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { 
    opacity: 1, 
    y: 0,
    transition: {
      duration: 0.6,
      ease: "easeOut"
    }
  }
};

export default function HomePage() {
  return (
    <motion.main
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <motion.section variants={sectionVariants}>
        <Hero />
      </motion.section>

      <motion.section 
        variants={sectionVariants}
        viewport={{ once: true }}
      >
        <FeaturedSitters />
      </motion.section>

      <motion.section 
        variants={sectionVariants}
        viewport={{ once: true }}
      >
        <InfoTabs />
      </motion.section>

      <motion.section 
        variants={sectionVariants}
        viewport={{ once: true }}
      >
        <BlogSection />
      </motion.section>

      <motion.section 
        variants={sectionVariants}
        viewport={{ once: true }}
      >
        <FAQ />
      </motion.section>
    </motion.main>
  );
}