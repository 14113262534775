import React from 'react';
import { Navigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useAuth } from '../hooks/useAuth';
import { LoadingSpinner } from '../components/common/LoadingSpinner';

interface PrivateRouteProps {
  children: React.ReactNode;
  allowedRole: 'sitter' | 'owner';
}

export function PrivateRoute({ children, allowedRole }: PrivateRouteProps) {
  const { user, userData, loading } = useAuth();

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!user || !userData) {
    return <Navigate to="/auth" />;
  }

  if (userData.role !== allowedRole) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
}