import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Upload, X, Plus, Loader2 } from 'lucide-react';
import { useAuth } from '../hooks/useAuth';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../config/firebase';

interface PhotoGalleryProps {
  photos: string[];
  onChange: (photos: string[]) => void;
  maxPhotos?: number;
  title?: string;
  description?: string;
}

export default function PhotoGallery({
  photos,
  onChange,
  maxPhotos = 10,
  title = "Photos",
  description = "Partagez des photos de votre maison et de vos expériences avec les animaux"
}: PhotoGalleryProps) {
  const { user } = useAuth();
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    if (!user) {
      setError('Vous devez être connecté pour ajouter des photos');
      return;
    }

    if (photos.length + acceptedFiles.length > maxPhotos) {
      setError(`Vous ne pouvez pas ajouter plus de ${maxPhotos} photos`);
      return;
    }

    setUploading(true);
    setError(null);

    try {
      const newPhotos = await Promise.all(
        acceptedFiles.map(async (file, index) => {
          return new Promise<string>((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              // Add timestamp and index to make each key unique
              const timestamp = Date.now();
              resolve(`${reader.result as string}#${timestamp}-${index}`);
            };
            reader.readAsDataURL(file);
          });
        })
      );

      const updatedPhotos = [...photos, ...newPhotos];
      
      // Update Firestore profile
      const sitterRef = doc(db, 'sitterProfiles', user.uid);
      await updateDoc(sitterRef, { photos: updatedPhotos });
      
      onChange(updatedPhotos);
    } catch (error) {
      console.error('Error during upload:', error);
      setError(error instanceof Error ? error.message : 'Une erreur est survenue');
    } finally {
      setUploading(false);
    }
  }, [user, photos, maxPhotos, onChange]);

  const removePhoto = async (photoUrl: string) => {
    if (!user) return;

    try {
      setUploading(true);
      const updatedPhotos = photos.filter(url => url !== photoUrl);
      
      // Update Firestore profile
      const sitterRef = doc(db, 'sitterProfiles', user.uid);
      await updateDoc(sitterRef, { photos: updatedPhotos });
      
      onChange(updatedPhotos);
    } catch (error) {
      console.error('Error removing photo:', error);
      setError('Une erreur est survenue lors de la suppression de la photo');
    } finally {
      setUploading(false);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
      'image/gif': ['.gif']
    },
    maxSize: 5 * 1024 * 1024,
    disabled: uploading || photos.length >= maxPhotos
  });

  return (
    <div className="space-y-4">
      <div>
        <h3 className="text-lg font-medium">{title}</h3>
        <p className="text-sm text-gray-500 mt-1">{description}</p>
      </div>

      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {photos.map((photo, index) => {
          // Extract the unique identifier from the URL if it exists
          const [baseUrl] = photo.split('#');
          const key = photo.includes('#') ? photo : `${baseUrl}#${index}`;
          
          return (
            <div key={key} className="relative aspect-square">
              <img
                src={baseUrl}
                alt={`Photo ${index + 1}`}
                className="w-full h-full object-cover rounded-lg"
              />
              <button
                onClick={() => removePhoto(photo)}
                disabled={uploading}
                className="absolute -top-2 -right-2 p-1.5 bg-red-500 text-white rounded-full hover:bg-red-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed shadow-lg"
              >
                <X className="w-4 h-4" />
              </button>
            </div>
          );
        })}

        {photos.length < maxPhotos && (
          <div
            {...getRootProps()}
            className={`aspect-square border-2 border-dashed rounded-lg flex flex-col items-center justify-center cursor-pointer transition-colors ${
              isDragActive
                ? 'border-primary-500 bg-primary-50'
                : 'border-gray-300 hover:border-primary-500 hover:bg-gray-50'
            } ${uploading ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            <input {...getInputProps()} />
            {uploading ? (
              <div className="flex flex-col items-center">
                <Loader2 className="w-8 h-8 text-primary-500 animate-spin" />
                <p className="mt-2 text-sm text-gray-600">Upload en cours...</p>
              </div>
            ) : (
              <>
                <Plus className="w-8 h-8 text-gray-400 mb-2" />
                <p className="text-sm text-gray-500 text-center px-2">
                  {isDragActive ? 'Déposez les photos ici' : 'Ajouter des photos'}
                </p>
              </>
            )}
          </div>
        )}
      </div>

      {error && (
        <p className="text-sm text-red-500">{error}</p>
      )}

      <p className="text-sm text-gray-500">
        {photos.length}/{maxPhotos} photos • JPG, PNG ou GIF • Max 5MB par photo
      </p>
    </div>
  );
}